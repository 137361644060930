/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DatepicherComponent } from './datepicher/datepicher.component';
import { RoutingService } from '../../../service/routing.service';
import { Location } from '@angular/common';
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { ConfigService } from 'src/app/service/config.service';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { HelpComponent } from '../../help/help.component';
import * as moment from 'moment';

/**
 * main menu filter of the application
 */
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-menu-filters',
  templateUrl: './menu-filters.component.html',
  styleUrls: ['./menu-filters.component.scss'],
})
export class MenuFiltersComponent implements AfterViewInit {
  @ViewChild('presetsSelectionList', { static: true })
  private presetsSelectionList: MatSelectionList;

  /**
   * check if filters are activated (non default)
   */
  isFilterActive = false;

  /**
   * fullscreen mode
   */
  fullscreen: boolean;

  /**
   * sort by
   */
  sort: any;

  /**
   * time
   */
  time: any;

  /**
   * array of the time
   */
  timenumber: any = ['0', '1', '2', '7', '30', '90', '180', '365'];

  /**
   * time array string
   */
  timestring: any = [
    'custom',
    'day',
    '24 hours',
    'week',
    'month',
    'quarter',
    '6 months',
    'year',
  ];

  /**
   * time name to display
   */
  timename: string;

  /**
   * feed selected
   */
  feed: any;

  /**
   *
   */
  itheme: string;

  /**
   *
   */
  params: any;

  /**
   *
   */
  layout = 'main';

  /**
   *
   */
  intheme = true;

  /**
   * possible labels and values of language filters
   */
  filterLang = [];
  menuButtonLang = [];

  /**
   * possible labels and values of timeframe filters
   */
  filterTimeframe = [
    {
      label: 'Today',
      value: '1',
    },
    {
      label: '24 hours',
      value: '2',
    },
    {
      label: 'Week',
      value: '7',
    },
    {
      label: 'Month',
      value: '30',
    },
    {
      label: 'Quarter',
      value: '90',
    },
    {
      label: '6 months',
      value: '180',
    },
    {
      label: 'Year',
      value: '365',
    },
    {
      label: 'Custom',
      value: '0',
    },
  ];
  /**
   * form control for timeframe filters
   */
  menuFilterTimeframe: UntypedFormControl;

  /**
   * possible labels and values of sentiment filters
   */
  filterSentiment = [
    // {
    //   label: 'All',
    //   value: 'all'
    // },
    {
      label: 'Positive',
      value: 'pos',
    },
    {
      label: 'Neutral',
      value: 'neu',
    },
    {
      label: 'Negative',
      value: 'neg',
    },
    // {
    //   label: 'No Sentiment',
    //   value: 'nf'
    // }
  ];
  /**
   * form control for sentiment filters
   */
  menuFilterSentiment: UntypedFormControl;

  /**
   * form control for language filters
   */
  menuFilterLang: UntypedFormControl;

  /**
   *
   */
  project: string;

  /**
   *
   */
  i18n = 'English';

  /**
   * period
   */
  period: string;

  /**
   *
   */
  filterNews = [
    {
      // label: 'Pro (Full Text)',
      label: 'PRO',
      value: 'professional',
      icon: 'star',
      active: true,
    },
    {
      // label: 'Print (Full Text)',
      label: 'PRINT',
      value: 'print',
      icon: 'list_alt',
      active: true,
    },
    {
      // label: 'Web (Finance)',
      label: 'WEB',
      value: 'online',
      icon: 'dvr',
      active: true,
    },
    {
      // label: 'Web (Global)',
      label: 'RSS',
      value: 'non_professional',
      icon: 'rss_feed',
      active: true,
    },
  ];
  menuButtonNews = [];

  /**
   * form control of the feed
   */
  menuFilterNews: UntypedFormControl;

  /**
   * document categories disabled by default
   */
  // defaultDisabledCategories = ['gent', 'gedu', 'ciprof', 'glife', 'cmarkr', 'gpersf', 'gsci', 'gspo', 'gwea'];
  defaultDisabledCategories = [];

  /**
   * filters presets object containing all the data
   */
  presets: any;

  /**
   * default filters preset
   */
  presetDefault = {
    id: 'defaultId',
    name: 'Default',
    feed: 'non_professional,online,print,professional',
    time: '7',
    // filter: 'pos,neu,neg,nf',
    filter: 'pos,neu,neg',
    lang: 'en,de',
    ranks: null,
    categories: this.defaultDisabledCategories.join(),
    continents: null,
    countries: null,
    panels: null,
    factuality: null,
    temporality: null,
    event_ids: null,
  };

  /**
   * default filters preset including environmental (E) events
   */
  presetEventsE = {
    id: 'eventsE',
    name: 'E events',
    feed: 'non_professional,online,print,professional',
    time: '7',
    filter: null,
    lang: 'en,de',
    ranks: null,
    categories: this.defaultDisabledCategories.join(),
    continents: null,
    countries: null,
    panels: null,
    factuality: null,
    temporality: null,
    event_ids:
      'E33_A,E33_B,E33_E,E33_F,E33_J,E33_K,E33_H,E33_I,E49_A,E49_B,E33_D,E34_A,E34_B,E52_B,E52_A,E34_C,E34_D,E33_L',
  };

  /**
   * default filters preset including social (S) events
   */
  presetEventsS = {
    id: 'eventsS',
    name: 'S events',
    feed: 'non_professional,online,print,professional',
    time: '7',
    filter: null,
    lang: 'en,de',
    ranks: null,
    categories: this.defaultDisabledCategories.join(),
    continents: null,
    countries: null,
    panels: null,
    factuality: null,
    temporality: null,
    event_ids:
      'E48_A,E48_D,E48_E,E48_F,E48_G,E13_B,E13_A,E48_B,E48_C,E51_A,E51_D,E51_B,E3_A,E2_B,E15_A,E2_A,E32_C,E17_B,E17_C,E17_A,E17_D',
  };

  /**
   * default filters preset including governance (G) events
   */
  presetEventsG = {
    id: 'eventsG',
    name: 'G events',
    feed: 'non_professional,online,print,professional',
    time: '7',
    filter: null,
    lang: 'en,de',
    ranks: null,
    categories: this.defaultDisabledCategories.join(),
    continents: null,
    countries: null,
    panels: null,
    factuality: null,
    temporality: null,
    event_ids:
      'E47_A,E20_A,E22_B,E22_C,E50_B,E50_A,E16_A,E23_A,E33_G,E33_C,E25_E,E21_A,E21_B,E42_C,E45_A,E45_C,E18_B,E18_A,E18_F,E18_G,E6_D,E6_C,E6_B,E6_E,E6_A,E19_A,E19_B',
  };

  /**
   * custom default preset
   */
  presetCustomDefault = {
    id: 'defaultId',
    name: 'Default',
    feed: 'non_professional,online,print,professional',
    time: '7',
    // filter: 'pos,neu,neg,nf',
    filter: 'pos,neu,neg',
    lang: 'en,de',
    ranks: null,
    categories: this.defaultDisabledCategories.join(),
    continents: null,
    countries: null,
    panels: null,
    factuality: null,
    temporality: null,
    event_ids: null,
  };

  /**
   * id of the custom default filter
   */
  idCustomDefault: any;

  /**
   * name of the custom default filter
   */
  nameCustomDefault: any;

  /**
   * filters presets array for display
   */
  filterPresets = [];

  /**
   * form control of filters presets
   */
  menuFilterPresets: UntypedFormControl;

  /**
   * all events from URL parameter event_ids
   */
  urlEvents: any;

  /**
   * events object containing info for filtering purposes
   */
  events: any;

  /**
   * events filter
   */
  filterEvents = [];

  /**
   * form control of the events filter
   */
  menuFilterEvents: UntypedFormControl;

  /**
   * sub_events object containing info for filtering purposes
   */
  subEvents: any;

  /**
   * sub_events filter
   */
  filterSubEvents = {};

  /**
   * all subevents
   */
  allSubEvents = [];

  /**
   * document categories object containing info for filtering purposes
   */
  categories: any;

  /**
   * document categories filter
   */
  filterCategories = [];

  /**
   * form control of the document categories filter
   */
  menuFilterCategories: UntypedFormControl;

  /**
   * source panels object containing info for filtering purposes
   */
  panels: any;

  /**
   * source panels filter
   */
  filterPanels = [];

  /**
   * form control of the source panels filter
   */
  menuFilterPanels: UntypedFormControl;

  /**
   * geography filters group object
   */
  filterGeography = {
    continents: [],
    countries: [],
  };

  /**
   * form control of the geography filters
   */
  menuFilterGeography: UntypedFormControl;

  /**
   * geography group autocompletion form, group and options observable
   */
  geographyForm: UntypedFormGroup;
  geographyGroups = [];
  geographyGroupOptions: Observable<any>;

  /**
   * temporality and factuality filters group object
   */
  filterFactTemp = {
    factuality: [
      {
        value: 'fact',
        label: 'Factual',
        selected: false,
      },
      {
        value: 'probable',
        label: 'Probable',
        selected: false,
      },
      {
        value: 'possible',
        label: 'Possible',
        selected: false,
      },
    ],
    temporality: [
      {
        value: 'past',
        label: 'Past',
        selected: false,
      },
      {
        value: 'present',
        label: 'Present',
        selected: false,
      },
      {
        value: 'future',
        label: 'Future',
        selected: false,
      },
    ],
  };

  /**
   * form control of the factuality/temporality filters
   */
  menuFilterFactTemp: UntypedFormControl;

  /**
   * source ranks object containing info for filtering purposes
   */
  ranks: any;

  /**
   * source ranks filter
   */
  filterRanks = [];

  /**
   * form control of the source ranks filter
   */
  menuFilterRanks: UntypedFormControl;

  /**
   * special tag filter
   */
  tagFilter = null;

  /**
   * special participant filter
   */
  participantFilter = null;

  /**
   * omit resetting filters on menu close
   */
  omitReset = false;

  /**
   * query parameters subscribe and initialize the formcontrols
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private yukkApi: YukkApi,
    public dialog: MatDialog,
    public routing: RoutingService,
    public location: Location,
    public snackBar: MatSnackBar,
    public auth: AuthService,
    public config: ConfigService,
    private cd: ChangeDetectorRef,
  ) {
    this.project = this.config.appConfig.routing.project;

    const currentDay = new Date();
    const startDay = new Date(this.config.appConfig.setup.startDate);
    const maxTimeframe = moment(currentDay).diff(moment(startDay), 'days');
    this.filterTimeframe = this.filterTimeframe.filter((el) => {
      return (
        Number(el.value) <=
          Number(this.auth.internalCockpitSettings.maxTimeframe) &&
        Number(el.value) <= Number(maxTimeframe)
      );
    });

    // const availableLanguages = 'en,de,ru,zh,fr,it,es';
    // let availableLanguages = 'en,de,ru,zh';
    // if ((this.config.appConfig.environment === 'prod') || (this.config.appConfig.routing.project === 'scorelab')) {
    //   availableLanguages = 'en,de';
    //   this.filterLang = [
    //     {
    //       label: 'English',
    //       value: 'en',
    //     },
    //     {
    //       label: 'German',
    //       value: 'de',
    //     }
    //   ];
    //   this.presetDefault.lang = 'en,de';
    //   this.presetCustomDefault.lang = 'en,de';
    // }

    this.route.queryParams.subscribe((params) => {
      if (params.tag) {
        this.tagFilter = params.tag;
      } else {
        this.tagFilter = null;
      }
      if (params.participant) {
        this.participantFilter = params.participant;
      } else {
        this.participantFilter = null;
      }

      this.menuFilterPresets = new UntypedFormControl();

      if (this.filterPresets.length === 0) {
        this.auth.getFilter().subscribe((result) => {
          this.presets = result;
          this.filterPresets = [];

          const defaultPreset = {
            label: 'Default',
            value: 'Default',
            visible: false,
            presetCopy: JSON.parse(JSON.stringify(this.presetDefault)),
          };

          this.filterPresets.push(defaultPreset);

          if (
            this.auth.scorelabSettings.defaultScore === 'esg' ||
            this.auth.scorelabSettings.defaultScore === 'esg_risk'
          ) {
            const eventsEpreset = {
              label: 'E events',
              value: 'E events',
              visible: false,
              presetCopy: JSON.parse(JSON.stringify(this.presetEventsE)),
            };
            const eventsSpreset = {
              label: 'S events',
              value: 'S events',
              visible: false,
              presetCopy: JSON.parse(JSON.stringify(this.presetEventsS)),
            };
            const eventsGpreset = {
              label: 'G events',
              value: 'G events',
              visible: false,
              presetCopy: JSON.parse(JSON.stringify(this.presetEventsG)),
            };
            this.filterPresets.push(eventsEpreset);
            this.filterPresets.push(eventsSpreset);
            this.filterPresets.push(eventsGpreset);
          }

          result.forEach((item) => {
            if (item.name === '_default_') {
              Object.assign(this.filterPresets[0].presetCopy, item);
              Object.assign(this.presetCustomDefault, item);
              this.idCustomDefault = item.id;
              if (item.defaultFilter) {
                this.nameCustomDefault = item.defaultFilter;
              }
            } else {
              this.filterPresets.push({
                label: item.name,
                value: item.name,
                visible: false,
                presetCopy: item,
              });
            }
          });

          if (
            (params.time && params.time !== this.presetCustomDefault.time) ||
            (!params.time &&
              '7' !== this.presetCustomDefault.time &&
              null !== this.presetCustomDefault.time) ||
            (params.feed &&
              params.feed !== this.presetCustomDefault.feed &&
              null !== this.presetCustomDefault.feed) ||
            (!params.feed &&
              'non_professional,online,print,professional' !==
                this.presetCustomDefault.feed &&
              null !== this.presetCustomDefault.feed &&
              'all' !== this.presetCustomDefault.feed) ||
            (params.filter &&
              params.filter !== this.presetCustomDefault.filter) ||
            (!params.filter &&
              'pos,neu,neg' !== this.presetCustomDefault.filter &&
              null !== this.presetCustomDefault.filter) ||
            (params.lang && params.lang !== this.presetCustomDefault.lang) ||
            (!params.lang &&
              null !== this.presetCustomDefault.lang &&
              'en,de' !== this.presetCustomDefault.lang) ||
            (params.ranks && params.ranks !== this.presetCustomDefault.ranks) ||
            (!params.ranks && null !== this.presetCustomDefault.ranks) ||
            (params.categories &&
              params.categories !== this.presetCustomDefault.categories &&
              null !== this.presetCustomDefault.categories) ||
            (!params.categories &&
              null !== this.presetCustomDefault.categories &&
              this.defaultDisabledCategories.join() !==
                this.presetCustomDefault.categories) ||
            (params.continents &&
              params.continents !== this.presetCustomDefault.continents) ||
            (!params.continents &&
              null !== this.presetCustomDefault.continents) ||
            (params.countries &&
              params.countries !== this.presetCustomDefault.countries) ||
            (!params.countries &&
              null !== this.presetCustomDefault.countries) ||
            (params.panels &&
              params.panels !== this.presetCustomDefault.panels) ||
            (!params.panels && null !== this.presetCustomDefault.panels) ||
            (params.factuality &&
              params.factuality !== this.presetCustomDefault.factuality) ||
            (!params.factuality &&
              null !== this.presetCustomDefault.factuality) ||
            (params.temporality &&
              params.temporality !== this.presetCustomDefault.temporality) ||
            (!params.temporality &&
              null !== this.presetCustomDefault.temporality) ||
            (params.event_ids &&
              params.event_ids !== this.presetCustomDefault.event_ids) ||
            (!params.event_ids && null !== this.presetCustomDefault.event_ids)
          ) {
            this.isFilterActive = true;
            if (this.auth.filter && this.auth.filter === 'Default') {
              this.auth.filter = 'Custom';
            }

            let presetId;
            // @ts-expect-error Description of why the error is expected
            this.presetsSelectionList.options._results.forEach(
              (item, index) => {
                if (item._value === this.auth.filter) {
                  presetId = index;
                  setTimeout(() => {
                    if (
                      this.presetsSelectionList &&
                      // @ts-expect-error Description of why the error is expected
                      this.presetsSelectionList.options._results
                    ) {
                      this.presetsSelectionList.deselectAll();
                      // @ts-expect-error Description of why the error is expected
                      this.presetsSelectionList.options._results[
                        presetId
                      ]._setSelected(true);
                    }
                  }, 200);
                }
              },
            );
          } else {
            this.isFilterActive = false;
            this.auth.filter = 'Default';

            setTimeout(() => {
              if (
                this.presetsSelectionList &&
                this.presetsSelectionList.options.first
              ) {
                this.presetsSelectionList.deselectAll();
                this.presetsSelectionList.options.first._setSelected(true);
              }
            }, 200);
          }

          // setTimeout(() => {
          //   if (this.presetsSelectionList && this.presetsSelectionList.options.first) {
          //     this.presetsSelectionList.deselectAll();
          //     this.presetsSelectionList.options.first._setSelected(true);
          //   }
          // }, 200);
        });
      } else {
        if (
          (this.auth.scorelabSettings.defaultScore === 'esg' ||
            this.auth.scorelabSettings.defaultScore === 'esg_risk') &&
          this.filterPresets &&
          this.filterPresets.length > 0 &&
          this.filterPresets[0].value === 'Default' &&
          (this.filterPresets.length === 1 ||
            this.filterPresets[1].value !== 'E events')
        ) {
          const eventsEpreset = {
            label: 'E events',
            value: 'E events',
            visible: false,
            presetCopy: JSON.parse(JSON.stringify(this.presetEventsE)),
          };
          const eventsSpreset = {
            label: 'S events',
            value: 'S events',
            visible: false,
            presetCopy: JSON.parse(JSON.stringify(this.presetEventsS)),
          };
          const eventsGpreset = {
            label: 'G events',
            value: 'G events',
            visible: false,
            presetCopy: JSON.parse(JSON.stringify(this.presetEventsG)),
          };
          this.filterPresets.splice(
            1,
            0,
            eventsEpreset,
            eventsSpreset,
            eventsGpreset,
          );
        } else if (
          (this.auth.scorelabSettings.defaultScore === 'esg' ||
            this.auth.scorelabSettings.defaultScore === 'esg_risk') &&
          this.filterPresets &&
          this.filterPresets.length > 3 &&
          this.filterPresets[0].value === 'Default' &&
          this.filterPresets[1].value === 'E events' &&
          this.filterPresets[2].value === 'S events' &&
          this.filterPresets[3].value === 'G events'
        ) {
          this.filterPresets.splice(1, 3);
        }

        if (
          (params.time && params.time !== this.presetCustomDefault.time) ||
          (!params.time &&
            '7' !== this.presetCustomDefault.time &&
            null !== this.presetCustomDefault.time) ||
          (params.feed &&
            params.feed !== this.presetCustomDefault.feed &&
            null !== this.presetCustomDefault.feed) ||
          (!params.feed &&
            'non_professional,online,print,professional' !==
              this.presetCustomDefault.feed &&
            null !== this.presetCustomDefault.feed &&
            'all' !== this.presetCustomDefault.feed) ||
          (params.filter &&
            params.filter !== this.presetCustomDefault.filter) ||
          (!params.filter &&
            'pos,neu,neg' !== this.presetCustomDefault.filter &&
            null !== this.presetCustomDefault.filter) ||
          (params.lang && params.lang !== this.presetCustomDefault.lang) ||
          (!params.lang &&
            null !== this.presetCustomDefault.lang &&
            'en,de' !== this.presetCustomDefault.lang) ||
          (params.ranks && params.ranks !== this.presetCustomDefault.ranks) ||
          (!params.ranks && null !== this.presetCustomDefault.ranks) ||
          (params.categories &&
            params.categories !== this.presetCustomDefault.categories &&
            null !== this.presetCustomDefault.categories) ||
          (!params.categories &&
            null !== this.presetCustomDefault.categories &&
            this.defaultDisabledCategories.join() !==
              this.presetCustomDefault.categories) ||
          (params.continents &&
            params.continents !== this.presetCustomDefault.continents) ||
          (!params.continents &&
            null !== this.presetCustomDefault.continents) ||
          (params.countries &&
            params.countries !== this.presetCustomDefault.countries) ||
          (!params.countries && null !== this.presetCustomDefault.countries) ||
          (params.panels &&
            params.panels !== this.presetCustomDefault.panels) ||
          (!params.panels && null !== this.presetCustomDefault.panels) ||
          (params.factuality &&
            params.factuality !== this.presetCustomDefault.factuality) ||
          (!params.factuality &&
            null !== this.presetCustomDefault.factuality) ||
          (params.temporality &&
            params.temporality !== this.presetCustomDefault.temporality) ||
          (!params.temporality &&
            null !== this.presetCustomDefault.temporality) ||
          (params.event_ids &&
            params.event_ids !== this.presetCustomDefault.event_ids) ||
          (!params.event_ids && null !== this.presetCustomDefault.event_ids)
        ) {
          this.isFilterActive = true;
          if (this.auth.filter && this.auth.filter === 'Default') {
            this.auth.filter = 'Custom';
          }
          let presetId;
          // @ts-expect-error Description of why the error is expected
          this.presetsSelectionList.options._results.forEach((item, index) => {
            if (item._value === this.auth.filter) {
              presetId = index;
              setTimeout(() => {
                if (
                  this.presetsSelectionList &&
                  // @ts-expect-error Description of why the error is expected
                  this.presetsSelectionList.options._results
                ) {
                  this.presetsSelectionList.deselectAll();
                  // @ts-expect-error Description of why the error is expected
                  this.presetsSelectionList.options._results[
                    presetId
                  ]._setSelected(true);
                }
              }, 200);
            }
          });
        } else {
          this.isFilterActive = false;
          this.auth.filter = 'Default';
          setTimeout(() => {
            if (
              this.presetsSelectionList &&
              this.presetsSelectionList.options.first
            ) {
              this.presetsSelectionList.deselectAll();
              this.presetsSelectionList.options.first._setSelected(true);
            }
          }, 200);
        }
      }

      if (params.eventid && params.eventype === 'sub_event') {
        this.urlEvents = params.eventid.split(',');
        this.menuFilterEvents = new UntypedFormControl(
          params.eventid.split(','),
        );
      } else if (params.event_ids) {
        this.urlEvents = params.event_ids.split(',');
        this.menuFilterEvents = new UntypedFormControl(
          params.event_ids.split(','),
        );
      } else {
        this.urlEvents = [];
        this.menuFilterEvents = new UntypedFormControl();
      }
      this.events = [];
      this.subEvents = [];

      if (params.categories) {
        this.menuFilterCategories = new UntypedFormControl(
          this.filterCategories
            .filter(
              (option) => !params.categories.split(',').includes(option.value),
            )
            .map((option) => option.value),
        );
      } else {
        this.menuFilterCategories = new UntypedFormControl(
          this.filterCategories.map((option) => option.value),
        );
      }
      if (params.ranks) {
        this.menuFilterRanks = new UntypedFormControl(params.ranks.split(','));
      } else {
        this.menuFilterRanks = new UntypedFormControl();
      }
      if (params.panels) {
        this.menuFilterPanels = new UntypedFormControl(
          params.panels.split(','),
        );
      } else {
        this.menuFilterPanels = new UntypedFormControl();
      }
      let geography = [];
      if (params.continents) {
        const continentsArray = params.continents
          .split(',')
          .map((continent) => 'continent ' + continent);
        geography = geography.concat(continentsArray);
      }
      if (params.countries) {
        const countriesArray = params.countries
          .split(',')
          .map((country) => 'country ' + country);
        geography = geography.concat(countriesArray);
      }
      this.menuFilterGeography = new UntypedFormControl(geography);

      this.geographyForm = this.formBuilder.group({
        geographyGroup: '',
      });

      this.geographyGroupOptions = this.geographyForm
        .get('geographyGroup')!
        .valueChanges.pipe(
          startWith(''),
          map((value) => this.groupMatches(value)),
        );

      let tempAndFact = [];
      if (params.factuality) {
        const factualityArray = params.factuality.split(',');
        tempAndFact = tempAndFact.concat(factualityArray);
      }
      if (params.temporality) {
        const temporalityArray = params.temporality.split(',');
        tempAndFact = tempAndFact.concat(temporalityArray);
      }
      this.menuFilterFactTemp = new UntypedFormControl(tempAndFact);

      if (
        this.filterLang.length === 0 ||
        this.filterCategories.length === 0 ||
        this.filterRanks.length === 0 ||
        this.filterGeography.continents.length === 0 ||
        this.filterGeography.countries.length === 0 ||
        this.filterPanels.length === 0 ||
        this.filterEvents.length === 0
      ) {
        this.yukkApi.getFiltersList().subscribe((result) => {
          const languages = result.filter(
            (element) => element.filter === 'lang',
          );
          const documentCategories = result.filter(
            (element) => element.filter === 'document_categories',
          );
          const sourceRanks = result.filter(
            (element) => element.filter === 'source_ranks',
          );
          const sourceContinents = result.filter(
            (element) => element.filter === 'source_continents',
          );
          const sourceCountries = result.filter(
            (element) => element.filter === 'source_countries',
          );
          const sourcePanels = result.filter(
            (element) => element.filter === 'source_panels',
          );
          const events = result.filter(
            (element) => element.filter === 'events',
          );
          if (events.length === 1) {
            this.filterEvents = [];
            this.auth.eventsList = {
              events: [],
              subEvents: {},
            };
            events[0].values.forEach((superEvent) => {
              superEvent.children.forEach((event) => {
                this.filterEvents.push({
                  label: event.entity.name,
                  value: event.entity.alpha_id,
                  selected: false,
                });
                this.auth.eventsList.events.push({
                  label: event.entity.name,
                  value: event.entity.alpha_id,
                });
                this.filterSubEvents[event.entity.alpha_id] = [];
                this.auth.eventsList.subEvents[event.entity.alpha_id] = [];
                event.children.forEach((subevent) => {
                  this.filterSubEvents[event.entity.alpha_id].push({
                    label: subevent.entity.name,
                    value: subevent.entity.alpha_id,
                    selected: false,
                  });
                  this.auth.eventsList.subEvents[event.entity.alpha_id].push({
                    label: subevent.entity.name,
                    value: subevent.entity.alpha_id,
                  });
                  this.allSubEvents.push(subevent);
                });
              });
            });
            this.filterEvents.forEach((element, index) => {
              if (
                this.urlEvents.length !== 0 &&
                this.urlEvents.includes(element.value)
              ) {
                this.filterEvents[index].selected = true;
                this.events.push(element.value);
              } else {
                this.filterEvents[index].selected = false;
              }
              this.filterSubEvents[element.value].forEach(
                (element2, index2) => {
                  if (
                    this.urlEvents.length !== 0 &&
                    this.urlEvents.includes(element2.value)
                  ) {
                    this.filterSubEvents[element.value][index2].selected = true;
                    this.subEvents.push(element2.value);
                  } else {
                    this.filterSubEvents[element.value][index2].selected =
                      false;
                  }
                },
              );
            });
          }
          if (documentCategories.length === 1) {
            this.filterCategories = documentCategories[0].values
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((element) => {
                return {
                  value: element.value,
                  label: element.name,
                  selected: !this.defaultDisabledCategories.includes(
                    element.value,
                  ),
                };
              });
            if (params.categories) {
              this.menuFilterCategories = new UntypedFormControl(
                this.filterCategories
                  .filter(
                    (option) =>
                      !params.categories.split(',').includes(option.value),
                  )
                  .map((option) => option.value),
              );
            } else {
              this.menuFilterCategories = new UntypedFormControl(
                this.filterCategories.map((option) => option.value),
              );
            }
            this.filterCategories.forEach((element, index) => {
              if (this.menuFilterCategories.value !== null) {
                this.filterCategories[index].selected =
                  this.menuFilterCategories.value.includes(element.value);
              } else {
                this.filterCategories[index].selected =
                  !this.defaultDisabledCategories.includes(element.value);
              }
            });
          } else {
            this.filterCategories = [];
          }
          if (languages.length === 1) {
            this.filterLang = languages[0].values.map((element) => {
              return {
                value: element.value,
                label: element.name,
              };
            });
            if (!this.auth.featureFlags.betaLanguages) {
              this.filterLang = this.filterLang.filter((el) =>
                ['en', 'de'].includes(el.value),
              );
            }
            if (params.lang) {
              this.menuFilterLang = new UntypedFormControl(
                params.lang.split(','),
              );
              this.menuButtonLang = this.filterLang.filter(
                (option) => params.lang.split(',').indexOf(option.value) !== -1,
              );
            } else {
              // this.menuFilterLang = new UntypedFormControl(this.filterLang.map(option => option.value));
              this.menuFilterLang = new UntypedFormControl(
                this.presetDefault.lang.split(','),
              );
              this.menuButtonLang = this.filterLang.filter(
                (option) =>
                  this.menuFilterLang.value.indexOf(option.value) !== -1,
              );
            }
          } else {
            this.filterLang = [];
          }
          if (sourceRanks.length === 1) {
            this.filterRanks = sourceRanks[0].values.map((element) => {
              return {
                value: element.value.toString(),
                label: 'Rank ' + element.name,
              };
            });
          } else {
            this.filterRanks = [];
          }
          if (sourceContinents.length === 1) {
            this.filterGeography.continents = sourceContinents[0].values.map(
              (element) => {
                return {
                  value: 'continent ' + element.value,
                  label: element.name,
                  selected: false,
                };
              },
            );
            this.filterGeography.continents.forEach((element, index) => {
              if (this.menuFilterGeography.value !== null) {
                this.filterGeography.continents[index].selected =
                  this.menuFilterGeography.value.includes(element.value);
              } else {
                this.filterGeography.continents[index].selected = false;
              }
            });
          } else {
            this.filterGeography.continents = [];
          }
          if (sourceCountries.length === 1) {
            this.filterGeography.countries = sourceCountries[0].values.map(
              (element) => {
                return {
                  value: 'country ' + element.value,
                  label: element.name,
                  selected: false,
                };
              },
            );
            this.filterGeography.countries.forEach((element, index) => {
              if (this.menuFilterGeography.value !== null) {
                this.filterGeography.countries[index].selected =
                  this.menuFilterGeography.value.includes(element.value);
              } else {
                this.filterGeography.countries[index].selected = false;
              }
            });
          } else {
            this.filterGeography.countries = [];
          }
          if (sourcePanels.length === 1) {
            this.filterPanels = sourcePanels[0].values.map((element) => {
              return {
                value: element.value.toString(),
                label: element.name,
              };
            });
          } else {
            this.filterPanels = [];
          }

          this.geographyGroups = [
            {
              type: 'Continents',
              names: this.filterGeography.continents.map(
                (continent) => continent.label,
              ),
            },
            {
              type: 'Countries',
              names: this.filterGeography.countries.map(
                (country) => country.label,
              ),
            },
          ];
        });
      } else {
        this.filterCategories.forEach((element, index) => {
          if (this.menuFilterCategories.value !== null) {
            this.filterCategories[index].selected =
              this.menuFilterCategories.value.includes(element.value);
          } else {
            this.filterCategories[index].selected =
              !this.defaultDisabledCategories.includes(element.value);
          }
        });
        this.filterGeography.continents.forEach((element, index) => {
          if (this.menuFilterGeography.value !== null) {
            this.filterGeography.continents[index].selected =
              this.menuFilterGeography.value.includes(element.value);
          } else {
            this.filterGeography.continents[index].selected = false;
          }
        });
        this.filterGeography.countries.forEach((element, index) => {
          if (this.menuFilterGeography.value !== null) {
            this.filterGeography.countries[index].selected =
              this.menuFilterGeography.value.includes(element.value);
          } else {
            this.filterGeography.countries[index].selected = false;
          }
        });
        this.filterEvents.forEach((element, index) => {
          if (
            this.urlEvents.length !== 0 &&
            this.urlEvents.includes(element.value)
          ) {
            this.filterEvents[index].selected = true;
            this.events.push(element.value);
          } else {
            this.filterEvents[index].selected = false;
          }
          this.filterSubEvents[element.value].forEach((element2, index2) => {
            if (
              this.urlEvents.length !== 0 &&
              this.urlEvents.includes(element2.value)
            ) {
              this.filterSubEvents[element.value][index2].selected = true;
              this.subEvents.push(element2.value);
            } else {
              this.filterSubEvents[element.value][index2].selected = false;
            }
          });
        });
        this.filterFactTemp.factuality.forEach((element, index) => {
          if (this.menuFilterFactTemp.value !== null) {
            this.filterFactTemp.factuality[index].selected =
              this.menuFilterFactTemp.value.includes(element.value);
          } else {
            this.filterFactTemp.factuality[index].selected = false;
          }
        });
        this.filterFactTemp.temporality.forEach((element, index) => {
          if (this.menuFilterFactTemp.value !== null) {
            this.filterFactTemp.temporality[index].selected =
              this.menuFilterFactTemp.value.includes(element.value);
          } else {
            this.filterFactTemp.temporality[index].selected = false;
          }
        });
      }

      this.period = params.period ? params.period : '7';
      if (params.filter) {
        this.menuFilterSentiment = new UntypedFormControl(
          params.filter.split(','),
        );
      } else {
        this.menuFilterSentiment = new UntypedFormControl(
          this.filterSentiment.map((option) => option.value),
        );
      }
      if (params.lang) {
        this.menuFilterLang = new UntypedFormControl(params.lang.split(','));
        this.menuButtonLang = this.filterLang.filter(
          (option) => params.lang.split(',').indexOf(option.value) !== -1,
        );
      } else {
        this.menuFilterLang = new UntypedFormControl(
          this.presetDefault.lang.split(','),
        );
        this.menuButtonLang = this.filterLang.filter(
          (option) => this.menuFilterLang.value.indexOf(option.value) !== -1,
        );
      }
      if (params.feed) {
        this.menuFilterNews = new UntypedFormControl(params.feed.split(','));
        this.menuButtonNews = this.filterNews.filter(
          (option) => params.feed.split(',').indexOf(option.value) !== -1,
        );
      } else {
        this.menuFilterNews = new UntypedFormControl(
          this.filterNews.map((option) => {
            if (option.active) {
              return option.value;
            }
          }),
        );
        this.menuButtonNews = this.filterNews.filter(
          (option) => this.menuFilterNews.value.indexOf(option.value) !== -1,
        );
      }
      if (this.config.appConfig.setup.useStorage) {
        this.itheme = localStorage.getItem('theme');
      }
      this.params = params;
      this.fullscreen = params.fullscreen === 'true' ? true : false;
      if (params.feed) {
        this.feed = params.feed;
      } else {
        this.feed = 'all';
      }
      if (params.time) {
        this.time = params.time;
        if (
          params.time === '1' ||
          params.time === '2' ||
          params.time === '7' ||
          params.time === '14' ||
          params.time === '30' ||
          params.time === '90' ||
          params.time === '180' ||
          params.time === '365'
        ) {
          this.menuFilterTimeframe = new UntypedFormControl(
            params.time.split(','),
          );
        } else {
          this.menuFilterTimeframe = new UntypedFormControl(['0']);
        }
      } else {
        this.time = '7';
        this.menuFilterTimeframe = new UntypedFormControl(['7']);
      }
      this.timename = this.timestring[this.timenumber.indexOf(this.time)];
      if (this.timename === 'custom') {
        this.timename = params.time;
      }
    });
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  /**
   * parse the time
   */
  goTime(value) {
    let period = value;
    if (value === 30) {
      period = 7;
    }
    if (value === 90) {
      period = 14;
    }
    if (value === 180) {
      period = 14;
    }
    if (value === 365) {
      period = 14;
    }
    this.router.navigate([], {
      queryParams: {
        time: value,
        period: period,
        tag: null,
        newsday: null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  /**
   * open dialog datepicher
   */
  goCustom() {
    let time = this.time;
    if (this.time === '0') {
      time = this.params.time;
    }
    const dialogRef = this.dialog.open(DatepicherComponent, {
      data: {
        time: time,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (
        result &&
        Object.prototype.hasOwnProperty.call(result, 'time') &&
        Object.prototype.hasOwnProperty.call(result, 'period')
      ) {
        this.time = result.time;
        this.period = result.period;
      } else {
        if (this.params.time) {
          if (this.params.time < 500) {
            this.menuFilterTimeframe = new UntypedFormControl(
              this.params.time.split(','),
            );
          } else {
            this.menuFilterTimeframe = new UntypedFormControl(['0']);
          }
        } else {
          this.menuFilterTimeframe = new UntypedFormControl(['7']);
        }
      }
    });
  }

  /**
   * go fullscreen
   */
  goFullscreen() {
    if (this.fullscreen) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  /**
   * change the feed type
   */
  goFeed(value) {
    this.router.navigate([], {
      queryParams: {
        feed: value,
        newsday: null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  /**
   * change sort by
   */
  goSortBy(value) {
    this.router.navigate([], {
      queryParams: {
        sort: value,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  /**
   * change the theme
   */
  inTheme(theme) {
    // localStorage.set('theme', theme)
    this.router.navigate([], {
      queryParams: {
        theme: theme,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
    // this.routing.inTheme(theme)
  }

  handleSearch(name, type) {
    const geographyArray = this.menuFilterGeography.value;
    if (type === 'Continents') {
      this.filterGeography.continents.forEach((element, index) => {
        if (element.label === name && element.selected === false) {
          this.filterGeography.continents[index].selected = true;
          geographyArray.push(element.value);
          this.menuFilterGeography.setValue(geographyArray);
        }
      });
    } else if (type === 'Countries') {
      this.filterGeography.countries.forEach((element, index) => {
        if (element.label === name && element.selected === false) {
          this.filterGeography.countries[index].selected = true;
          geographyArray.push(element.value);
          this.menuFilterGeography.setValue(geographyArray);
        }
      });
    }
  }

  /**
   * flag selected items from multi-selection lists
   */
  handleSelection(element) {
    element.selected = !element.selected;
    this.presetsSelectionList.deselectAll();
    this.auth.filter = 'Custom';
  }

  /**
   * select or deselect all events at once
   */
  toggleAllEvents() {
    const events = [];
    this.filterEvents.forEach((event) => {
      this.filterSubEvents[event.value].forEach((subevent) => {
        events.push(subevent.value);
      });
    });
    if (this.menuFilterEvents.value?.length !== this.allSubEvents?.length) {
      this.filterEvents.forEach((event) => {
        this.filterSubEvents[event.value].forEach((subevent) => {
          subevent.selected = true;
        });
      });
    } else {
      this.filterEvents.forEach((event) => {
        this.filterSubEvents[event.value].forEach((subevent) => {
          subevent.selected = false;
        });
      });
    }
    if (
      this.menuFilterEvents.value &&
      (this.menuFilterEvents.value.length === events.length ||
        this.menuFilterEvents.value.length === 0)
    ) {
      this.presetsSelectionList.deselectAll();
      this.auth.filter = 'Custom';
    }
    if (this.menuFilterEvents.value?.length !== events?.length) {
      this.menuFilterEvents.setValue(events);
    } else {
      this.menuFilterEvents.setValue([]);
    }
  }

  /**
   * tooltip for events selection checkbox based on current selection status
   */
  eventsToggleTooltip() {
    if (this.menuFilterEvents.value?.length !== this.allSubEvents?.length) {
      return 'Select all events';
    } else {
      return 'Deselect all events';
    }
  }

  /**
   * on user's click remove filter or change to default one
   * @param type
   * @param value
   */
  removeFilter(type, value) {
    if (type === 'tag') {
      this.tagFilter = null;
      this.router.navigate([], {
        queryParams: {
          tag: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    } else if (type === 'participant') {
      this.participantFilter = null;
      this.router.navigate([], {
        queryParams: {
          participant: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    } else {
      if (type === 'timeframe') {
        this.menuFilterTimeframe = new UntypedFormControl(
          this.presetCustomDefault.time.split(','),
        );
        this.presetsSelectionList.deselectAll();
        this.auth.filter = 'Custom';
      } else if (type === 'sentiment') {
        if (this.menuFilterSentiment.value.length > 1) {
          const sentiment = this.menuFilterSentiment.value.filter(
            (item) => item !== value,
          );
          this.menuFilterSentiment = new UntypedFormControl(sentiment);
        } else {
          this.menuFilterSentiment = new UntypedFormControl(
            this.presetDefault.filter.split(','),
          );
        }
        this.presetsSelectionList.deselectAll();
        this.auth.filter = 'Custom';
      } else if (type === 'language') {
        if (this.menuFilterLang.value.length > 1) {
          const language = this.menuFilterLang.value.filter(
            (item) => item !== value,
          );
          this.menuFilterLang = new UntypedFormControl(language);
        } else {
          this.menuFilterLang = new UntypedFormControl(
            this.presetDefault.lang.split(','),
          );
        }
        this.presetsSelectionList.deselectAll();
        this.auth.filter = 'Custom';
      } else if (type === 'news') {
        if (this.menuFilterNews.value.length > 1) {
          const newsfeed = this.menuFilterNews.value.filter(
            (item) => item !== value,
          );
          this.menuFilterNews = new UntypedFormControl(newsfeed);
        } else {
          this.menuFilterNews = new UntypedFormControl(
            this.presetDefault.feed.split(','),
          );
        }
        this.presetsSelectionList.deselectAll();
        this.auth.filter = 'Custom';
      } else if (type === 'rank') {
        const ranks = this.menuFilterRanks.value.filter(
          (item) => item !== value,
        );
        this.menuFilterRanks = new UntypedFormControl(ranks);
        this.presetsSelectionList.deselectAll();
        this.auth.filter = 'Custom';
      } else if (type === 'panel') {
        const panels = this.menuFilterPanels.value.filter(
          (item) => item !== value,
        );
        this.menuFilterPanels = new UntypedFormControl(panels);
        this.presetsSelectionList.deselectAll();
        this.auth.filter = 'Custom';
      } else if (type === 'category') {
        const categories = this.menuFilterCategories.value.filter(
          (item) => item !== value,
        );
        this.menuFilterCategories = new UntypedFormControl(categories);
        this.presetsSelectionList.deselectAll();
        this.auth.filter = 'Custom';
      } else if (type === 'continent' || type === 'country') {
        const geographies = this.menuFilterGeography.value.filter(
          (item) => item !== value,
        );
        this.menuFilterGeography = new UntypedFormControl(geographies);
        this.presetsSelectionList.deselectAll();
        this.auth.filter = 'Custom';
      } else if (type === 'subevent') {
        const events = this.menuFilterEvents.value.filter(
          (item) => item !== value,
        );
        this.menuFilterEvents = new UntypedFormControl(events);
        this.presetsSelectionList.deselectAll();
        this.auth.filter = 'Custom';
      } else if (type === 'factuality' || type === 'temporality') {
        const facttemp = this.menuFilterFactTemp.value.filter(
          (item) => item !== value,
        );
        this.menuFilterFactTemp = new UntypedFormControl(facttemp);
        this.presetsSelectionList.deselectAll();
        this.auth.filter = 'Custom';
      }
      this.setFilterOptions();
    }
  }

  /**
   * deselect selected preset on filters change
   */
  handleNoSelection() {
    this.presetsSelectionList.deselectAll();
    this.auth.filter = 'Custom';
  }

  /**
   * apply selected preset to all filters
   */
  handlePresetSelection(selectedPreset) {
    this.auth.filter = selectedPreset.label;
    if (selectedPreset.presetCopy.time) {
      this.time = selectedPreset.presetCopy.time;
      if (
        selectedPreset.presetCopy.time === '1' ||
        selectedPreset.presetCopy.time === '2' ||
        selectedPreset.presetCopy.time === '7' ||
        selectedPreset.presetCopy.time === '14' ||
        selectedPreset.presetCopy.time === '30' ||
        selectedPreset.presetCopy.time === '90' ||
        selectedPreset.presetCopy.time === '180' ||
        selectedPreset.presetCopy.time === '365'
      ) {
        this.menuFilterTimeframe = new UntypedFormControl(
          selectedPreset.presetCopy.time.split(','),
        );
      } else {
        this.menuFilterTimeframe = new UntypedFormControl(['0']);
      }
    } else {
      this.time = '7';
      this.menuFilterTimeframe = new UntypedFormControl(['7']);
    }
    this.timename = this.timestring[this.timenumber.indexOf(this.time)];
    if (this.timename === 'custom') {
      this.timename = selectedPreset.presetCopy.time;
    }

    if (selectedPreset.presetCopy.filter) {
      this.menuFilterSentiment = new UntypedFormControl(
        selectedPreset.presetCopy.filter.split(','),
      );
    } else {
      this.menuFilterSentiment = new UntypedFormControl(
        this.filterSentiment.map((option) => option.value),
      );
    }

    if (selectedPreset.presetCopy.lang) {
      this.menuFilterLang = new UntypedFormControl(
        selectedPreset.presetCopy.lang.split(','),
      );
    } else {
      this.menuFilterLang = new UntypedFormControl(
        this.filterLang.map((option) => option.value),
      );
    }

    if (selectedPreset.presetCopy.ranks) {
      this.menuFilterRanks = new UntypedFormControl(
        selectedPreset.presetCopy.ranks.split(','),
      );
    } else {
      this.menuFilterRanks = new UntypedFormControl();
    }

    if (selectedPreset.presetCopy.panels) {
      this.menuFilterPanels = new UntypedFormControl(
        selectedPreset.presetCopy.panels.split(','),
      );
    } else {
      this.menuFilterPanels = new UntypedFormControl();
    }

    if (selectedPreset.presetCopy.categories) {
      this.menuFilterCategories = new UntypedFormControl(
        this.filterCategories
          .filter(
            (option) =>
              !selectedPreset.presetCopy.categories
                .split(',')
                .includes(option.value),
          )
          .map((option) => option.value),
      );
    } else {
      this.menuFilterCategories = new UntypedFormControl(
        this.filterCategories
          .filter(
            (option) => !this.defaultDisabledCategories.includes(option.value),
          )
          .map((option) => option.value),
      );
    }
    this.filterCategories.forEach((element, index) => {
      if (this.menuFilterCategories.value !== null) {
        this.filterCategories[index].selected =
          this.menuFilterCategories.value.includes(element.value);
      } else {
        this.filterCategories[index].selected =
          !this.defaultDisabledCategories.includes(element.value);
      }
    });

    let geography = [];
    if (selectedPreset.presetCopy.continents) {
      const continentsArray = selectedPreset.presetCopy.continents
        .split(',')
        .map((continent) => 'continent ' + continent);
      geography = geography.concat(continentsArray);
    }
    if (selectedPreset.presetCopy.countries) {
      const countriesArray = selectedPreset.presetCopy.countries
        .split(',')
        .map((country) => 'country ' + country);
      geography = geography.concat(countriesArray);
    }
    this.menuFilterGeography = new UntypedFormControl(geography);
    this.filterGeography.continents.forEach((element, index) => {
      if (this.menuFilterGeography.value !== null) {
        this.filterGeography.continents[index].selected =
          this.menuFilterGeography.value.includes(element.value);
      } else {
        this.filterGeography.continents[index].selected = false;
      }
    });
    this.filterGeography.countries.forEach((element, index) => {
      if (this.menuFilterGeography.value !== null) {
        this.filterGeography.countries[index].selected =
          this.menuFilterGeography.value.includes(element.value);
      } else {
        this.filterGeography.countries[index].selected = false;
      }
    });

    let facttemp = [];
    if (selectedPreset.presetCopy.factuality) {
      const factualityArray = selectedPreset.presetCopy.factuality.split(',');
      facttemp = facttemp.concat(factualityArray);
    }
    if (selectedPreset.presetCopy.temporality) {
      const temporalityArray = selectedPreset.presetCopy.temporality.split(',');
      facttemp = facttemp.concat(temporalityArray);
    }
    this.menuFilterFactTemp = new UntypedFormControl(facttemp);
    this.filterFactTemp.factuality.forEach((element, index) => {
      if (this.menuFilterFactTemp.value !== null) {
        this.filterFactTemp.factuality[index].selected =
          this.menuFilterFactTemp.value.includes(element.value);
      } else {
        this.filterFactTemp.factuality[index].selected = false;
      }
    });
    this.filterFactTemp.temporality.forEach((element, index) => {
      if (this.menuFilterFactTemp.value !== null) {
        this.filterFactTemp.temporality[index].selected =
          this.menuFilterFactTemp.value.includes(element.value);
      } else {
        this.filterFactTemp.temporality[index].selected = false;
      }
    });

    if (selectedPreset.presetCopy.event_ids) {
      this.urlEvents = selectedPreset.presetCopy.event_ids.split(',');
      this.menuFilterEvents = new UntypedFormControl(
        selectedPreset.presetCopy.event_ids.split(','),
      );
    } else {
      this.urlEvents = [];
      this.menuFilterEvents = new UntypedFormControl();
    }
    this.events = [];
    this.subEvents = [];
    this.filterEvents.forEach((element, index) => {
      if (
        this.urlEvents.length !== 0 &&
        this.urlEvents.includes(element.value)
      ) {
        this.filterEvents[index].selected = true;
        this.events.push(element.value);
      } else {
        this.filterEvents[index].selected = false;
      }
      this.filterSubEvents[element.value].forEach((element2, index2) => {
        if (
          this.urlEvents.length !== 0 &&
          this.urlEvents.includes(element2.value)
        ) {
          this.filterSubEvents[element.value][index2].selected = true;
          this.subEvents.push(element2.value);
        } else {
          this.filterSubEvents[element.value][index2].selected = false;
        }
      });
    });

    if (selectedPreset.presetCopy.feed) {
      this.menuFilterNews = new UntypedFormControl(
        selectedPreset.presetCopy.feed.split(','),
      );
    } else {
      this.menuFilterNews = new UntypedFormControl(
        this.filterNews.map((option) => {
          if (option.active) {
            return option.value;
          }
        }),
      );
    }
  }

  /**
   * make single selection work for 'mat-selection-list'
   * @param event
   * @param type
   */
  handleSingleSelection(event, type) {
    if (event.options[0].selected) {
      event.source.deselectAll();
      event.options[0]._setSelected(true);
      // if (type === 'sentiment') {
      //   this.menuFilterSentiment = new UntypedFormControl( event.options[0].value.split() );
      //   this.presetsSelectionList.deselectAll();
      //   this.auth.filter = 'Custom';
      // }
      if (type === 'timeframe') {
        this.menuFilterTimeframe = new UntypedFormControl(
          event.options[0].value.split(),
        );
        this.presetsSelectionList.deselectAll();
        this.auth.filter = 'Custom';
      } else if (type === 'preset') {
        this.menuFilterTimeframe = new UntypedFormControl(
          event.options[0].value.split(),
        );
        const selectedPreset = this.filterPresets.filter((preset) => {
          return preset.value === event.options[0].value;
        })[0];
        this.handlePresetSelection(selectedPreset);
        if (selectedPreset.presetCopy.time) {
          const dateFrom = this.yukkApi.myFromTo(
            selectedPreset.presetCopy.time,
          ).date1;
          const dateTo = this.yukkApi.myFromTo(
            selectedPreset.presetCopy.time,
          ).date2;
          const startDay = this.config.appConfig.setup.startDate;
          const maxDays = this.auth.internalCockpitSettings.maxTimeframe;
          const days = moment(dateTo).diff(moment(dateFrom), 'days');
          if (dateFrom < startDay || dateTo < startDay) {
            alert(
              'Please select another filter, timeframe must be above ' +
                startDay +
                '.',
            );
          }
          if (!this.routing.isDev() && days > maxDays) {
            alert(
              'Please select another filter, the date range cannot exceed ' +
                maxDays +
                ' days.',
            );
          }
        }
      }
    } else {
      if (type === 'preset') {
        event.source.deselectAll();
        event.options[0]._setSelected(true);
      }
    }
  }

  /**
   * handle custom timeframe selection
   * @param value
   */
  handleCustomTimeframeSelection(value) {
    if (value === '0') {
      this.goCustom();
    }
  }

  /**
   * returns matching items
   */
  elementMatches(options, value) {
    const filterValue = value.toLowerCase();
    return options.filter(
      (item) => item.toLowerCase().indexOf(filterValue) === 0,
    );
  }

  /**
   * returns matching group's items
   */
  groupMatches(value) {
    if (value) {
      return this.geographyGroups
        .map((group) => ({
          type: group.type,
          names: this.elementMatches(group.names, value),
        }))
        .filter((group) => group.names.length > 0);
    }
    return this.geographyGroups;
  }

  /**
   * clear all filters if not applied on close
   */
  resetFilterOptions() {
    if (!this.omitReset) {
      this.router.navigate([], {
        queryParams: {
          resetFilters: Math.random(),
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
    this.omitReset = false;
  }

  /**
   * setup all the filtering options from the main filter menu
   */
  setFilterOptions() {
    this.omitReset = true;

    // timeframe filter options

    const timeframeValue = this.menuFilterTimeframe.value[0];
    let time = this.time;
    let period = this.period;
    if (timeframeValue === '0') {
      // do nothing
    } else if (timeframeValue === '1') {
      time = timeframeValue;
      period = '7';
    } else if (timeframeValue === '2') {
      time = timeframeValue;
      period = '7';
    } else if (timeframeValue === '7') {
      time = timeframeValue;
      period = '7';
    } else if (timeframeValue === '30') {
      time = timeframeValue;
      period = '7';
    } else if (timeframeValue === '90') {
      time = timeframeValue;
      period = '14';
    } else if (timeframeValue === '180') {
      time = timeframeValue;
      period = '14';
    } else if (timeframeValue === '365') {
      time = timeframeValue;
      period = '14';
    }

    // sentiment filter options

    // let sentiment = this.menuFilterSentiment.value[0];
    // if ( sentiment === 'all' ) {
    //   sentiment = null;
    // }

    let sentiment = this.menuFilterSentiment.value.join(',');
    if (this.menuFilterSentiment.value.length === this.filterSentiment.length) {
      sentiment = null;
    }
    if (this.menuFilterSentiment.value.length === 0) {
      this.menuFilterSentiment = new UntypedFormControl(
        this.filterSentiment.map((option) => option.value),
      );
      sentiment = null;
    }

    // language filter options

    let lang = this.menuFilterLang.value.join(',');
    // if (this.menuFilterLang.value.length === this.filterLang.length) {
    //   lang = null;
    // }
    if (this.menuFilterLang.value.length === 0) {
      this.menuFilterLang = new UntypedFormControl(
        this.filterLang.map((option) => option.value),
      );
      lang = null;
    }
    this.menuButtonLang = this.filterLang.filter(
      (option) => this.menuFilterLang.value.indexOf(option.value) !== -1,
    );

    // newsfeed filter options

    let feed = this.menuFilterNews.value.join(',');
    // if (this.menuFilterNews.value.length === this.filterNews.length) {
    //   feed = null
    // }
    if (this.menuFilterNews.value.length === 0) {
      feed = null;
      this.menuFilterNews = new UntypedFormControl(
        this.filterNews.map((option) => option.value),
      );
    }
    this.menuButtonNews = this.filterNews.filter(
      (option) => this.menuFilterNews.value.indexOf(option.value) !== -1,
    );

    // event filter options

    let events = null;
    if (
      this.menuFilterEvents.value &&
      this.menuFilterEvents.value.length !== 0
    ) {
      events = this.menuFilterEvents.value.join(',');
    }
    const checkIfEventUnselected =
      this.params.eventid &&
      this.menuFilterEvents.value.length === 1 &&
      this.menuFilterEvents.value.includes(this.params.eventid)
        ? false
        : true;

    // document categories filter options

    let categories = null;
    if (
      this.menuFilterCategories.value &&
      this.menuFilterCategories.value.length !== 0
    ) {
      categories = this.filterCategories
        .filter(
          (option) => !this.menuFilterCategories.value.includes(option.value),
        )
        .map((option) => option.value)
        .join(',');
    }

    // source continents filter options

    let continents = null;
    if (
      this.menuFilterGeography.value &&
      this.menuFilterGeography.value.length !== 0
    ) {
      const continentsArray = this.menuFilterGeography.value.filter((item) => {
        return (
          this.filterGeography.continents.filter((element) => {
            return item === element.value;
          }).length !== 0
        );
      });
      if (continentsArray.length !== 0) {
        continents = continentsArray
          .map((item) => item.split(' ').pop())
          .join(',');
      }
    }

    // source countries filter options

    let countries = null;
    if (
      this.menuFilterGeography.value &&
      this.menuFilterGeography.value.length !== 0
    ) {
      const countriesArray = this.menuFilterGeography.value.filter((item) => {
        return (
          this.filterGeography.countries.filter((element) => {
            return item === element.value;
          }).length !== 0
        );
      });
      if (countriesArray.length !== 0) {
        countries = countriesArray
          .map((item) => item.split(' ').pop())
          .join(',');
      }
    }

    // source factuality filter options

    let factuality = null;
    if (
      this.menuFilterFactTemp.value &&
      this.menuFilterFactTemp.value.length !== 0
    ) {
      const factualityArray = this.menuFilterFactTemp.value.filter((item) => {
        return (
          this.filterFactTemp.factuality.filter((element) => {
            return item === element.value;
          }).length !== 0
        );
      });
      if (factualityArray.length !== 0) {
        factuality = factualityArray
          .map((item) => item.split(' ').pop())
          .join(',');
      }
    }

    // source temporality filter options

    let temporality = null;
    if (
      this.menuFilterFactTemp.value &&
      this.menuFilterFactTemp.value.length !== 0
    ) {
      const temporalityArray = this.menuFilterFactTemp.value.filter((item) => {
        return (
          this.filterFactTemp.temporality.filter((element) => {
            return item === element.value;
          }).length !== 0
        );
      });
      if (temporalityArray.length !== 0) {
        temporality = temporalityArray
          .map((item) => item.split(' ').pop())
          .join(',');
      }
    }

    // source ranks filter options

    let ranks = null;
    if (this.menuFilterRanks.value && this.menuFilterRanks.value.length !== 0) {
      ranks = this.menuFilterRanks.value.join(',');
    }

    // source panels filter options

    let panels = null;
    if (
      this.menuFilterPanels.value &&
      this.menuFilterPanels.value.length !== 0
    ) {
      panels = this.menuFilterPanels.value.join(',');
    }

    // const dateFrom = this.yukkApi.myFromTo(time).date1;
    // const dateTo = this.yukkApi.myFromTo(time).date2;
    // const startDay = this.config.appConfig.setup.startDate;
    // const maxDays = this.auth.internalCockpitSettings.maxTimeframe;
    // const days = moment(dateTo).diff(moment(dateFrom), 'days');
    // if ((dateFrom < startDay) || (dateTo < startDay)) {
    //   alert('Please select another filter, timeframe must be above ' + startDay + '.');
    // }
    // if ((!this.routing.isDev()) && (days > maxDays)) {
    //   alert('Please select another filter, the date range cannot exceed ' + maxDays + ' days.');
    // }

    this.router.navigate([], {
      queryParams: {
        time: time,
        period: period,
        filter: sentiment,
        lang: lang,
        feed: feed,
        event_ids: checkIfEventUnselected
          ? events
          : this.params.event_ids
            ? this.params.event_ids
            : null,
        eventid: checkIfEventUnselected
          ? null
          : this.params.eventid
            ? this.params.eventid
            : null,
        eventype: checkIfEventUnselected
          ? null
          : this.params.eventype
            ? this.params.eventype
            : null,
        factuality: factuality,
        temporality: temporality,
        categories: categories,
        continents: continents,
        countries: countries,
        panels: panels,
        ranks: ranks,
        tag: this.tagFilter,
        participant: this.participantFilter,
        newsday: null,
        // score: null
      },
      queryParamsHandling: 'merge' /*, replaceUrl: true*/,
    });
  }

  /**
   * change the language
   */
  inLang(event) {
    if (!event) {
      let lang = this.menuFilterLang.value.join(',');
      // if (this.menuFilterLang.value.length === this.filterLang.length) {
      //   lang = null;
      // }
      if (this.menuFilterLang.value.length === 0) {
        this.menuFilterLang = new UntypedFormControl(
          this.filterLang.map((option) => option.value),
        );
        lang = null;
      }
      this.menuButtonLang = this.filterLang.filter(
        (option) => this.menuFilterLang.value.indexOf(option.value) !== -1,
      );
      this.router.navigate([], {
        queryParams: {
          lang: lang,
          tag: null,
          newsday: null,
        },
        queryParamsHandling: 'merge' /*, replaceUrl: true*/,
      });
    }
  }

  /**
   * parse the feed type
   */
  inNews(event) {
    if (!event) {
      let feed = this.menuFilterNews.value.join(',');
      // if (this.menuFilterNews.value.length === this.filterNews.length) {
      //   feed = null
      // }
      if (this.menuFilterNews.value.length === 0) {
        feed = null;
        this.menuFilterNews = new UntypedFormControl(
          this.filterNews.map((option) => option.value),
        );
      }
      this.menuButtonNews = this.filterNews.filter(
        (option) => this.menuFilterNews.value.indexOf(option.value) !== -1,
      );
      this.router.navigate([], {
        queryParams: {
          feed: feed,
          tag: null,
        },
        queryParamsHandling: 'merge' /*, replaceUrl: true*/,
      });
    }
  }

  /**
   * save a preset filter
   */
  savePreset() {
    // timeframe filter options

    const timeframeValue = this.menuFilterTimeframe.value[0];
    let time = this.time;
    let period = this.period;
    if (timeframeValue === '0') {
      // do nothing
    } else if (timeframeValue === '1') {
      time = timeframeValue;
      period = '7';
    } else if (timeframeValue === '2') {
      time = timeframeValue;
      period = '7';
    } else if (timeframeValue === '7') {
      time = timeframeValue;
      period = '7';
    } else if (timeframeValue === '30') {
      time = timeframeValue;
      period = '7';
    } else if (timeframeValue === '90') {
      time = timeframeValue;
      period = '14';
    } else if (timeframeValue === '180') {
      time = timeframeValue;
      period = '14';
    } else if (timeframeValue === '365') {
      time = timeframeValue;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      period = '14';
    }

    // sentiment filter options

    // let sentiment = this.menuFilterSentiment.value[0];
    // if ( sentiment === 'all' ) {
    //   sentiment = null;
    // }

    let sentiment = this.menuFilterSentiment.value.join(',');
    if (this.menuFilterSentiment.value.length === this.filterSentiment.length) {
      sentiment = null;
    }
    if (this.menuFilterSentiment.value.length === 0) {
      this.menuFilterSentiment = new UntypedFormControl(
        this.filterSentiment.map((option) => option.value),
      );
      sentiment = null;
    }

    // language filter options

    let lang = this.menuFilterLang.value.join(',');
    // if (this.menuFilterLang.value.length === this.filterLang.length) {
    //   lang = null;
    // }
    if (this.menuFilterLang.value.length === 0) {
      this.menuFilterLang = new UntypedFormControl(
        this.filterLang.map((option) => option.value),
      );
      lang = null;
    }
    this.menuButtonLang = this.filterLang.filter(
      (option) => this.menuFilterLang.value.indexOf(option.value) !== -1,
    );

    // newsfeed filter options

    let feed = this.menuFilterNews.value.join(',');
    // if (this.menuFilterNews.value.length === this.filterNews.length) {
    //   feed = null
    // }
    if (this.menuFilterNews.value.length === 0) {
      feed = null;
      this.menuFilterNews = new UntypedFormControl(
        this.filterNews.map((option) => option.value),
      );
    }
    this.menuButtonNews = this.filterNews.filter(
      (option) => this.menuFilterNews.value.indexOf(option.value) !== -1,
    );

    // event filter options

    let events = null;
    if (
      this.menuFilterEvents.value &&
      this.menuFilterEvents.value.length !== 0
    ) {
      events = this.menuFilterEvents.value.join(',');
    }

    // document categories filter options

    let categories = null;
    if (
      this.menuFilterCategories.value &&
      this.menuFilterCategories.value.length !== 0
    ) {
      categories = this.filterCategories
        .filter(
          (option) => !this.menuFilterCategories.value.includes(option.value),
        )
        .map((option) => option.value)
        .join(',');
    }

    // source continents filter options

    let continents = null;
    if (
      this.menuFilterGeography.value &&
      this.menuFilterGeography.value.length !== 0
    ) {
      const continentsArray = this.menuFilterGeography.value.filter((item) => {
        return (
          this.filterGeography.continents.filter((element) => {
            return item === element.value;
          }).length !== 0
        );
      });
      if (continentsArray.length !== 0) {
        continents = continentsArray
          .map((item) => item.split(' ').pop())
          .join(',');
      }
    }

    // source countries filter options

    let countries = null;
    if (
      this.menuFilterGeography.value &&
      this.menuFilterGeography.value.length !== 0
    ) {
      const countriesArray = this.menuFilterGeography.value.filter((item) => {
        return (
          this.filterGeography.countries.filter((element) => {
            return item === element.value;
          }).length !== 0
        );
      });
      if (countriesArray.length !== 0) {
        countries = countriesArray
          .map((item) => item.split(' ').pop())
          .join(',');
      }
    }

    // source factuality filter options

    let factuality = null;
    if (
      this.menuFilterFactTemp.value &&
      this.menuFilterFactTemp.value.length !== 0
    ) {
      const factualityArray = this.menuFilterFactTemp.value.filter((item) => {
        return (
          this.filterFactTemp.factuality.filter((element) => {
            return item === element.value;
          }).length !== 0
        );
      });
      if (factualityArray.length !== 0) {
        factuality = factualityArray
          .map((item) => item.split(' ').pop())
          .join(',');
      }
    }

    // source temporality filter options

    let temporality = null;
    if (
      this.menuFilterFactTemp.value &&
      this.menuFilterFactTemp.value.length !== 0
    ) {
      const temporalityArray = this.menuFilterFactTemp.value.filter((item) => {
        return (
          this.filterFactTemp.temporality.filter((element) => {
            return item === element.value;
          }).length !== 0
        );
      });
      if (temporalityArray.length !== 0) {
        temporality = temporalityArray
          .map((item) => item.split(' ').pop())
          .join(',');
      }
    }

    // source ranks filter options

    let ranks = null;
    if (this.menuFilterRanks.value && this.menuFilterRanks.value.length !== 0) {
      ranks = this.menuFilterRanks.value.join(',');
    }

    // source panels filter options

    let panels = null;
    if (
      this.menuFilterPanels.value &&
      this.menuFilterPanels.value.length !== 0
    ) {
      panels = this.menuFilterPanels.value.join(',');
    }

    const forbiddenNames = [
      'Default',
      '_default_',
      'E events',
      'S events',
      'G events',
    ];
    const existingNames = this.filterPresets.map((preset) => {
      return preset.label;
    });

    const name = prompt('Name of the filter:', '');
    if (
      name &&
      name !== '' &&
      !forbiddenNames.includes(name) &&
      !existingNames.includes(name)
    ) {
      const filter = {
        name: name,
        time: time,
        filter: sentiment,
        lang: lang,
        feed: feed,
        event_ids: events,
        factuality: factuality,
        temporality: temporality,
        categories: categories,
        continents: continents,
        countries: countries,
        panels: panels,
        ranks: ranks,
      };
      this.filterPresets.splice(1, 0, {
        value: name,
        label: name,
        visible: false,
        presetCopy: filter,
      });
      this.auth.setFilter(filter).subscribe(() => {
        this.presetsSelectionList.deselectAll();
        // @ts-expect-error Description: This error is expected because the following code is accessing a private property.
        this.presetsSelectionList.options._results[1]._setSelected(true);
      });
    } else if (forbiddenNames.includes(name)) {
      alert(
        'Provided name is reserved for special filter presets. Please choose a different name.',
      );
    } else if (existingNames.includes(name)) {
      alert(
        'Provided name belongs to one of the existing filter presets. Please choose a different name.',
      );
    }
  }

  /**
   * delete a preset filter
   */
  delPreset(filter) {
    if (window.confirm('Delete ' + filter.value + ' filter?')) {
      this.filterPresets = this.filterPresets.filter((item) => {
        return item.value !== filter.value;
      });
      if (
        this.menuFilterPresets.value &&
        this.menuFilterPresets.value.includes(filter.value)
      ) {
        this.menuFilterPresets = new UntypedFormControl();
      }
      this.auth.getFilter().subscribe((result) => {
        const filterId = result.filter((item) => {
          return item.name === filter.value;
        })[0].id;
        this.auth.delFilter(filterId).subscribe();
      });
    }
  }

  /**
   * set chosen preset as a default filter
   */
  setDefaultPreset(filter) {
    if (window.confirm('Set ' + filter.value + ' as a default filter?')) {
      const defaultFilter = {};
      Object.assign(
        defaultFilter,
        {
          time: filter.presetCopy.time,
          feed: filter.presetCopy.feed,
          filter: filter.presetCopy.filter,
          lang: filter.presetCopy.lang,
          ranks: filter.presetCopy.ranks,
          panels: filter.presetCopy.panels,
          categories: filter.presetCopy.categories,
          continents: filter.presetCopy.continents,
          countries: filter.presetCopy.countries,
          factuality: filter.presetCopy.factuality,
          temporality: filter.presetCopy.temporality,
          event_ids: filter.presetCopy.event_ids,
        },
        {
          name: '_default_',
          defaultFilter: filter.label,
        },
      );
      if (this.idCustomDefault !== undefined) {
        this.auth.delFilter(this.idCustomDefault).subscribe(() => {
          this.auth.setFilter(defaultFilter).subscribe((result) => {
            Object.assign(this.filterPresets[0].presetCopy, defaultFilter);
            Object.assign(this.presetCustomDefault, defaultFilter);
            this.idCustomDefault = result.id;
            this.nameCustomDefault = filter.label;
            this.presetsSelectionList.deselectAll();
            this.presetsSelectionList.options.first._setSelected(true);
            this.handlePresetSelection({
              value: filter.value,
              label: filter.label,
              visible: false,
              presetCopy: filter.presetCopy,
            });
          });
        });
      } else {
        this.auth.setFilter(defaultFilter).subscribe((result) => {
          Object.assign(this.filterPresets[0].presetCopy, defaultFilter);
          Object.assign(this.presetCustomDefault, defaultFilter);
          this.idCustomDefault = result.id;
          this.nameCustomDefault = filter.label;
          this.presetsSelectionList.deselectAll();
          this.presetsSelectionList.options.first._setSelected(true);
          this.handlePresetSelection({
            value: filter.value,
            label: filter.label,
            visible: false,
            presetCopy: filter.presetCopy,
          });
        });
      }
    }
  }

  /**
   * set chosen preset as a default filter
   */
  resetDefaultPreset() {
    if (window.confirm('Reset default filter?')) {
      if (this.idCustomDefault !== undefined) {
        this.auth.delFilter(this.idCustomDefault).subscribe();
        Object.assign(this.filterPresets[0].presetCopy, this.presetDefault);
        Object.assign(this.presetCustomDefault, this.presetDefault);
        this.idCustomDefault = undefined;
        this.nameCustomDefault = undefined;
        this.presetsSelectionList.deselectAll();
        this.presetsSelectionList.options.first._setSelected(true);
        this.handlePresetSelection({
          value: 'Default',
          label: 'Default',
          visible: false,
          presetCopy: this.presetDefault,
        });
      }
    }
  }

  /**
   * make filter-actions visible on mouseenter
   */
  onMouseEnter(preset) {
    preset.visible = true;
  }

  /**
   * make filter-actions not visible on mouseleave
   */
  onMouseLeave(preset) {
    preset.visible = false;
  }

  /**
   * redirect the preset filter
   */
  viewPreset() {
    // this.router.navigate([], {
    //   queryParams: {
    //     time: preset.time,
    //     filter: preset.filter,
    //     lang: preset.lang,
    //     feed: preset.feed,
    //     event_ids: preset.event_ids,
    //     categories: preset.categories,
    //     continents: preset.continents,
    //     countries: preset.countries,
    //     ranks: preset.ranks,
    //     newsday: null
    //   }, queryParamsHandling: 'merge'
    // });
  }

  /**
   * open help component
   */
  openHelp() {
    this.dialog.open(HelpComponent, {
      data: { component: 'filter' },
    });
  }
}
