/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { YukkApi } from '../../../service/yukkapi.service';
import { StockChart } from 'angular-highcharts';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ConfigService } from 'src/app/service/config.service';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-trend-indicator',
  templateUrl: './trend-indicator.component.html',
  styleUrls: ['./trend-indicator.component.scss'],
})
export class TrendIndicatorComponent {
  datamain;
  controlSignals: UntypedFormControl;
  mapindex: any;
  options: object;
  options2: object;
  loading: boolean;
  // date1: any;
  // date2: any;
  // chartbot: any;
  chartnew: any;
  stockChart: StockChart;
  stockChartRef: any;
  params: any;
  arraytime = [
    { name: 'Signals 2-weekly update', value: 14 },
    { name: 'Warning daily update', value: 32 },
  ];
  mytime = 14;
  arraytype = [
    { name: 'Low Risk', value: 'conservative' },
    { name: 'Balanced', value: 'dynamic' },
    { name: 'Optimised Risk & Return', value: 'progressive' },
    { name: 'Binary', value: 'passive' },
  ];
  strategy: string;
  ELEMENT_DATA = [
    { name: 'YTD', weight: 2.22, symbol: -1.57 },
    { name: '1Y', weight: 3.45, symbol: 2.45 },
    { name: '3Y', weight: 14.8, symbol: 10.9 },
    { name: '5Y', weight: 45.6, symbol: 35.8 },
    { name: '10Y', weight: 236.8, symbol: 141.3 },
    { name: 'Sharpe', weight: 0.61, symbol: 0.26 },
    { name: 'p.a. %', weight: 9.78, symbol: 5.31 },
    { name: 'MaxSS', weight: -24.8, symbol: -58.69 },
    { name: 'vola %', weight: 15.1, symbol: 19.12 },
  ];
  displayedColumns: string[] = ['name', 'weight', 'symbol'];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  // @ViewChild(MatSort) sort: MatSort
  setup: any;
  charth: number;
  chartdist: number;
  ioption: any;
  alert: boolean;
  error: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private yukkApi: YukkApi,
    public routing: RoutingService,
    public auth: AuthService,
    public snackBar: MatSnackBar,
    public config: ConfigService,
  ) {
    this.setup = this.config.appConfig.setup;

    this.route.queryParams.subscribe((params) => {
      this.params = params;
      if (params.id) {
        this.controlSignals = new UntypedFormControl(params.id.split());
        this.initChart();
      } else {
        this.controlSignals = new UntypedFormControl('early_warning');
        this.router.navigate([], {
          queryParams: {
            id: 'early_warning',
          },
          queryParamsHandling: 'merge',
        });
      }
      if (params.theme && params.theme === 'dark') {
        this.ioption = {
          color: '255, 255, 255',
          color1: '0, 0, 0',
        };
      } else if (params.theme && params.theme === 'light') {
        this.ioption = {
          color: '0, 0, 0',
          color1: '255, 255, 255',
        };
      }
    });

    this.ioption = {
      color: '255, 255, 255',
      color1: '0, 0, 0',
    };
    if (this.routing.theme === 'light') {
      this.ioption = {
        color: '0, 0, 0',
        color1: '255, 255, 255',
      };
    }
  }

  initChart() {
    this.loading = true;
    this.error = false;
    this.datamain = undefined;
    this.yukkApi.getSignalsList().subscribe(
      (result) => {
        this.mapindex = result.entities.filter((signal) => {
          return signal.alpha_id !== 'early_warning_fx_gbp_eur';
        });
        const entity = this.mapindex.filter(
          (e) => e.alpha_id === this.params.id,
        )[0];

        this.yukkApi.getSignalData(entity.alpha_id).subscribe(
          (result2) => {
            const sentiment_active_data = result2.overlay;

            // sentiment_active_data.invested_since = sentiment_active_data.invested_since * 1000;
            // sentiment_active_data.investment_ratio = sentiment_active_data.investment_ratio.map(item => [item[0] * 1000, item[1]]);
            // sentiment_active_data.performance = sentiment_active_data.performance.map(item => [item[0] * 1000, item[1]]);
            // sentiment_active_data.price = sentiment_active_data.price.map(item => [item[0] * 1000, item[1]]);

            // this.date1 = sentiment_active_data.investment_ratio[0][0];
            // this.date2 = sentiment_active_data.investment_ratio[sentiment_active_data.investment_ratio.length - 1][0];
            const sentipercent = this.formatPercent(
              this.calcPerformancePercent(
                sentiment_active_data.performance[0][1],
                sentiment_active_data.performance[
                  sentiment_active_data.performance.length - 1
                ][1],
              ),
            );
            const indexpercent = this.formatPercent(
              this.calcPerformancePercent(
                sentiment_active_data.price[0][1],
                sentiment_active_data.price[
                  sentiment_active_data.price.length - 1
                ][1],
              ),
            );

            if (this.params.id === 'early_warning') {
              const current_investment =
                sentiment_active_data.investment_ratio[
                  sentiment_active_data.investment_ratio.length - 1
                ][1] * 100;
              this.datamain = {
                sentito: entity.name,
                sentipercent: sentipercent,
                indexpercent: indexpercent,
                current_investment: Math.round(current_investment * 100) / 100,
                label: ['InvestLab', 'Index', 'Investment'],
              };
            } else {
              this.datamain = {
                sentito: entity.name,
                sentipercent: sentipercent,
                indexpercent: indexpercent,
                label: ['InvestLab', 'Index', 'Signal', 'Confidence'],
                current_signal: sentiment_active_data.current_signal,
                current_confidence: sentiment_active_data.current_confidence,
              };
            }

            let start: boolean;
            const zones = [];

            if (this.params.id === 'early_warning') {
              sentiment_active_data.investment_ratio.forEach(
                function (data1, key) {
                  if (data1[1] < 1 && !start) {
                    start = true;
                    zones.push({
                      value: sentiment_active_data.performance[key][0],
                    });
                  } else if (
                    (start && data1[1] >= 1) ||
                    (key ===
                      sentiment_active_data.investment_ratio.length - 1 &&
                      data1[1] < 1)
                  ) {
                    zones.push({
                      value: sentiment_active_data.performance[key][0],
                      fillColor: '#db5925',
                    });
                    start = false;
                  }
                },
              );
            } else {
              sentiment_active_data.signal.forEach(function (data1, key) {
                if (data1[1] < 1 && !start) {
                  start = true;
                  zones.push({
                    value: sentiment_active_data.performance[key][0],
                  });
                } else if (
                  (start && data1[1] >= 1) ||
                  (key === sentiment_active_data.signal.length - 1 &&
                    data1[1] < 1)
                ) {
                  zones.push({
                    value: sentiment_active_data.performance[key][0],
                    fillColor: '#db5925',
                  });
                  start = false;
                }
              });
            }

            const unitgroup = [
              ['week', [1]],
              ['month', [1, 2, 3, 4, 6]],
            ];
            // this.chartbot = sentiment_active_data.investment_ratio;

            let chartSeries = [];
            let chartYaxis = [];

            if (this.params.id === 'early_warning') {
              chartYaxis = [
                {
                  height: '70%',
                  offset: 0,
                  labels: {
                    formatter: function () {
                      return (this.value > 0 ? ' + ' : '') + this.value + '%';
                    },
                    x: 5,
                    align: 'left',
                    style: {
                      color: 'rgba(' + this.ioption.color + ', 0.4)',
                    },
                  },
                  gridLineColor: 'rgba(' + this.ioption.color + ', 0.2)',
                },
                {
                  top: '70%',
                  height: '30%',
                  offset: 0,
                  labels: {
                    x: 5,
                    align: 'left',
                    style: {
                      color: 'rgba(' + this.ioption.color + ', 0.4)',
                    },
                  },
                  title: '',
                  gridLineColor: 'transparent',
                },
              ];
              chartSeries = [
                {
                  type: 'area',
                  compare: 'percent',
                  name: this.datamain.label[0],
                  data: sentiment_active_data.performance,
                  dataGrouping: {
                    units: unitgroup,
                    // enabled: false
                  },
                  color: 'rgba(133, 188, 22, 0.5)',
                  fillColor: 'rgba(37, 177, 219, 0.1)',
                  tooltip: {
                    valueDecimals: 2,
                  },
                  yAxis: 0,
                  zoneAxis: 'x',
                  zones: zones,
                  lineWidth: 2,
                },
                {
                  type: 'area',
                  compare: 'percent',
                  name: this.datamain.label[1],
                  data: sentiment_active_data.price,
                  dataGrouping: {
                    units: unitgroup,
                    // enabled: false
                  },
                  // color: 'rgba(255, 255, 255, 0.1)',
                  // fillColor: 'rgba(255, 255, 255, 0.05)',
                  color: 'rgba(180, 180, 180, 0.5)',
                  fillColor: 'rgba(180, 180, 180, 0.2)',
                  tooltip: {
                    valueDecimals: 2,
                  },
                  yAxis: 0,
                  lineWidth: 1,
                },
                {
                  type: 'area',
                  className: 'chart111',
                  name: this.datamain.label[2],
                  data:
                    this.params.id === 'early_warning'
                      ? sentiment_active_data.investment_ratio
                      : sentiment_active_data.signal,
                  dataGrouping: {
                    units: unitgroup,
                    // enabled: false
                  },
                  yAxis: 1,
                  lineWidth: 1,
                  color: '#338d93',
                  fillColor: 'rgba(48, 180, 187, 0.2)',
                  tooltip: { valueDecimals: 2 },
                },
              ];
            } else {
              chartYaxis = [
                {
                  height: '60%',
                  offset: 0,
                  labels: {
                    formatter: function () {
                      return (this.value > 0 ? ' + ' : '') + this.value + '%';
                    },
                    x: 5,
                    align: 'left',
                    style: {
                      color: 'rgba(' + this.ioption.color + ', 0.4)',
                    },
                  },
                  gridLineColor: 'rgba(' + this.ioption.color + ', 0.2)',
                },
                {
                  top: '60%',
                  height: '20%',
                  offset: 0,
                  labels: {
                    x: 5,
                    align: 'left',
                    style: {
                      color: 'rgba(' + this.ioption.color + ', 0.4)',
                    },
                  },
                  title: '',
                  gridLineColor: 'transparent',
                },
                {
                  top: '80%',
                  height: '20%',
                  offset: 0,
                  labels: {
                    x: 5,
                    align: 'left',
                    style: {
                      color: 'rgba(' + this.ioption.color + ', 0.4)',
                    },
                  },
                  title: '',
                  gridLineColor: 'transparent',
                },
              ];
              chartSeries = [
                {
                  type: 'area',
                  compare: 'percent',
                  name: this.datamain.label[0],
                  data: sentiment_active_data.performance,
                  dataGrouping: {
                    units: unitgroup,
                    // enabled: false
                  },
                  color: 'rgba(133, 188, 22, 0.5)',
                  fillColor: 'rgba(37, 177, 219, 0.1)',
                  tooltip: {
                    valueDecimals: 2,
                  },
                  yAxis: 0,
                  zoneAxis: 'x',
                  zones: zones,
                  lineWidth: 2,
                },
                {
                  type: 'area',
                  compare: 'percent',
                  name: this.datamain.label[1],
                  data: sentiment_active_data.price,
                  dataGrouping: {
                    units: unitgroup,
                    // enabled: false
                  },
                  // color: 'rgba(255, 255, 255, 0.1)',
                  // fillColor: 'rgba(255, 255, 255, 0.05)',
                  color: 'rgba(180, 180, 180, 0.5)',
                  fillColor: 'rgba(180, 180, 180, 0.2)',
                  tooltip: {
                    valueDecimals: 2,
                  },
                  yAxis: 0,
                  lineWidth: 1,
                },
                {
                  type: 'area',
                  className: 'chart111',
                  name: this.datamain.label[2],
                  data:
                    this.params.id === 'early_warning'
                      ? sentiment_active_data.investment_ratio
                      : sentiment_active_data.signal,
                  dataGrouping: {
                    units: unitgroup,
                    // enabled: false
                  },
                  yAxis: 1,
                  lineWidth: 1,
                  color: '#338d93',
                  fillColor: 'rgba(48, 180, 187, 0.2)',
                  tooltip: { valueDecimals: 2 },
                },
                {
                  type: 'area',
                  className: 'chart112',
                  name: this.datamain.label[3],
                  data:
                    this.params.id === 'early_warning'
                      ? sentiment_active_data.investment_ratio
                      : sentiment_active_data.confidence,
                  dataGrouping: {
                    units: unitgroup,
                    // enabled: false
                  },
                  yAxis: 2,
                  lineWidth: 1,
                  color: '#338d93',
                  fillColor: 'rgba(48, 180, 187, 0.2)',
                  tooltip: { valueDecimals: 2 },
                },
              ];
            }

            this.options = {
              chart: {
                backgroundColor: 'transparent',
                zoomType: 'x',
                alignTicks: true,
                marginLeft: 0,
                events: {
                  load: function () {
                    document.getElementsByClassName(
                      'highcharts-range-selector-buttons',
                    )[0].firstChild.textContent = '';
                  },
                },
              },
              title: {
                text: result2.entity.name,
                floating: true,
                y: 15,
                style: {
                  color: 'rgba(' + this.ioption.color + ', 1)',
                },
              },
              credits: { enabled: false },
              exporting: {
                enabled: true,
                menuClassName: 'highcharts-contextmenu',
                buttons: {
                  contextButton: {
                    symbol: 'menuball',
                    width: 18,
                    height: 18,
                    symbolX: 9,
                    symbolY: 9,
                    symbolSize: 10,
                    symbolStrokeWidth: 2,
                    menuItems: [
                      'printChart',
                      'separator',
                      'downloadPNG',
                      'downloadJPEG',
                      'downloadPDF',
                      'separator',
                      'downloadSVG',
                      'downloadXLS',
                      'separator',
                      'downloadCSV',
                    ],
                    theme: {
                      fill: 'none',
                      stroke: 'none',
                    },
                  },
                },
              },
              legend: {
                enabled: false,
                verticalAlign: 'top',
                itemStyle: {
                  color: '#E0E0E3',
                },
                itemHoverStyle: {
                  color: '#FFF',
                },
                itemHiddenStyle: {
                  color: '#606063',
                },
              },
              tooltip: {
                split: false,
                followPointer: true,
                followTouchMove: true,
                backgroundColor: 'rgba(0,0,0, 0.1)',
                borderColor: 'transparent',
                shadow: false,
                style: {
                  color: 'white',
                  fontSize: 14,
                  fontWeight: 'bold',
                },
                shared: true,
                useHTML: true,
                headerFormat: '<small>{point.key}</small><table>',
                pointFormat:
                  '<tr><td style="color: {series.color}">{series.name}: </td>' +
                  '<td style="text-align: right"><b>{point.y:.2f}</b></td></tr>',
                footerFormat: '</table>',
              },
              rangeSelector: {
                buttonTheme: {
                  fill: 'rgba(' + this.ioption.color1 + ', 0.2)',
                  style: {
                    color: 'rgba(' + this.ioption.color + ', 1)',
                  },
                  states: {
                    hover: {
                      fill: '#000003',
                      stroke: '#000000',
                      style: {
                        color: 'white',
                      },
                    },
                    select: {
                      fill: '#000003',
                      stroke: '#000000',
                      style: {
                        color: 'white',
                      },
                    },
                  },
                },
                buttons: [
                  {
                    type: 'ytd',
                    text: 'YTD',
                  },
                  {
                    type: 'year',
                    count: 1,
                    text: '1Y',
                  },
                  {
                    type: 'year',
                    count: 3,
                    text: '3Y',
                  },
                  {
                    type: 'year',
                    count: 5,
                    text: '5Y',
                  },
                  {
                    type: 'all',
                    text: 'All',
                  },
                ],
                selected: 4,

                inputBoxBorderColor: '#505053',
                inputStyle: {
                  backgroundColor: '#333',
                  color: 'silver',
                },
                labelStyle: {
                  color: 'silver',
                },
              },
              navigator: {
                enabled: true,
                handles: {
                  backgroundColor: '#212121',
                  borderColor: '#AAA',
                },
                outlineColor: 'transparent',
                outlineWidth: 0,
                maskFill: 'rgba(0,0,0,0.1)',
                series: { color: '#525252', lineColor: '#727272' },
                xAxis: { gridLineColor: '#525252' },
              },
              scrollbar: {
                enabled: false,
                barBackgroundColor: 'transparent',
                barBorderColor: '#808083',
                buttonArrowColor: '#CCC',
                buttonBackgroundColor: '#606063',
                buttonBorderColor: '#606063',
                rifleColor: 'transparent',
                trackBackgroundColor: 'transparent',
                trackBorderColor: '#404043',
              },
              xAxis: {
                type: 'datetime',
                // tickInterval: 1,
                breaks: [
                  {
                    from: 1537567200000,
                    to: 1537740000000,
                    breakSize: 1,
                  },
                ],
                ordinal: false,
                events: {
                  afterSetExtremes: (event) => this.onAfterSetExtremes(event),
                },
                labels: {
                  style: {
                    color: 'rgba(' + this.ioption.color + ', 0.4)',
                  },
                },
                lineColor: 'rgba(' + this.ioption.color + ', 0.2)',
                tickColor: 'rgba(' + this.ioption.color + ', 0.2)',
                crosshair: {
                  color: 'rgba(' + this.ioption.color + ', 0.2)',
                },
              },
              yAxis: chartYaxis,
              series: chartSeries,
              plotOptions: {
                series: {
                  states: {
                    inactive: {
                      opacity: 1,
                    },
                  },
                  marker: {
                    enabled: false,
                  },
                },
              },
            };
            this.stockChart = new StockChart(this.options);
            this.stockChartRef = this.stockChart.ref$;
            this.stockChartRef.subscribe((res) => {
              const chart111 = document
                .getElementsByClassName('chart111')[0]
                .getElementsByClassName('highcharts-area')[0];
              this.charth = chart111.getBoundingClientRect().height - 1;
              if (this.params.id !== 'early_warning') {
                const chart112 = document
                  .getElementsByClassName('chart112')[0]
                  .getElementsByClassName('highcharts-area')[0];
                this.chartdist =
                  chart112.getBoundingClientRect().top -
                  chart111.getBoundingClientRect().bottom;
              }
              let startDate = '';
              let endDate = '';
              let highchartsTextObject;
              // const textWidth = 150;
              const calculatePosition = (
                navLeft,
                navRight,
                textSize,
                boxSize,
                boxPadding,
              ) => {
                const positionLeft = Math.round(
                  (navLeft + navRight - textSize) * 0.5,
                );
                const positionRight = Math.round(
                  (navLeft + navRight + textSize) * 0.5,
                );
                if (positionLeft < 0) {
                  return 0;
                } else if (positionRight > boxSize + boxPadding) {
                  return boxSize + boxPadding - textSize;
                } else {
                  return positionLeft;
                }
              };
              const calculateDates = (
                dateMin,
                dateMax,
                navMin,
                navMax,
                navSize,
              ) => {
                return {
                  start: Math.ceil(
                    dateMin + ((dateMax - dateMin) * navMin) / navSize,
                  ),
                  end: Math.floor(
                    dateMin + ((dateMax - dateMin) * navMax) / navSize,
                  ),
                };
              };
              const navContainer = document.getElementsByClassName(
                'highcharts-navigator',
              )[0];
              navContainer.addEventListener(
                'mousemove',
                () => {
                  if (
                    highchartsTextObject &&
                    !(Object.keys(highchartsTextObject).length === 0)
                  ) {
                    highchartsTextObject.destroy();
                  }
                  const dateRange = calculateDates(
                    res.navigator.xAxis.min,
                    res.navigator.xAxis.max,
                    res.navigator.zoomedMin,
                    res.navigator.zoomedMax,
                    res.navigator.size,
                  );
                  startDate = moment(dateRange.start).format('DD.MM.YYYY');
                  endDate = moment(dateRange.end).format('DD.MM.YYYY');
                  highchartsTextObject = res.renderer
                    .text(startDate + ' - ' + endDate, 0, 0, 'useHTML')
                    .attr({ id: 'navTooltip' })
                    .css({ color: 'transparent' })
                    .add();
                  const tooltipContainer =
                    document.getElementById('navTooltip');
                  const tooltipContainerWidth = tooltipContainer.offsetWidth;
                  highchartsTextObject.destroy();
                  const textPositionX = calculatePosition(
                    res.navigator.handles[0].translateX,
                    res.navigator.handles[1].translateX,
                    tooltipContainerWidth,
                    res.navigator.size,
                    res.navigator.left,
                  );
                  const color = 'rgba(' + this.ioption.color + ', 0.4)';
                  highchartsTextObject = res.renderer
                    .text(
                      startDate + ' - ' + endDate,
                      textPositionX,
                      res.navigator.handles[0].translateY - 15,
                    )
                    .css({ color: color, fontSize: 12 })
                    .add();
                },
                false,
              );
              navContainer.addEventListener(
                'mouseleave',
                () => {
                  if (
                    highchartsTextObject &&
                    !(Object.keys(highchartsTextObject).length === 0)
                  ) {
                    highchartsTextObject.destroy();
                  }
                },
                false,
              );
            });
            this.loading = false;
          },
          () => {
            this.error = true;
          },
        );
      },
      () => {
        this.error = true;
      },
    );
  }

  formatPercent(val) {
    return Math.round(val * 100) / 100;
  }

  calcPerformancePercent(firstVal, lastVal) {
    return (lastVal / firstVal - 1) * 100;
  }

  saveInstance(chartInstance) {
    this.chartnew = chartInstance;
  }

  onAfterSetExtremes(event) {
    const sentiSeries = event.target.series[0].points;
    const indexSeries = event.target.series[1].points;
    this.datamain.sentipercent = this.formatPercent(
      this.calcPerformancePercent(
        sentiSeries[0].y,
        sentiSeries[sentiSeries.length - 1].y,
      ),
    );
    this.datamain.indexpercent = this.formatPercent(
      this.calcPerformancePercent(
        indexSeries[0].y,
        indexSeries[indexSeries.length - 1].y,
      ),
    );
    // this.updateVolumechart();
  }

  updateVolumechart() {
    // const newchart: any = [];
    // const date1 = this.date1;
    // const date2 = this.date2;
    // this.chartbot.forEach(function (data, key) {
    //   if (date1 <= data[0] && date2 >= data[0]) {
    //     newchart.push(data);
    //   }
    // });
    // this.chartnew.series[0].setData(newchart)
  }

  goUrl(event) {
    if (event.options[0].selected) {
      event.source.deselectAll();
      event.options[0]._setSelected(true);
      this.controlSignals = new UntypedFormControl(
        event.options[0].value.split(),
      );
      this.router.navigate([], {
        queryParams: {
          id: event.options[0].value,
        },
        queryParamsHandling: 'merge',
      });
    } else {
      event.source.deselectAll();
      event.options[0]._setSelected(true);
    }
  }

  inType(value) {
    this.router.navigate([], {
      queryParams: {
        overlay: value,
      },
      queryParamsHandling: 'merge',
    });
  }

  setIndicator() {
    this.auth.setIndicator(this.alert, this.params).subscribe(() => {
      if (this.alert) {
        this.snackBar.open(
          'You have subscribed to ' +
            this.capitalizeFirstLetter(this.params.overlay) +
            ' Alerts for ' +
            this.capitalizeFirstLetter(this.params.id) +
            ', if there is a change in the recommended Investment Ratio, you will be notified by email before market opening.',
          '',
          { duration: 10000 },
        );
      } else {
        this.snackBar.open(
          'You have unsubscribed from ' +
            this.capitalizeFirstLetter(this.params.overlay) +
            ' Alerts for ' +
            this.capitalizeFirstLetter(this.params.id),
          '',
          { duration: 6000 },
        );
      }
    });
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
