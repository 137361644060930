import { Component, Input, Output, EventEmitter, } from '@angular/core';

@Component({
  selector: 'app-related-questions',
  templateUrl: './chat-related-questos.component.html',
  styleUrls: ['./chat-related-questos.component.scss']
})
export class RelatedQuestionsComponent {
  @Input() relatedQuestions: string[] = [];
  @Output() submitRelatedQuestion = new EventEmitter<string>();

  constructor() { }


  onClickQuestionBox(question: string) {
    this.submitRelatedQuestion.emit(question);
  }
}
