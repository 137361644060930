import {
  Component,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { AuthService } from 'src/app/service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';



@Component({
  selector: "app-event-sorting-toggle",
  templateUrl: "./event-sorting-toggle.component.html",
})
export class EventSortingToggleComponent {
  @Input() sortByOptions: {
    value: string;
    icon: string;
    label?: string;
    tooltip?: string;
    class?: string;
  }[] = [];
  @Input() sortBy: string;
  @Input() hideTooltip: boolean = true; // Default value
  @Output() sortByChange = new EventEmitter<string>();

  //additional param for llm chat charts widgets path: ../../../project/llm/chat-charts/chat-charts.component.ts
  customScore: string;

  qparam: any;

  constructor(public auth: AuthService,     private route: ActivatedRoute,
  ){
    this.customScore = this.auth.scorelabSettings.defaultScore; 

    this.route.queryParams.subscribe(qparam => {

      this.qparam = JSON.parse(JSON.stringify(qparam));

      
      if(this.qparam.custom_score){
        this.customScore = this.qparam.custom_score;
      }
  })
}

  onSortByChange(event: any) {
    this.sortByChange.emit(event.value);
  }

}
