<div class="tooltip__container">
  <app-article-card
    class="article__tooltip"
    [title]="sourceDoc.title"
    [publishTime]="sourceDoc.publish_time"
    [provider]="sourceDoc.provider"
    [eventNames]="sourceDoc?.eventNames"
    [entityNames]="sourceDoc?.entityNames"
    [citation]="sourceDoc?.citation"
    [isTooltip]="true"
  >
  </app-article-card>
</div>
