import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TooltipPositionService {
  private readonly TOOLTIP_HEIGHT = 192;
  private readonly TOOLTIP_WIDTH = 256;
  private readonly OFFSET = 10;
  private readonly INPUT_HEIGHT = 60;
  private readonly EXTRA_OFFSET = 20;

  calculateTooltipPosition(
    event: MouseEvent,
    chatWrapperRect: DOMRect,
  ): { left: number; top: number } {
    const citationRect = (event.target as HTMLElement).getBoundingClientRect();

    const topPosition = this.calculateTopPosition(citationRect);
    const leftPosition = this.calculateLeftPosition(
      citationRect,
      chatWrapperRect,
    );

    return { left: leftPosition, top: topPosition };
  }

  private calculateTopPosition(citationRect: DOMRect): number {
    const spaceBelow =
      window.innerHeight - citationRect.bottom - this.INPUT_HEIGHT;
    return spaceBelow < this.TOOLTIP_HEIGHT
      ? citationRect.top - this.TOOLTIP_HEIGHT - this.OFFSET - this.EXTRA_OFFSET
      : citationRect.bottom + this.OFFSET;
  }

  private calculateLeftPosition(
    citationRect: DOMRect,
    chatWrapperRect: DOMRect,
  ): number {
    const leftPosition =
      citationRect.left + citationRect.width / 2 - this.TOOLTIP_WIDTH / 2;

    if (leftPosition < chatWrapperRect.left + this.OFFSET) {
      return chatWrapperRect.left + this.OFFSET;
    } else if (
      leftPosition + this.TOOLTIP_WIDTH >
      chatWrapperRect.right - this.OFFSET
    ) {
      return (
        chatWrapperRect.right -
        this.TOOLTIP_WIDTH -
        this.OFFSET -
        citationRect.width
      );
    }
    return leftPosition;
  }
}
