interface WelcomeQuestionItem {
  header: string;
  icon: string;
  question: string;
}

export interface WelcomeQuestion {
  title: string;
  questions: WelcomeQuestionItem[];
}

export const welcomeQuestions: WelcomeQuestion[] = [
  {
    title: 'News',
    questions: [
      {
        header: 'Top news of the day',
        icon: 'trending_up',
        question: 'Give me a summary of the top news and events of the day',
      },
      {
        header: 'Major news & events in the industry',
        icon: 'business',
        question: 'What are major news and events in my industry?',
      },
    ],
  },
  {
    title: 'Portfolio',
    questions: [
      {
        header: 'News summary about my portfolio',
        icon: 'list',
        question: 'Give me a summary of the latest news about my portfolio',
      },
      {
        header: 'Top movers in my portfolio',
        icon: 'show_chart',
        question: 'Who are the top movers in my portfolio?',
      },
    ],
  },
];
