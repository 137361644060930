<div *ngIf="auth.featureFlags.showMenuRHS" class="menu-bside">

  <div class="cont" *ngIf="routing.isNewsletter()">
    <div class="cont" *ngIf="auth.featureFlags.showViewOptions">
      <!--<div class="label" *ngIf="auth.featureFlags.showNewsletterPreview">View</div>
      <button mat-icon-button (click)="iPreview()" [class.active]="routing.isPreview()" matTooltip="Newsletter Preview" matTooltipPosition="left" *ngIf="auth.featureFlags.showNewsletterPreview">
        <mat-icon svgIcon="email-newsletter"></mat-icon>
      </button>
      <div class="space" *ngIf="auth.featureFlags.showNewsletterPreview"></div>
      <div class="label" *ngIf="auth.featureFlags.showNewsletterArticles">News</div>
      <button mat-icon-button (click)="iArticles()" [class.active]="routing.isArticles()" matTooltip="Edit / View Articles" matTooltipPosition="left" *ngIf="auth.featureFlags.showNewsletterArticles">
        <mat-icon>assignment</mat-icon>
      </button>
      <div class="space" *ngIf="auth.featureFlags.showNewsletterArticles"></div>-->
      <div class="label" *ngIf="auth.featureFlags.showNewsletterLivePreview">View</div>
      <!--<div class="info-label" *ngIf="auth.featureFlags.showNewsletterLivePreview">BETA</div>-->
      <button mat-icon-button (click)="iLivePreview()" [class.active]="routing.isPreview()" matTooltip="Newsletter Live Preview" matTooltipPosition="left" *ngIf="auth.featureFlags.showNewsletterLivePreview">
        <mat-icon>play_circle_outline</mat-icon>
      </button>
      <div class="space" *ngIf="auth.featureFlags.showNewsletterArticles"></div>
    </div>
  </div>

  <div class="cont" *ngIf="!routing.isHot() && !routing.isNewsletter()">
    <div *ngIf="auth.featureFlags.showViewOptions && !(params.type==='event' && params.id==='super_event')" class="cont">
      <div class="label" *ngIf="auth.featureFlags.showScannerView && routing.isFolio()">Scan</div>
      <button mat-icon-button (click)="iScan()" [class.active]="routing.isScanner()" matTooltip="Scanner" matTooltipPosition="left" *ngIf="auth.featureFlags.showScannerView && routing.isFolio()">
        <mat-icon>compass_calibration</mat-icon>
      </button>
      <div class="space" *ngIf="auth.featureFlags.showScannerView && routing.isFolio()"></div>
      <div class="label" *ngIf="auth.featureFlags.showMainView && (routing.isFolio() || routing.isQuery())">Edit</div>
      <button mat-icon-button (click)="iMain()" [class.active]="routing.isMain()" matTooltip="Main" matTooltipPosition="left" *ngIf="auth.featureFlags.showMainView && (routing.isFolio() || routing.isQuery())">
        <mat-icon svgIcon="table-edit"></mat-icon>
      </button>
      <div class="space" *ngIf="auth.featureFlags.showMainView && (routing.isFolio() || routing.isQuery())"></div>
      <div class="label" *ngIf="auth.featureFlags.showNewsView && (params.id !== 'default') && (params.type !== 'hierarchy')">News</div>
      <button mat-icon-button [class.active]="routing.isNewsaudit()" (click)="iNewsaudit()" matTooltip="News View" matTooltipPosition="left" *ngIf="auth.featureFlags.showNewsView && (params.id !== 'default') && (params.type !== 'hierarchy')">
        <mat-icon>assignment</mat-icon>
      </button>
      <div class="space" *ngIf="auth.featureFlags.showNewsView && (params.id !== 'default') && (params.type !== 'hierarchy')"></div>
      <div class="label" *ngIf="(auth.featureFlags.showChart || auth.featureFlags.showBenchmark || auth.featureFlags.showRadar || auth.featureFlags.showNetwork) && (params.id !== 'default') && (params.type !== 'hierarchy')">Charts</div>
      <button mat-icon-button (click)="iChart()" [class.active]="routing.isChart()" matTooltip="Chart" matTooltipPosition="left" *ngIf="auth.featureFlags.showChart && (params.id !== 'default') && (params.type !== 'hierarchy')">
        <mat-icon>insert_chart</mat-icon>
      </button>
      <button mat-icon-button (click)="iChart2()" [class.active]="routing.isChart2()" matTooltip="Benchmark" matTooltipPosition="left" *ngIf="auth.featureFlags.showBenchmark && (!routing.isMarket() || params.tutorial=='dataoptionhome') && (params.id !== 'default') && !params.portfolioId">
        <mat-icon>multiline_chart</mat-icon>
      </button>
      <!--<button mat-icon-button [class.active]="routing.isRadar()" (click)="iRadar()" matTooltip="Radar" matTooltipPosition="left" *ngIf="auth.featureFlags.showRadar && (this.auth.scorelabSettings.defaultScore === 'sentiment') && (params.id !== 'default')">
        <mat-icon class="flip">my_location</mat-icon>
      </button>-->
      <!--<button mat-icon-button [class.active]="routing.isNetwork()" (click)="iNetwork()" matTooltip="Network" matTooltipPosition="left" *ngIf="auth.featureFlags.showNetwork && (this.auth.scorelabSettings.defaultScore === 'sentiment') && (params.id !== 'default')">
        <mat-icon>bubble_chart</mat-icon>
      </button>-->
      <button mat-icon-button [class.active]="routing.isParticipants()" (click)="iParticipants()" matTooltip="Outlier Detection" matTooltipPosition="left" *ngIf="auth.featureFlags.showParticipants && (this.customScore === 'sentiment') && (routing.isFolio() || routing.isQuery() || routing.isSearch()) && (params.id !== 'default') && !params.portfolioId">
        <mat-icon svgIcon="chart-scatter-plot"></mat-icon>
      </button>
      <div class="space" *ngIf="(auth.featureFlags.showChart || auth.featureFlags.showBenchmark || auth.featureFlags.showRadar || auth.featureFlags.showNetwork) && (params.id !== 'default')"></div>
    </div>
  </div>
  <!--<div class="cont" *ngIf="routing.isHot()">
    <div class="label">Filter</div>
    <button mat-icon-button (click)="menufilter.open()">
      <mat-icon>filter_list</mat-icon>
    </button>
    <mat-form-field class="invisible">
      <mat-select class="select-bside-filter" [formControl]="menuFilter" multiple (openedChange)="goFilter($event)" #menufilter>
        <mat-option *ngFor="let item of entities" [value]="item">{{item|nicetxt}}</mat-option>
      </mat-select>
    </mat-form-field>
    &lt;!&ndash; <button mat-icon-button (click)="menufilter2.open()" *ngIf="routing.isDev()">
      <mat-icon>wifi_tethering</mat-icon>
    </button>
    <mat-form-field class="">
      <mat-select [(ngModel)]="menuFilter2" #menufilter2 (selectionChange)="goFilter2($event)">
        <mat-option value="volume">volume</mat-option>
        <mat-option value="sentiment">sentiment</mat-option>
        <mat-option value="sentiment_delta">trending</mat-option>
      </mat-select>
    </mat-form-field> &ndash;&gt;
    <div class="space"></div>
  </div>-->
  <!--<div class="cont" *ngIf="auth.featureFlags.showSortOptions && routing.isMarket() && routing.isChart()" [class.inblur]="params.tutorial=='dataoptionhome'">
    <div class="space"></div>
    <div class="label">Sort</div>
     <button mat-icon-button (click)="goSort('ratio')" matTooltip="Volume" matTooltipPosition="left"
      [class.active]="sort=='ratio'||sort=='-ratio'" *ngIf="routing.isTrending()">
      <mat-icon>layers</mat-icon>
    </button>
    <button *ngIf="!(this.auth.scorelabSettings.defaultScore === 'sentiment') && routing.isFolio() && routing.isMain()" mat-icon-button (click)="goSort('score')" [matTooltip]="scoreTooltip()" matTooltipPosition="left"
            [class.active]="sort=='score'||sort=='-score'">
      <mat-icon *ngIf="sort!='-score'" svgIcon="alert-plus-outline"></mat-icon>
      <mat-icon *ngIf="sort=='-score'" svgIcon="alert-minus-outline"></mat-icon>
    </button>
    <button *ngIf="auth.featureFlags.showSortSentiment && (params.id !== 'default')" mat-icon-button (click)="goSort('senti')" matTooltip="Sentiment Rating where 0% is very negative and 100% is very positive" matTooltipPosition="left"
      [class.active]="sort=='senti'||sort=='-senti'">
      <mat-icon *ngIf="sort!='-senti'" svgIcon="emoticon-happy-outline"></mat-icon>
      <mat-icon *ngIf="sort=='-senti'" svgIcon="emoticon-sad-outline"></mat-icon>
    </button>
    <button *ngIf="auth.featureFlags.showSortTrending && routing.isMain() && (params.id !== 'default')" mat-icon-button (click)="goSort('trend')" matTooltip="Change in the sentiment for the period" matTooltipPosition="left"
      [class.active]="sort=='trend'||sort=='-trend'">
      <mat-icon *ngIf="sort!='-trend'" svgIcon="trending-up"></mat-icon>
      <mat-icon *ngIf="sort=='-trend'" svgIcon="trending-down"></mat-icon>
    </button>
    <button *ngIf="auth.featureFlags.showSortVolumeCount && !routing.isMarket() && (params.id !== 'default')" mat-icon-button (click)="goSort('count')" matTooltip="Total number of news articles" matTooltipPosition="left"
            [class.active]="sort=='count'||sort=='-count'">
      <mat-icon *ngIf="sort!='-count'" svgIcon="sort-variant" style="transform: scaleY(1.3)"></mat-icon>
      <mat-icon *ngIf="sort=='-count'" svgIcon="sort-reverse-variant" style="transform: scaleY(1.3)"></mat-icon>
    </button>
    <button *ngIf="auth.featureFlags.showSortVolumeRatio && !routing.isMarket() && routing.isMain() && (params.id !== 'default')" mat-icon-button (click)="goSort('ratio')" matTooltip="The relative change in the total number of news articles from the previous period" matTooltipPosition="left"
            [class.active]="sort=='ratio'||sort=='-ratio'">
      <mat-icon *ngIf="sort!='-ratio'" svgIcon="finance" style="transform: scaleY(-1) rotate(90deg)"></mat-icon>
      <mat-icon *ngIf="sort=='-ratio'" svgIcon="finance" style="transform: rotate(90deg);"></mat-icon>
    </button>
    <button *ngIf="auth.featureFlags.showSortAlphabetic" mat-icon-button (click)="goSort('abc')" matTooltip="Alphabetic Order" matTooltipPosition="left" [class.active]="sort=='abc'||sort=='-abc'">
      <mat-icon *ngIf="sort!='-abc'" svgIcon="sort-alphabetical-descending-variant"></mat-icon>
      <mat-icon *ngIf="sort=='-abc'" svgIcon="sort-alphabetical-ascending-variant"></mat-icon>
    </button>
    <div class="space"></div>
    <div class="label">Filter</div>
    <button mat-icon-button
      [ngClass]="{'all':!params.filter,'pos':params.filter=='pos','neu':params.filter=='neu','neg':params.filter=='neg'}"
      matTooltip="Polarity" matTooltipPosition="left" [matMenuTriggerFor]="menu">
      <mat-icon>radio_button_checked</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-icon-button class="all" [routerLink]="" [queryParams]="{tag:null,filter:null}"
        queryParamsHandling="merge" matTooltip="All" matTooltipPosition="above">
        <mat-icon>{{isFilter('all')}}</mat-icon>
      </button>
      <button mat-icon-button class="pos" [routerLink]="" [queryParams]="{tag:null,filter:'pos'}"
        queryParamsHandling="merge" matTooltip="Positive" matTooltipPosition="above">
        <mat-icon>{{isFilter('pos')}}</mat-icon>
      </button>
      <button mat-icon-button class="neu" [routerLink]="" [queryParams]="{tag:null,filter:'neu'}"
        queryParamsHandling="merge" matTooltip="Neutral" matTooltipPosition="above">
        <mat-icon>{{isFilter('neu')}}</mat-icon>
      </button>
      <button mat-icon-button class="neg" [routerLink]="" [queryParams]="{tag:null,filter:'neg'}"
        queryParamsHandling="merge" matTooltip="Negative" matTooltipPosition="above">
        <mat-icon>{{isFilter('neg')}}</mat-icon>
      </button>
    </mat-menu>
  </div>-->
</div>
