/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { from, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { QueryEditorComponent } from '../query-editor/query-editor.component';
import { QueryImportComponent } from '../query-import/query-import.component';
import { RoutingService } from 'src/app/service/routing.service';
import { ConfigService } from 'src/app/service/config.service';
import { QueryNameComponent } from '../query-name/query-name.component';
import * as FileSaver from 'file-saver';
import { ColorPipe } from 'src/app/pipe/color.pipe';
import { ColorRatioPipe } from 'src/app/pipe/colorRatio.pipe';
import { autocollection } from './preset-collection';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-query-folio',
  templateUrl: './query-folio.component.html',
  styleUrls: ['./query-folio.component.scss'],
})
export class QueryFolioComponent {
  /**
   * view chosen for the collection board (list or mosaic)
   */
  collectionView: any;

  items: any;
  itemsCountMin: any;
  itemsCountMax: any;
  itemsTrendMax: any;
  itemsTrendMin: any;
  itemsRatioMax: any;
  itemsRatioMin: any;

  itemsLoaded: any;

  selectedQueries: any;

  currentQuery: any;

  isQueryAdded: boolean;

  /**
   * collection content in the news-table's format
   */
  itemsFiltered: any;

  query: any;
  myOrder: string;
  params: any;
  previousValue: any;

  constructor(
    public auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private yukkApi: YukkApi,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public routing: RoutingService,
    public config: ConfigService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.params = params;
      if (
        this.config.appConfig.setup.useStorage &&
        localStorage.getItem('collection_view') !== null
      ) {
        this.collectionView = localStorage.getItem('collection_view');
      } else {
        this.collectionView = 'list';
      }
      this.isQueryAdded = false;
      if (routing.reFresh(params, this.previousValue, ['update'])) {
        this.isQueryAdded = true;
      }
      if (
        routing.reFresh(params, this.previousValue, [
          'id',
          'groupId',
          'update',
          'time',
          'lang',
          'feed',
          'categories',
          'continents',
          'countries',
          'factuality',
          'temporality',
          'ranks' /*, 'sort', 'defaultsort'*/,
          'panels',
        ])
      ) {
        let itemsCopy = null;
        let itemsCopyIds = [];
        if (this.items) {
          itemsCopy = JSON.parse(JSON.stringify(this.items));
          itemsCopyIds = this.items.map((item) => item.query.uid);
        }
        this.items = null;
        this.itemsLoaded = false;
        this.selectedQueries = [];
        const query = [];
        if (this.auth.iquery && this.params.id !== 'default') {
          if (!this.auth.iquery || this.auth.iquery.length === 0) {
            this.items = [];
          }
          this.items = [];
          const itemsLength = this.auth.iquery.length;
          let itemsCount = 0;
          const data = [];
          this.auth.iquery.forEach((item) => {
            const params = JSON.parse(JSON.stringify(this.params));
            params['iquery'] = Object.assign({}, item.query, { uid: item.uid });
            // const request = this.yukkApi.isentiment(params, false);
            query.push(params);
            this.items.push({
              query: item,
            });
          });
          from(query)
            .pipe(
              concatMap((req, index) => {
                if (
                  this.isQueryAdded &&
                  itemsCopy &&
                  this.auth.iquery &&
                  this.auth.iquery[index] &&
                  itemsCopyIds.includes(this.auth.iquery[index].uid) &&
                  !(
                    params.queryid &&
                    params.queryid === this.auth.iquery[index].uid
                  )
                ) {
                  return of(
                    itemsCopy.filter((item) => {
                      return item.query.uid === this.auth.iquery[index].uid;
                    })[0],
                  );
                } else {
                  return this.yukkApi.isentiment(req, false);
                }
              }),
            )
            .subscribe((queryItem2) => {
              const queryItem = JSON.parse(JSON.stringify(queryItem2));
              this.items[itemsCount].sentiment = queryItem.sentiment;
              data.push(queryItem);
              itemsCount++;
              if (itemsCount === itemsLength) {
                this.items = data.map((itom: any, index: number) => {
                  if (itom) {
                    itom['query'] = this.auth.iquery[index];
                    return itom;
                  } else {
                    return {
                      query: this.auth.iquery[index],
                    };
                  }
                });
                this.itemsFiltered = this.items.map((item) => {
                  return {
                    id: item.query ? item.query.uid : undefined,
                    type: 'query',
                    name: item.query ? item.query.name : undefined,
                    sentiment: item.sentiment
                      ? item.sentiment.sentiment
                      : undefined,
                    trend: item.sentiment
                      ? item.sentiment.sentiment_delta
                      : undefined,
                    count: item.sentiment ? item.sentiment.count : undefined,
                    ratio: item.sentiment
                      ? item.sentiment.volume_ratio
                      : undefined,
                  };
                });
                const definedItems = this.items.filter((item) => {
                  return item.sentiment;
                });
                this.itemsCountMax = Math.max(
                  ...definedItems.map((item) => {
                    return item.sentiment.count;
                  }),
                  1,
                );
                this.itemsCountMin = Math.min(
                  ...definedItems.map((item) => {
                    return item.sentiment.count;
                  }),
                  0,
                );
                this.itemsTrendMax = Math.max(
                  ...definedItems.map((item) => {
                    return item.sentiment.sentiment_delta;
                  }),
                );
                this.itemsTrendMin = Math.min(
                  ...definedItems.map((item) => {
                    return item.sentiment.sentiment_delta;
                  }),
                );
                this.itemsRatioMax = Math.max(
                  ...definedItems.map((item) => {
                    return item.sentiment.volume_ratio;
                  }),
                );
                this.itemsRatioMin = Math.min(
                  ...definedItems.map((item) => {
                    return item.sentiment.volume_ratio;
                  }),
                );
                this.itemsLoaded = true;
              }
            });
        } else if (this.auth.iquery && this.params.id === 'default') {
          this.items = this.auth.iquery.map((element) => {
            return {
              query: element,
              entity: {},
              sentiment: {},
            };
          });
        } else {
          this.items = [];
          // this.iQuery()
        }
      }

      this.previousValue = params;
      if (params.defaultsort) {
        if (params.defaultsort === 'abc') {
          this.myOrder = 'query.name';
        }
        if (params.defaultsort === '-abc') {
          this.myOrder = '-query.name';
        }
      } else if (params.sort) {
        if (params.sort === 'senti') {
          this.myOrder = '-sentiment.sentiment';
        }
        if (params.sort === '-senti') {
          this.myOrder = 'sentiment.sentiment';
        }
        if (params.sort === 'abc') {
          this.myOrder = 'query.name';
        }
        if (params.sort === '-abc') {
          this.myOrder = '-query.name';
        }
        if (params.sort === 'trend') {
          this.myOrder = '-sentiment.sentiment_delta';
        }
        if (params.sort === '-trend') {
          this.myOrder = 'sentiment.sentiment_delta';
        }
        if (params.sort === 'count') {
          this.myOrder = '-sentiment.count';
        }
        if (params.sort === '-count') {
          this.myOrder = 'sentiment.count';
        }
        if (params.sort === 'ratio') {
          this.myOrder = '-sentiment.volume_ratio';
        }
        if (params.sort === '-ratio') {
          this.myOrder = 'sentiment.volume_ratio';
        }
      } else {
        this.myOrder = '-sentiment.count';
      }
    });
  }

  listContainingCollections(item) {
    const collections = this.auth.querys
      .filter((collection) => {
        return item.query.groups.includes(collection.uid);
      })
      .map((element) => {
        return element.name;
      });
    if (collections && collections.length !== 0) {
      return collections;
    } else {
      return [];
    }
  }

  listContainingQueries(item) {
    return item.query.referenced_by?.map((e) => e.name);
  }

  selectQuery(item) {
    let check = false;
    const filteredQueries = this.selectedQueries.filter((query) => {
      if (query.uid === item.uid) {
        check = true;
      }
      return query.uid !== item.uid;
    });
    if (check) {
      this.selectedQueries = filteredQueries;
    } else {
      this.selectedQueries.push(item);
    }
  }

  getBackground(item) {
    if (this.myOrder === 'query.name' || this.myOrder === '-query.name') {
      const filterPipe = new ColorRatioPipe(this.routing);
      const value = item.sentiment ? item.sentiment.volume_ratio : undefined;
      if (!this.itemsLoaded) {
        return 'rgb(120,120,120)';
      } else {
        return filterPipe.transform(
          value,
          this.itemsRatioMin,
          this.itemsRatioMax,
        );
      }
    }
    if (
      this.myOrder === '-sentiment.count' ||
      this.myOrder === 'sentiment.count'
    ) {
      const filterPipe = new ColorRatioPipe(this.routing);
      const value = item.sentiment ? item.sentiment.volume_ratio : undefined;
      if (!this.itemsLoaded) {
        return 'rgb(120,120,120)';
      } else {
        return filterPipe.transform(
          value,
          this.itemsRatioMin,
          this.itemsRatioMax,
        );
      }
    }
    if (
      this.myOrder === '-sentiment.volume_ratio' ||
      this.myOrder === 'sentiment.volume_ratio'
    ) {
      const filterPipe = new ColorRatioPipe(this.routing);
      let value = item.sentiment
        ? item.sentiment.volume_ratio
          ? item.sentiment.volume_ratio
          : undefined
        : undefined;
      if (
        item.sentiment &&
        (item.sentiment.volume_ratio === null ||
          item.sentiment.volume_ratio === 0) &&
        item.sentiment.count &&
        item.sentiment.count > 0
      ) {
        value = this.itemsRatioMin;
      }
      if (!this.itemsLoaded) {
        return 'rgb(120,120,120)';
      } else {
        return filterPipe.transform(
          value,
          this.itemsRatioMin,
          this.itemsRatioMax,
        );
      }
    }
    if (
      this.myOrder === '-sentiment.sentiment' ||
      this.myOrder === 'sentiment.sentiment'
    ) {
      const filterPipe = new ColorPipe(this.routing);
      const value = item.sentiment ? item.sentiment.sentiment : undefined;
      if (!this.itemsLoaded) {
        return 'rgb(120,120,120)';
      } else {
        return filterPipe.transform(value);
      }
    }
    if (
      this.myOrder === '-sentiment.sentiment_delta' ||
      this.myOrder === 'sentiment.sentiment_delta'
    ) {
      const filterPipe = new ColorPipe(this.routing);
      const value = item.sentiment ? item.sentiment.sentiment : undefined;
      if (!this.itemsLoaded) {
        return 'rgb(120,120,120)';
      } else {
        return filterPipe.transform(value);
      }
    }
  }

  iNewone() {
    this.dialog.open(QueryEditorComponent, {});
  }

  iEdit(item) {
    this.dialog.open(QueryEditorComponent, {
      data: item.query,
    });
  }

  iDelete() {
    if (this.selectedQueries.length !== 0) {
      if (window.confirm('Do you want to delete selected queries?')) {
        const queriesIds = this.selectedQueries.map((query) => query.uid);
        this.auth.delQueries(queriesIds).subscribe(() => {
          this.router.navigate([], {
            queryParams: {
              update: Math.random(),
              queryid: null,
              newsfeedFromQuery: null,
            },
            queryParamsHandling: 'merge',
          });
        });
      }
    }
  }

  iManage() {
    this.router.navigate([], {
      queryParams: {
        id: 'default',
        groupId: 'default',
        defaultsort: 'abc',
        queryid: null,
        newsfeedFromQuery: null,
      },
      queryParamsHandling: 'merge',
      relativeTo: this.route,
    });
  }

  iChart(item) {
    if (this.routing.isWidget()) {
      this.router.navigate(['/widget/newsfeed'], {
        queryParams: {
          id: item.query.uid,
          type: 'search_query',
          newsfeedFromQuery: null,
          queryid: null,
        },
        queryParamsHandling: 'merge',
        relativeTo: this.route,
      });
    } else {
      this.router.navigate(['../chart'], {
        queryParams: {
          id: item.query.uid,
          newsfeedFromQuery: null,
          queryid: null,
        },
        queryParamsHandling: 'merge',
        relativeTo: this.route,
      });
    }
  }

  /**
   * add tag to the url on item click
   */
  addTag(item) {
    if (this.routing.isWidget()) {
      this.iChart(item);
    } else {
      if (this.params.newsfeedFromQuery === item.query.uid) {
        this.auth.queryForNewsfeed = null;
      } else {
        this.auth.queryForNewsfeed = item;
      }
      this.router.navigate([], {
        queryParams: {
          newsfeedFromQuery:
            this.params.newsfeedFromQuery === item.query.uid
              ? null
              : item.query.uid,
          newstype: null,
          queryid: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    }
  }

  onMouseEnterName(id) {
    this.currentQuery = id;
  }

  onMouseLeaveName() {
    this.currentQuery = null;
  }

  iImport(event, type) {
    if (event === null && type === 'query') {
      if (
        this.items &&
        this.items.length >= this.auth.featureFlags.queriesPerCollectionsLimit
      ) {
        this.snackBar.open(
          'Number of queries per collection is limited to ' +
            this.auth.featureFlags.queriesPerCollectionsLimit +
            '.',
          'OK',
          { duration: 5000 },
        );
      } else {
        const currentCollection = this.auth.querys
          ? this.auth.querys.filter((item) => {
              return item.uid === this.params.id;
            })[0]
          : {};
        this.dialog.open(QueryImportComponent, {
          data: {
            collection: currentCollection,
            queries: this.items.map((element) => {
              return element.query;
            }),
          },
        });
      }
    } else if (event !== null && type === 'collection') {
      if (
        this.auth.querys &&
        event.target.files &&
        this.auth.querys.length + event.target.files.length >
          this.auth.featureFlags.collectionsLimit
      ) {
        this.snackBar.open(
          'Number of collections is limited to ' +
            this.auth.featureFlags.collectionsLimit +
            '.',
          'OK',
          { duration: 5000 },
        );
      } else {
        if (event.target.files && event.target.files[0]) {
          for (let i = 0; i < event.target.files.length; i++) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
              const result = JSON.parse(e.target.result);
              if (
                result.content &&
                result.content.length >
                  this.auth.featureFlags.queriesPerCollectionsLimit
              ) {
                this.snackBar.open(
                  'Number of queries per collection is limited to ' +
                    this.auth.featureFlags.queriesPerCollectionsLimit +
                    '.',
                  'OK',
                  { duration: 5000 },
                );
              } else {
                this.auth.addGroupQuery(result.name).subscribe((result2) => {
                  const queriesArray = [];
                  result.content.forEach((query) => {
                    queriesArray.push(
                      Object.assign({}, query, {
                        groups: [result2.id],
                      }),
                    );
                  });
                  this.auth.addQueries(queriesArray).subscribe(() => {
                    this.router.navigate([], {
                      queryParams: {
                        id: result2.id,
                        groupId: result2.id,
                        update: Math.random(),
                        queryid: null,
                        newsfeedFromQuery: null,
                      },
                      queryParamsHandling: 'merge',
                    });
                  });
                });
              }
            };
            reader.readAsText(event.target.files[i]);
          }
        }
      }
    } else if (event !== null && type === 'query') {
      if (event.target.files && event.target.files[0]) {
        for (let i = 0; i < event.target.files.length; i++) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            const result = JSON.parse(e.target.result);
            const data = {
              groups: ['default'],
              name: result.name,
              query: result.query,
            };
            this.auth.addQuery(data).subscribe((result2) => {
              this.auth.getGroupsQuery().subscribe((groups) => {
                groups.map((group) => {
                  if (group.uid === result2.uid) {
                    this.auth.iquery = group.content;
                    this.auth.query = {
                      uid: group.uid,
                      name: group.name,
                      query: {
                        operator: 'or',
                        filters: [],
                        groups: group.content.map((query) => query.query),
                      },
                    };
                  }
                });
              });
              this.router.navigate([], {
                queryParams: {
                  update: Math.random(),
                  queryid: null,
                  newsfeedFromQuery: null,
                },
                queryParamsHandling: 'merge',
              });
            });
          };
          reader.readAsText(event.target.files[i]);
        }
      }
    }
  }

  iQuery() {
    if (
      !(this.params.id === 'default') &&
      this.items &&
      this.items.length >= this.auth.featureFlags.queriesPerCollectionsLimit
    ) {
      this.snackBar.open(
        'Number of queries per collection is limited to ' +
          this.auth.featureFlags.queriesPerCollectionsLimit +
          '.',
        'OK',
        { duration: 5000 },
      );
    } else {
      this.dialog.open(QueryEditorComponent, {
        // data: {}
      });
    }
  }

  iModify() {
    this.dialog.open(QueryNameComponent, {
      data: this.auth.query ? this.auth.query : {},
    });
  }

  iCreate() {
    if (
      this.auth.querys &&
      this.auth.querys.length >= this.auth.featureFlags.collectionsLimit
    ) {
      this.snackBar.open(
        'Number of collections is limited to ' +
          this.auth.featureFlags.collectionsLimit +
          '.',
        'OK',
        { duration: 5000 },
      );
    } else {
      this.dialog.open(QueryNameComponent, {
        // data: {}
      });
    }
  }

  // inTab(uid) {
  //   this.router.navigate([], {
  //     queryParams: {
  //       id: uid,
  //       groupId: uid
  //     },
  //     queryParamsHandling: 'merge',
  //     relativeTo: this.route
  //   });
  // }

  iExport(type) {
    /*if (type === 'events') {
      this.yukkApi.exportEvent(this.params, 'query').subscribe(result => {
        FileSaver.saveAs(result, 'event_export.xlsx');
      });
    } else */
    if (type === 'collection') {
      const collectionPure = this.auth.querys
        .filter((collection) => {
          return collection.uid === this.params.groupId;
        })
        .map((element) => {
          return {
            name: element.name,
            content: element.content.map((query) => {
              return {
                name: query.name,
                query: query.query,
              };
            }),
          };
        })[0];
      const collectionString = JSON.stringify(collectionPure);
      const blob = new Blob([collectionString], { type: 'application/json' });
      FileSaver.saveAs(blob, 'collection_' + collectionPure.name + '.json');
    } else if (type === 'queries') {
      this.selectedQueries.forEach((query) => {
        const queryPure = {
          name: query.name,
          query: query.query,
        };
        const queryString = JSON.stringify(queryPure);
        const blob = new Blob([queryString], { type: 'application/json' });
        FileSaver.saveAs(blob, 'query_' + queryPure.name + '.json');
      });
    } else if (type === 'stats') {
      if (this.items && this.items.length > 0) {
        const date = new Date();
        const displayDate =
          date.getFullYear() +
          '-' +
          date.getMonth() +
          '-' +
          date.getDate() +
          ' ' +
          date.getHours() +
          '-' +
          date.getMinutes() +
          '-' +
          date.getSeconds();
        const doubleArrayData = [['Name', 'Vol', 'ΔVol', 'Sent', 'ΔSent']];
        this.items.forEach((query) => {
          doubleArrayData.push([
            query.query && query.query.name ? query.query.name : '',
            query.sentiment && query.sentiment.count
              ? query.sentiment.count
              : '',
            query.sentiment && query.sentiment.volume_ratio
              ? query.sentiment.volume_ratio
              : '',
            query.sentiment && query.sentiment.sentiment
              ? query.sentiment.sentiment
              : '',
            query.sentiment && query.sentiment.sentiment_delta
              ? query.sentiment.sentiment_delta
              : '',
          ]);
        });
        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(doubleArrayData);
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        /* save to file */
        XLSX.writeFile(
          wb,
          'Collection statistics from ' + displayDate + '.xlsx',
        );
      }
    }
  }

  /**
   * apply chosen sorting option
   */
  goSort(value) {
    if (this.params.sort === value) {
      this.router.navigate([], {
        queryParams: { sort: '-' + value },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    } else {
      this.router.navigate([], {
        queryParams: { sort: value },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    }
  }

  /**
   * apply chosen sorting option for default collection (queries management page)
   */
  goDefaultSort(value) {
    if (this.params.defaultsort === value) {
      this.router.navigate([], {
        queryParams: { defaultsort: '-' + value },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    } else {
      this.router.navigate([], {
        queryParams: { defaultsort: value },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    }
  }

  /**
   * change main view layout of the collection board (mosaic or list)
   */
  changeView(type) {
    if (type === 'mosaic') {
      if (this.config.appConfig.setup.useStorage) {
        localStorage.setItem('collection_view', 'mosaic');
      }
      this.collectionView = 'mosaic';
    } else if (type === 'list') {
      if (this.config.appConfig.setup.useStorage) {
        localStorage.setItem('collection_view', 'list');
      }
      this.collectionView = 'list';
    }
  }

  loadSampleCollections() {
    for (let i = 0; i < autocollection.length; i++) {
      const result = autocollection[i];
      this.auth.addGroupQuery(result.name).subscribe((result2) => {
        const queriesArray = [];
        result.content.forEach((query) => {
          queriesArray.push(
            Object.assign({}, query, {
              groups: [result2.id],
            }),
          );
        });
        this.auth.addQueries(queriesArray).subscribe(() => {
          this.router.navigate([], {
            queryParams: {
              id: result2.id,
              groupId: result2.id,
              update: Math.random(),
              queryid: null,
              newsfeedFromQuery: null,
            },
            queryParamsHandling: 'merge',
          });
        });
      });
    }
  }
}
