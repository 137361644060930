const ESG_LAB_EVENTS = [
  'E53_B',
  'E53_A',
  'E13_A',
  'E33_D',
  'E48_F',
  'E33_B',
  'E34_B',
  'E17_A',
  'E34_A',
  'E55_A',
  'E22_B',
  'E34_D',
  'E18_A',
  'E17_B',
  'E51_A',
  'E48_G',
  'E22_A',
  'E48_A',
  'E22_C',
  'E34_C',
  'E52_A',
  'E20_A',
  'E48_D',
  'E17_C',
  'E33_G',
  'E33_E',
  'E33_K',
  'E21_B',
  'E25_B',
  'E18_B',
  'E33_L',
  'E49_B',
  'E33_C',
  'E33_J',
  'E25_E',
  'E16_A',
  'E48_B',
  'E33_H',
  'E33_I',
];

const RISK_LAB_EVENTS = [
  'E18_C',
  'E16_A',
  'E35_B',
  'E13_A',
  'E13_B',
  'E47_A',
  'E45_A',
  'E18_F',
  'E21_A',
  'E20_A',
  'E18_B',
  'E22_A',
  'E22_B',
  'E22_C',
  'E18_A',
];

const NEWS_AND_INVEST_LAB_EVENTS = [
  'E11_A',
  'E18_A',
  'E18_B',
  'E18_C',
  'E42_B',
  'E6_A',
  'E6_B',
  'E6_E',
  'E7_A',
  'E13_A',
  'E13_B',
  'E15_A',
  'E17_C',
  'E19_A',
  'E19_B',
  'E2_B',
  'E3_A',
  'E35_B',
  'E42_A',
  'E45_A',
  'E45_C',
  'E5_A',
  'E8_A',
  'E8_B',
  'E25_B',
  'E25_E',
  'E32_C',
  'E52_B',
  'E38_E',
  'E41_C',
  'E14_A',
  'E14_B',
  'E16_A',
  'E20_A',
  'E21_A',
  'E22_A',
  'E22_B',
  'E22_C',
  'E23_A',
  'E45_B',
  'E47_A',
  'E48_A',
  'E48_B',
  'E48_C',
  'E48_H',
  'E48_I',
  'E51_A',
  'E53_A',
  'E53_B',
  'E55_A',
];

export const portfolioEvents = {
  esgLab: ESG_LAB_EVENTS,
  riskLab: RISK_LAB_EVENTS,
  newsLab: NEWS_AND_INVEST_LAB_EVENTS,
  investLab: NEWS_AND_INVEST_LAB_EVENTS,
};
