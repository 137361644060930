<div class="userform ">

  <div class="logo pointer" routerLink="/">
    <img src="assets/logo/{{logo}}_default.svg" alt="yukkalab">
  </div>

  <div class="message">
    <div *ngIf="message=='welcome'">
      <div>Welcome to YUKKALab</div>
    </div>
    <div *ngIf="message=='expired'">
      <div>Your session has expired, please login again.</div>
    </div>
    <div *ngIf="message=='newuser'">
      <div>You have registered a new account, it still has to be approved.</div>
    </div>
    <div *ngIf="message=='password'">
      <div>Please check your email inbox to reset the password.</div>
    </div>
    <div *ngIf="message=='notadmin'">
      <div>Only the administrator can enter in this area.</div>
    </div>
    <div *ngIf="message=='notreview'">
      <div>Only the admin or review can enter in this area.</div>
    </div>
    <div *ngIf="message=='email'">
      <div>Your email address has to be verified. Please check your email inbox.</div>
    </div>
    <div *ngIf="message=='approve'">
      <div>Your account still has to be approved.</div>
    </div>
    <div *ngIf="message=='blocked'">
      <div>This account is currently under review.</div>
    </div>
    <div *ngIf="message=='error404'">
      <div class="error404">ERROR 404</div>
    </div>
    <div *ngIf="(message=='errorMessage') && errorMessage">
      <div>{{errorMessage}}</div>
    </div>
    <div *ngIf="message=='logout'">
      <div>LOGOUT</div>
    </div>
    <div *ngIf="message=='autherror'">
      <div>This service is currently not available<br>We apologize for any inconvenience.</div>
    </div>
  </div>

  <div class="disclaimer">
    <span class="link" routerLink="../../login">login page</span>
    <!-- <a class="link" href="https://shop.yukkalab.de/" target="_blank">registration</a> - -->
    <!-- <span class="link" routerLink="../../registration">registration</span> - -->
    <!-- <span class="link" routerLink="../../password">password</span> -->
  </div>

</div>
