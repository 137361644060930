<div class="chat__navigation">
  <div class="chat__history">
    <div class="chat-header">
      <span>News Assistant</span>
      <span class="info-label">BETA</span>
    </div>
    <div class="line"></div>
    <button
      class="chat__button"
      id="chat-start-new-chat"
      (click)="startNewChat()"
    >
      New Chat
    </button>
    <div *ngIf="chats && chats.length > 0">
      <h4 class="chat__h4">Past conversations</h4>
    </div>
    <div class="chats__container">
      <ng-container *ngFor="let chat of chats">
        <div
          class="chat__title__container"
          [ngClass]="{
            'chat__title__container--active': chat.chat_id === activeChatId,
          }"
          (click)="goToChatConversation(chat)"
          (mouseenter)="onMouseEnter(chat)"
          (mouseleave)="onMouseLeave(chat)"
        >
          <div class="chat__title" *ngIf="!editMode[chat.chat_id]">
            {{ chat.title }}
          </div>
          <input
            *ngIf="editMode[chat.chat_id]"
            [(ngModel)]="chat.title"
            (keyup.enter)="saveChatTitle(chat)"
            type="text"
            class="chat__titleEdit"
          />
          <div class="chat__icons">
            <mat-icon
              *ngIf="!editMode[chat.chat_id]"
              [class.hidden]="!chat.visible"
              class="chat__icon edit-icon"
              (click)="editChat(chat.chat_id, $event)"
              >edit</mat-icon
            >
            <mat-icon
              *ngIf="!editMode[chat.chat_id]"
              [class.hidden]="!chat.visible"
              class="chat__icon delete-icon"
              (click)="deleteChat(chat.chat_id)"
              >delete</mat-icon
            >
            <div *ngIf="editMode[chat.chat_id]" class="chat__iconEdit">
              <mat-icon
                *ngIf="editMode[chat.chat_id]"
                class="chat__icon save-icon"
                (click)="saveChatTitle(chat)"
                >done</mat-icon
              >
              <mat-icon
                *ngIf="editMode[chat.chat_id]"
                class="chat__icon abort-icon"
                (click)="abortEditChat(chat)"
                >close</mat-icon
              >
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
