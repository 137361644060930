import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../../service/auth.service';
import { YukkApi } from '../../../service/yukkapi.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { catchError, first } from 'rxjs/operators';
import { marked } from 'marked';
import { RoutingService } from '../../../service/routing.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ChatMessage,
  AIMessage,
  ChatAIMessage,
  SourceDoc,
  ChatDetailsResponse,
  Fact,
  UserInfo,
} from '../interfaces/chart-details.interface';

import { switchMap, map } from 'rxjs/operators';
import { iif, of } from 'rxjs';

import { PortfolioSetupComponent } from '../../settings/portfolio-setup/portfolio-setup.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserAccount } from 'src/models/user.types';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  headerText = 'YUKKA Lab News LLM';

  chatInput: string; // question from user
  chatOutput = []; // answer from LLM stored in an array
  chatHistory = []; // storing chatInput (called message) and answer (called history) in an array

  loading = false;
  error = false;
  params: { chatId?: string; [key: string]: unknown };
  chatDetails: (ChatMessage | ChatAIMessage)[];

  // properties for history sent in auth.llmChat
  chatIdQuestion = '';
  chatIdAnswer: string | null = '';
  chatIdHistory: [string, string][] = [];
  chatTitle = '';
  portfolioCompanies: string[] = [];

  // passing chatId and chatTitle to chatNavigation Component (child component)
  parentMessageChatTitle: string;
  parentMessageChatId: string;

  isDialogOpen: boolean = false;

  userAccountInfo: UserAccount;

  constructor(
    public auth: AuthService,
    public yukkApi: YukkApi,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public routing: RoutingService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.params = params;
    });
    this.userAccountInfo = this.auth.accountInfo;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.context === 'llm_signup' && !this.isDialogOpen) {
        this.isDialogOpen = true;
        this.dialog
          .open(PortfolioSetupComponent)
          .afterClosed()
          .subscribe(() => {
            this.isDialogOpen = false;
            this.router.navigate([], {
              queryParams: {
                context: null,
              },
              queryParamsHandling: 'merge',
              replaceUrl: true,
            });
          });
      }
      const chatId = params['chatId'];
      if (chatId) {
        this.loadConversation(chatId);
      }

      this.fetchTheUsersPortfolio();
    });
  }

  /**
   * Checks if the given message is an instance of AIMessage.
   *
   * @param message - The message to check.
   * @returns True if the message is an instance of AIMessage, false otherwise.
   */
  isAIMessage(message: string | AIMessage): message is AIMessage {
    return (message as AIMessage).entityName !== undefined;
  }

  // loading conversation based on ChatId
  loadConversation(chatId: string) {
    this.chatIdHistory = [];
    // get chat conversation based on chatId
    this.auth.getChatDetails(chatId).subscribe(
      (chatDetails) => {
        this.chatDetails = (chatDetails as ChatDetailsResponse).messages.map(
          (chat) => {
            // determining which message belongs to ai or human
            if (chat.actor === 'ai') {
              let parsedMessage: AIMessage | string = chat.message;
              if (typeof chat.message === 'string') {
                try {
                  parsedMessage = this.parseJsonString(
                    chat.message,
                  ) as AIMessage;
                  (chat as ChatAIMessage).message = parsedMessage;
                } catch (e) {
                  console.error('Error parsing AI message:', e);
                }
              }

              if (typeof parsedMessage === 'object') {
                const companyInfo = this.extractCompanyInfo(
                  parsedMessage.requested_entities,
                );
                const articlesToUse =
                  this.articlesWithEventsAndCitations(parsedMessage);
                (chat as ChatAIMessage).message = this.processChatMessage(
                  parsedMessage,
                  articlesToUse,
                  companyInfo,
                );
                if (parsedMessage.summary?.text?.length > 0) {
                  this.chatIdAnswer = parsedMessage.summary.text;
                } else if (
                  !parsedMessage.summary &&
                  parsedMessage.messages?.length > 0
                ) {
                  if (typeof parsedMessage.messages[0] == 'string') {
                    this.chatIdAnswer = parsedMessage.messages[0];
                  }
                } else {
                  console.log('No valid AI message found.');
                }
              }
            } else if (
              chat.actor === 'human' &&
              !this.isAIMessage(chat.message)
            ) {
              this.chatIdQuestion = chat.message;
            }

            // Push the pair [question, answer] to chatIdHistory array
            if (this.chatIdQuestion && this.chatIdAnswer) {
              if (this.chatIdHistory.length >= 10) {
                this.chatIdHistory.shift();
              }
              this.chatIdHistory.push([this.chatIdQuestion, this.chatIdAnswer]);
              this.chatIdQuestion = '';
              this.chatIdAnswer = '';
              this.chatTitle = this.chatIdHistory[0][0];
              this.parentMessageChatTitle = this.chatTitle;
              this.parentMessageChatId = chatId;
            }

            return chat;
          },
        );
      },
      (error) => {
        console.error('Error fetching chat details:', error);
      },
    );
  }

  submitRelatedQuestions(question) {
    if (question && typeof question === 'string' && !this.loading) {
      this.postTheMessage(question);
    }
  }

  // post request into chatId conversation
  postChatConversation(chatId: string, actor: string, message: string): void {
    this.auth.postChatConversation(chatId, actor, message).subscribe({
      next: () => {
        this.loadConversation(chatId);
      },
      error: (err) => console.error('Error posting message:', err),
    });
  }

  postTheMessage(userQuestion: string): void {
    setTimeout(() => {
      const target = document.getElementById('llmContainer');
      target?.scrollTo(0, target.scrollHeight);
    }, 500);
    this.loading = true;
    const userInfo = this.generateUserInformation();
    const message = userQuestion.trim(); //question
    const history = JSON.parse(JSON.stringify(this.chatIdHistory)); //answer
    const chatId = this.params.chatId; // chatId
    this.chatInput = '';
    if (!this.error) {
      this.chatHistory.push([marked.parse(message), '']);
      this.chatOutput.push({});
    } else {
      this.chatHistory[this.chatHistory.length - 1][0] = message;
    }
    this.error = false;
    this.postChatConversation(chatId, 'human', message);
    const chatPayload = {
      message: message,
      history: history,
      ...userInfo,
    };
    // post message and history into v2/chat endpoint
    this.yukkApi
      .chatLLM(chatPayload)
      .pipe(
        first(),
        switchMap((res) => {
          const copyRes = JSON.parse(JSON.stringify(res));
          const copyRes2 = JSON.parse(JSON.stringify(res));
          this.chatHistory[this.chatHistory.length - 1][1] = marked.parse(
            copyRes.history[copyRes.history.length - 1][1],
          );
          return iif(
            () =>
              copyRes2.requested_entities &&
              copyRes2.requested_entities.length > 0,
            this.yukkApi
              .getEntitiesInfo(
                copyRes2.requested_entities ? copyRes2.requested_entities : [],
                'array',
              )
              .pipe(
                map((entitiesRes) => {
                  const entityName = entitiesRes.map((entity) => entity.name);
                  return { copyRes2, entityName };
                }),
              ),
            of({ copyRes2, entityName: null }),
          );
        }),
      )
      .subscribe(
        ({ copyRes2, entityName }) => {
          this.processResponse(copyRes2, entityName);
        },
        () => {
          this.handleError();
        },
      );
  }

  onSubmit() {
    if (
      this.chatInput &&
      typeof this.chatInput === 'string' &&
      this.chatInput.trim() &&
      !this.loading
    ) {
      this.postTheMessage(this.chatInput);
    }
  }
  extractCompanyInfo(requestedEntities: string[]): {
    companyName: string;
    companyType: string;
  } {
    let companyName = '';
    let companyType = '';

    if (requestedEntities && requestedEntities.length > 0) {
      const firstEntity = requestedEntities[0];
      const [type, name] = firstEntity.split('.');
      companyName = name;
      companyType = type;
    }

    return { companyName, companyType };
  }

  articlesWithEventsAndCitations(aiMessage: AIMessage): SourceDoc[] {
    const addedArticleIds = new Set<string>();
    const sourceDocumentsWithEvents = aiMessage.source_documents?.reduce(
      (acc, article) => {
        if (!addedArticleIds.has(article.id)) {
          let matchingEntityNames = [];
          const matchingEvents = aiMessage.events
            ?.filter((event) => event.document_ids.includes(article.id))
            .map((event) => event.name);
          if (aiMessage.requested_entities?.length > 1) {
            matchingEntityNames = aiMessage.events
              ?.filter((event) => event.document_ids.includes(article.id))
              .map((event) => event.entity_name);
          }
          acc.push({
            ...article,
            eventNames: matchingEvents || [],
            entityNames: matchingEntityNames,
          });

          addedArticleIds.add(article.id);
        }
        return acc;
      },
      [],
    );

    let factCitationCounter = 1;
    aiMessage?.facts?.forEach((fact: Fact) => {
      if (fact.source_doc_ids.length > 0) {
        fact.source_doc_ids.map((source) => {
          const sourceDoc = sourceDocumentsWithEvents.find(
            (doc: SourceDoc) => doc.id === source,
          );
          if (sourceDoc) {
            if (!sourceDoc.citation) {
              sourceDoc.citation = factCitationCounter++;
            }
          }
        });
      }
    });

    if (sourceDocumentsWithEvents?.length > 0) {
      sourceDocumentsWithEvents.sort((a, b) => {
        if (a.citation !== undefined && b.citation !== undefined) {
          return a.citation - b.citation;
        }
        if (a.citation !== undefined) {
          return -1;
        }
        if (b.citation !== undefined) {
          return 1;
        }
        return (
          new Date(b.publish_time).getTime() -
          new Date(a.publish_time).getTime()
        );
      });
    }
    return sourceDocumentsWithEvents;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  processResponse(copyRes2: any, entityName: string[] | null) {
    if (!copyRes2.facts || copyRes2.facts.length === 0) {
      this.chatOutput[this.chatOutput.length - 1] = Object.assign(
        {},
        copyRes2,
        {
          entityName,
        },
      );
    } else {
      this.chatOutput[this.chatOutput.length - 1] = Object.assign(
        {},
        copyRes2,
        {
          entityName,
        },
      );
    }
    const chatId = this.params.chatId;
    this.loading = false;

    if (chatId) {
      const stringifiedAiMessage = JSON.stringify(
        this.chatOutput[this.chatOutput.length - 1],
      );
      this.postChatConversation(chatId, 'ai', stringifiedAiMessage);
    }
  }

  processChatMessage(
    message: AIMessage,
    articlesToUse?: SourceDoc[],
    companyInfo?: { companyName: string; companyType: string },
  ) {
    const processedMessage = { ...message };

    if (articlesToUse) {
      processedMessage.source_documents_events = articlesToUse;
    }

    if (companyInfo) {
      processedMessage.companyName = companyInfo.companyName;
      processedMessage.companyType = companyInfo.companyType;
    }

    return processedMessage;
  }

  private fetchTheUsersPortfolio() {
    this.auth.portFolios().subscribe((folios) => {
      const lastPortfolio = folios[0];
      if (lastPortfolio) {
        const companyEntities = lastPortfolio.content.filter((entity) => {
          const [type, name] = entity.split(':');
          if (type === 'company') {
            return true;
          }
        });
        const first10CompanpaniesOfPortfolio = companyEntities.slice(0, 10);

        this.yukkApi
          .getEntitiesInfo(first10CompanpaniesOfPortfolio, 'array')
          .pipe(
            catchError((error) => {
              console.error('Error fetching entity info:', error);
              return of(null);
            }),
          )
          .subscribe((entities) => {
            this.portfolioCompanies = entities.map((entity) => entity?.name);
          });
      }
    });
  }

  public handleError() {
    this.error = true;
    this.loading = false;
    this.snackBar.open('Something went wrong. Please try again later.', 'OK', {
      duration: 10000,
    });
  }

  private generateUserInformation(): UserInfo {
    const userInfo = this.userAccountInfo;
    console.log('userInfo', userInfo);
    return {
      user_role: this.formatRoleName(userInfo.role),
      user_industry: userInfo.industry ? userInfo.industry.toLowerCase() : null,
      user_interests: userInfo.motivations.map((interest) =>
        this.mappingMotivationsToInterest(interest),
      ),
      user_portfolio: this.portfolioCompanies,
    };
  }

  private mappingMotivationsToInterest(interestKey: string): string {
    const interestMap: { [key: string]: string } = {
      portfolio_management:
        'Manage personal or client portfolios more effectively.',
      market_and_competitive_analysis:
        'Gain insights into market trends, opportunities, and competitor strategies.',
      sustainability_monitoring:
        'Continuously monitor and assess sustainability efforts to ensure impact.',
      performance_monitoring:
        'Monitor the performance of investments and companies.',
      risk_assessment:
        'Evaluate and manage investment, partner or customer risks effectively. ',
      business_development:
        'Unlock growth by identifying opportunities, forging partnerships, and expanding your market reach.',
      other: 'Something else',
    };

    return interestMap[interestKey] || '';
  }

  private formatRoleName(industry: string): string {
    if (!industry) {
      return null;
    }
    return industry.toLowerCase().trim().replace(/\s+/g, '_');
  }

  parseJsonString(jsonString: string): unknown {
    try {
      return JSON.parse(jsonString);
    } catch (e) {
      console.error('Error parsing JSON string:', e);
      return jsonString; // Return the original string if it's not a valid JSON
    }
  }
}
