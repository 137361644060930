/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

//service
import { YukkApi } from 'src/app/service/yukkapi.service';
import { ColorScorePipe } from 'src/app/pipe/color-score.pipe';
import { ColorScore2Pipe } from 'src/app/pipe/color-score2.pipe';
import { ColorPipe } from 'src/app/pipe/color.pipe';
import { AuthService } from 'src/app/service/auth.service';
import { RoutingService } from 'src/app/service/routing.service';
import getScoreTypeDetails from '../chart-score-names';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-chat-charts-ranking',
  templateUrl: './chat-charts-ranking.component.html',
  styleUrls: ['./chat-charts-ranking.component.scss'],
})
export class ChatChartsRankingComponent implements OnInit, OnChanges {
  @Input() chartEntities: string[];
  @Input() childRankingScoreType: string;
  @Input() childRankingTimeframe: string;

  rankingTableData: any[] = [];

  loading: boolean;
  noData: boolean;

  constructor(
    private auth: AuthService,
    private yukkApi: YukkApi,
    public routing: RoutingService,
  ) {}

  ngOnInit(): void {
    this.getGraphRanking();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['childRankingScoreType']) {
      this.getGraphRanking();
    }
  }
  public getRankingScoreTitle(currentScoreType: string): string {
    return getScoreTypeDetails(currentScoreType).shortName;
  }

  private getGraphRanking() {
    this.loading = true;
    this.noData = false;
    this.yukkApi
      .getEntitiesInfo(this.chartEntities ? this.chartEntities : [], 'array')
      .pipe(
        catchError((error) => {
          console.error('Error fetching entity info:', error);
          this.noData = true;
          this.loading = false;
          return of(null);
        }),
      )

      .subscribe((entitiy) => {
        if (this.noData || !entitiy) return;

        const entitiesRes = Object.values(entitiy).filter((element) => {
          return element !== null;
        }) as any[];

        const content = entitiesRes.map((entity: any) => entity.compound_key);
        if (this.childRankingScoreType) {
          this.yukkApi
            .scoresTimeSeries(
              this.childRankingScoreType === 'sentiment'
                ? 'sentiment'
                : 'score',
              Object.assign(
                {},
                {
                  usePortfolio: true,
                  itemized: true,
                  requestScore: getScoreTypeDetails(this.childRankingScoreType)
                    .scoreKey,
                  custom_payload: content,
                  time: this.childRankingTimeframe,
                },
              ),
              'chart',
            )
            .pipe(
              catchError((error) => {
                console.error('Error fetching scores:', error);
                this.noData = true;
                this.loading = false;
                return of(null);
              }),
            )
            .subscribe((res) => {
              if (this.noData || !res) return;

              this.generateRankingTableData(res, entitiesRes);
              this.loading = false;
            });
        }
      });
  }

  private generateRankingTableData(scores: any, entities: any[]) {
    const chartData = [];
    if (this.childRankingScoreType === 'sentiment') {
      scores?.quotient_ts_itemized.forEach((element) => {
        const sentimentLastScore =
          element.quotient_ts[element.quotient_ts.length - 1]?.quotient;

        chartData.push({
          name: entities.find(
            (entitiy: any) =>
              entitiy.compound_key === element.entity_info.compound_key,
          )?.name,
          score: sentimentLastScore,
          scoreType: this.childRankingScoreType,
        });
      });
    } else {
      scores.forEach((element) => {
        chartData.push({
          name: entities.find(
            (entitiy: any) =>
              entitiy.compound_key === element.entity_info.compound_key,
          )?.name,
          score: element.score_ts_last_score?.score?.toFixed(2) || '-',
          scoreType: this.childRankingScoreType,
        });
      });
    }

    this.rankingTableData = chartData;
  }

  getScoreColor(item) {
    const scoreKey = getScoreTypeDetails(item.scoreType).scoreKey;
    if (scoreKey === 'sentiment') {
      const filterPipe = new ColorPipe(this.routing);
      return filterPipe.transform(item.score);
    } else {
      const filterPipe =
        this.auth.getScoreType(scoreKey) === 'pos'
          ? new ColorScore2Pipe(this.routing)
          : new ColorScorePipe(this.routing);
      return filterPipe.transform(item.score);
    }
  }
}
