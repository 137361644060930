import { Component, ViewEncapsulation } from '@angular/core';
import { ColorPipe } from 'src/app/pipe/color.pipe';
import { Chart } from 'angular-highcharts';
import * as moment from 'moment';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { ConfigService } from 'src/app/service/config.service';
import { RoutingService } from 'src/app/service/routing.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

/**
 * The radar like the entity chart is component by 2 bubble charts.
 * The fisrt one stay fixed in top displaying only the xAsix
 * The sencond one can be scrolled and have multiple yAxis
 */

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-news-radar',
  templateUrl: './news-radar.component.html',
  styleUrls: ['./news-radar.component.scss']
})
export class NewsRadarComponent {

  options: any;
  chart: Chart;
  chartRef: any;
  chart1: Chart;
  chart1Ref: any;
  inoptions = {
    color: '255,255,255',
    color1: '0,0,0',
  };
  params: any;
  titolo: any;
  topic_radar: any;
  loading: boolean;
  previousValue: any;
  previousValue2: any;
  nodata: boolean;
  theme: string;
  maxh = 120;
  bubmin = 8;
  bubmax: number;

  constructor(
    private route: ActivatedRoute,
    private colorpipe: ColorPipe,
    private yukkApi: YukkApi,
    public routing: RoutingService,
    private router: Router,
    public auth: AuthService,
    public config: ConfigService,
  ) {

    this.theme = this.config.appConfig.routing.theme;

    this.route.queryParams.subscribe(params => {
      this.params = params;
      this.bubmax = window.innerWidth / 100 * 5;
      if (params.time === '7') {
        this.bubmax = window.innerWidth / 100 * 20;
      }
      if (this.bubmax > 118) {
        this.bubmax = 118;
      }
      const reflowParams = ['reflow'];
      if (this.routing.reFresh(this.params, this.previousValue, reflowParams)) {
        if (this.chartRef) {
          setTimeout(() => {
            this.chartRef.source.value.reflow();
          }, 500);
        }
        if (this.chart1Ref) {
          setTimeout(() => {
            this.chart1Ref.source.value.reflow();
          }, 500);
        }
      }
      if (routing.reFresh(params, this.previousValue, ['type', 'id', 'time', 'lang', 'feed', 'categories', 'continents', 'countries', 'factuality', 'temporality', 'ranks', 'panels', 'period', 'theme', 'update'])) {
        this.loading = true;
        this.nodata = false;
        this.topic_radar = [];
        if (this.routing.theme === 'light') { this.inoptions.color = '0,0,0'; }
        if (params.theme && params.theme === 'dark') {
          this.inoptions.color = '255,255,255';
        } else if (params.theme && params.theme === 'light') {
          this.inoptions.color = '0,0,0';
        }
        this.yukkApi.isentiment(this.params, true).subscribe(result => {
          if (!(this.auth.scorelabSettings.defaultScore === 'sentiment') && this.params.type && ((this.params.type === 'company') || (this.params.type === 'pne'))) {
            this.yukkApi.scoresTimeSeries('score', this.params, 'chart').subscribe(result2 => {
              this.titolo = Object.assign({}, result, {score: Number(result2['score_ts'][result2['score_ts'].length - 1]['score'].toFixed(2))});
            });
          } else {
            this.titolo = result;
          }
        });

        this.yukkApi.trendradar(this.params).subscribe(result => {
          let res = result;
          if ( this.auth.iquery && this.routing.isQuery() ) {
            res = {multi_trend_radar: []};
            result.forEach((item, index) => {
              item.multi_trend_radar.forEach(item2 => {
                if (item2.entity.type === 'search_query') {
                  if (index === 0) {
                    item2.entity.name = this.auth.query.name;
                  } else {
                    item2.entity.name = this.auth.iquery[index - 1].name;
                  }
                  res.multi_trend_radar.push(item2);
                }
              });
            });
          } else if (this.routing.isQuery()) {
            result.multi_trend_radar.forEach((item, index) => {
              if (item.entity.type === 'search_query') {
                res.multi_trend_radar[index].entity.name = this.auth.query.name;
              }
            });
          }

          if (res.multi_trend_radar.length) {
            this.topic_radar = this.inSort(res.multi_trend_radar);

            if (this.params.hasOwnProperty('type') && (this.params.type === 'company')) {
              this.topic_radar = this.topic_radar.filter(item => {
                return (item.entity.type === 'company' || item.entity.type === 'person');
              });
            }

            // const radar_filter = result.multi_trend_radar.filter(item => item.volume > 0)
            // this.topic_radar = this.inSort(radar_filter)
            // if (this.params.id) {
            //   if (this.params.type === 'company') {
            //     this.topic_radar = this.topic_radar.filter(item => {
            //       if (item.entity.alpha_id === this.params.id || item.entity.type === 'person' || item.entity.type === 'product_model') {
            //         return item
            //       }
            //     })
            //   } else {
            //     this.topic_radar = this.topic_radar.filter(item => item.entity.type !== 'continent')
            //     const index = this.topic_radar.map(item => item.entity.alpha_id).indexOf(this.params.id)
            //     this.topic_radar.splice(index, 1)
            //   }
            // }
            this.getOption();
            if (this.chartRef) {
              setTimeout(() => {
                this.chartRef.source.value.reflow();
              }, 1000);
            }
            if (this.chart1Ref) {
              setTimeout(() => {
                this.chart1Ref.source.value.reflow();
              }, 1000);
            }
          } else {
            this.loading = false;
            this.nodata = true;
          }
        }, error => {
          this.loading = false;
          this.nodata = true;
        });
      } else {
        if (routing.reFresh(params, this.previousValue2, ['sort']) && this.params.sort && this.topic_radar) {
          this.loading = true;
          this.topic_radar = this.inSort(this.topic_radar);
          this.getOption();
          if (this.chartRef) {
            setTimeout(() => {
              this.chartRef.source.value.reflow();
            }, 1000);
          }
          if (this.chart1Ref) {
            setTimeout(() => {
              this.chart1Ref.source.value.reflow();
            }, 1000);
          }
        }
      }
      this.previousValue = params;
      this.previousValue2 = params;
    });
  }

  inSort(radar_filter) {
    if (!this.params.sort || this.params.sort === 'score' || this.params.sort === '-score' || this.params.sort === 'trend' || this.params.sort === '-trend' || this.params.sort === 'ratio' || this.params.sort === '-ratio' || this.params.sort === 'count') {
      return radar_filter.sort((a, b) => b.volume - a.volume);
    }
    if (this.params.sort === '-count') {
      return radar_filter.sort((a, b) => a.volume - b.volume);
    }
    if (this.params.sort === 'senti') {
      return radar_filter.sort((a, b) => b.quotient - a.quotient);
    }
    if (this.params.sort === '-senti') {
      return radar_filter.sort((a, b) => a.quotient - b.quotient);
    }
    if (this.params.sort === 'abc') {
      return radar_filter.sort((a, b) => a.entity.name.localeCompare(b.entity.name));
    }
    if (this.params.sort === '-abc') {
      return radar_filter.sort((a, b) => b.entity.name.localeCompare(a.entity.name));
    }
  }

  getOption() {
    this.options = {
      chart: {
        type: 'bubble',
        backgroundColor: 'transparent',
        zoomType: 'x',
        alignTicks: false,
        height: (this.topic_radar.length * this.maxh) + 50 + 'px',
      },
      credits: { enabled: false },
      legend: { enabled: false },
      exporting: { enabled: false },
      title: { text: '' },
      xAxis: {
        visible: true,
        type: 'datetime',
        maxZoom: 3 * 24 * 3600000,
        labels: {
          style: {
            color: 'rgba(' + this.inoptions.color + ', 0.4)'
          },
        },
        lineColor: 'rgba(' + this.inoptions.color + ', 0.2)',
        tickColor: 'rgba(' + this.inoptions.color + ', 0.2)',
        gridLineColor: 'rgba(' + this.inoptions.color + ', 0.05)',
        gridLineWidth: 1,
        opposite: true,
        events: { afterSetExtremes: (event => this.onAfterSetExtremes(event)) }
      },
      tooltip: {
        enabled: false,
        followTouchMove: false,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        shadow: false,
        style: {
          color: 'white',
          fontSize: 14,
        },
        y: -20,
        useHTML: true,
        formatter: function () {
          return this.point.volume + '<br>' + Math.round(this.point.quotient * 100) + '%';
        }
      },
      plotOptions: {
        series: {
          states: {
            inactive: {
              opacity: 1
            }
          },
          dataLabels: {
            enabled: false,
            shadow: false,
            format: '{point.name}',
            style: {
              fontSize: 10,
              textOutline: null,
              color: 'rgba(' + this.inoptions.color + ', 1)'
            },
          }
        }
      },
      yAxis: this.getyAxis(),
      series: this.getSeries(),
    };
    this.chart = new Chart(this.options);
    this.chartRef = this.chart.ref$;
    const options1 = JSON.parse(JSON.stringify(this.options));
    options1.chart.height = 34;
    options1.xAxis.visible = true;
    this.chart1 = new Chart(options1);
    this.chart1Ref = this.chart1.ref$;
    this.loading = false;
  }

  getyAxis() {
    const yAxis = [];
    let margintop = 40;
    this.topic_radar.forEach((item, index) => {
      const height = this.maxh;
      // if (index === 0 && this.params.id) {
      //   height = 200
      // }
      const serie = {
        height: height + 'px',
        top: margintop + 'px',
        title: {
          // text: item.entity.type === 'search_query' ? 'Query' : item.entity.name,
          text: item.entity.name,
          style: {
            color: 'rgba(' + this.inoptions.color + ', 1)'
          },
        },
        labels: {
          enabled: false,
        },
        tickColor: 'rgba(' + this.inoptions.color + ', 0.2)',
        gridLineColor: 'rgba(' + this.inoptions.color + ', 0.05)',
        gridLineWidth: 1,
        offset: 10,
      };
      margintop += height;
      yAxis.push(serie);
    });
    return yAxis;
  }

  getSeries() {
    const series = [];
    this.topic_radar.forEach((item, index) => {
      const serie = {
        yAxis: index,
        data: this.getData(item.series),
        marker: { fillOpacity: .8 },
        minSize: this.bubmin,
        maxSize: this.bubmax,
        // pointInterval: 24 * 3600 * 1000,
        events: {
          click: event => {
            const newsday = moment(event.point.options.x).format('YYYY-MM-DD');
            this.router.navigate([], {
              queryParams: {
                newsday: newsday,
                news: null
              }, queryParamsHandling: 'merge', replaceUrl: false,
            });
          },
        },
      };
      series.push(serie);
    });
    return series;
  }

  getData(array) {
    const serie = [];
    array.forEach(item => {
      if (item.volume) {
        const data = {
          x: moment(item.date_from * 1000).valueOf(),
          y: 0,
          z: item.volume,
          volume: item.volume,
          quotient: item.quotient,
          color: this.colorpipe.transform(item.quotient)
        };
        serie.push(data);
      }
    });
    return serie;
  }

  onAfterSetExtremes(event) {
    if (event.trigger === 'zoom') {
      this.loading = true;
      this.router.navigate([], {
        queryParams: {
          time: moment(event.userMin).format('YYYY-MM-DD') + '--' + moment(event.userMax).format('YYYY-MM-DD'),
          newsday: null,
          news: null
        }, queryParamsHandling: 'merge'
      });
    }
  }

}
