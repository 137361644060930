<div
  id="chat-article-card"
  class="article__card"
  [ngClass]="isTooltip ? 'tooltip' : ''"
>
  <div *ngIf="eventNames?.length > 0 || citation" class="article__header">
    <div class="article__header__tags">
      <span
        *ngIf="eventNames?.length > 0"
        class="article__header__tag article__header__tag--event"
        >{{ eventNames[0] }}</span
      >
      <span
        *ngIf="entityNames?.length > 0"
        class="article__header__tag article__header__tag--entity"
        >{{ entityNames[0] }}</span
      >
    </div>

    <div *ngIf="citation" class="article__header-citation">
      <span>{{ citation }}</span>
    </div>
  </div>

  <div class="article__content">
    <div class="article__content--container">
      <div class="article__title">
        {{ articleTitle }}
      </div>
    </div>
  </div>

  <div class="article__footer">
    <div class="article__published">
      <mat-icon class="icons">access_time</mat-icon>
      <span class="article__published">{{
        publishTime | date: 'mediumDate'
      }}</span>
    </div>
    <div class="article__provider">
      <mat-icon class="icons">public</mat-icon>
      <span
        >{{ provider | slice: 0 : 15
        }}{{ provider?.length > 15 ? '...' : '' }}</span
      >
    </div>
  </div>
</div>
