import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

// service

import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import getScoreTypeDetails from '../chart-score-names';

@Component({
  selector: 'app-chat-charts-events',
  templateUrl: './chat-charts-events.component.html',
  styleUrls: ['./chat-charts-events.component.scss'],
})
export class ChatChartsEventsComponent implements OnInit, OnChanges {
  @Input() childEvent: string;
  @Input() childEventScore: string;
  @Input() childEventsTimeframe: string;

  iframes: [];
  widget = [];

  companyName: string;

  eventScore: string;

  eventsTimeframe: string;

  dynamicUrl: string;

  constructor(
    public auth: AuthService,
    public yukkApi: YukkApi,
  ) {}

  ngOnInit() {
    this.urlChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['childEventScore']) {
      this.urlChange();
    }
  }

  urlChange() {
    this.companyName = this.childEvent;
    this.eventScore = this.childEventScore;
    this.eventsTimeframe = this.childEventsTimeframe;

    this.dynamicUrl = `${window.location.origin}/widget/company?type=company&id=${this.companyName}&time=${this.eventsTimeframe}&nomenu=true&notitle=true&custom_score=${getScoreTypeDetails(this.eventScore).scoreKey}`;
  }
}
