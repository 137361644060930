/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { RoutingService } from 'src/app/service/routing.service';
import { ConfigService } from 'src/app/service/config.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { appVersion } from 'src/environments/version';

/**
 * This component is the login area.
 */

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  hide = true;
  submitted: boolean;
  error_email: boolean;
  error_password: boolean;
  loading = false;
  message: string;
  myurl: string;
  project: any;
  logo: string;
  environment: any;
  version: any;

  getCode: boolean;
  error_code: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    public routing: RoutingService,
    public config: ConfigService,
    public snackBar: MatSnackBar,
  ) {
    if (window.Cypress) {
      console.log('LoginComponent running Cypress');
      window.LoginComponent = this;
    }

    this.version =
      appVersion +
      ' - ' +
      this.config.appConfig.version +
      ' - ' +
      this.config.appConfig.urlDataApi.split('/').pop();

    this.project = this.config.appConfig.routing.project;
    this.environment = this.config.appConfig;
    this.getCode = false;
    this.route.queryParams.subscribe((params) => {
      this.message = params.message;
      this.myurl = params.url;
      if (window.location.hostname === 'swissre.yukkalab.com') {
        this.logo = 'swissre';
      } else {
        this.logo = this.project;
      }
    });
  }

  public loginForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });

  public codeForm = this.fb.group({
    email: ['', Validators.required],
    code: ['', Validators.required],
  });

  doLogin() {
    this.submitted = true;
    this.error_email = false;
    this.error_password = false;
    const email = this.loginForm.controls['email'].value.trim();
    const password = this.loginForm.controls['password'].value.trim();
    if (this.loginForm.valid) {
      this.loading = true;
      this.auth.authLogin(email, password).subscribe(
        (result) => {
          if (!result['two_factor_auth']) {
            if (this.myurl) {
              this.router.navigateByUrl(decodeURIComponent(this.myurl));
            } else {
              this.router.navigate(['../'], { relativeTo: this.route });
            }
          } else {
            this.getCode = true;
            this.submitted = false;
            this.codeForm.controls['email'].reset({
              value: email,
              disabled: true,
            });
            this.codeForm.controls['code'].setValue('');
            this.snackBar.open(
              'Two factor authentication is activated on your account. Please enter the additional code we sent to you via email.',
              'OK',
              { duration: 30000 },
            );
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
          alert('The provided sign-in information seems to be incorrect.');
        },
      );
    }
  }

  checkCode() {
    this.error_code = false;
    const email = this.codeForm.controls['email'].value.trim();
    const code = this.codeForm.controls['code'].value.trim();
    if (this.codeForm.valid) {
      this.loading = true;
      this.auth.authLogin2fa(email, code).subscribe(
        () => {
          if (this.myurl) {
            this.router.navigateByUrl(decodeURIComponent(this.myurl));
          } else {
            this.router.navigate(['../'], { relativeTo: this.route });
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
          this.getCode = false;
          alert('The provided code seems to be incorrect.');
        },
      );
    }
  }
}
