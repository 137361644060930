import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable, of } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { ParentCompany, SupplyChainData, SupplierInfo } from './network-data.interface';

import { YukkApi } from "../../../service/yukkapi.service";

@Injectable({
  providedIn: "root",
})
export class SupplyChainService {
  private _isEntityName: string = 'vitesco_technologies_group_aktiengesellschaft';
  private supplyChainSubject = new BehaviorSubject<SupplyChainData | null>(null);
  private supplyChain$ = this.supplyChainSubject.asObservable().pipe(shareReplay(1));

  constructor(private yukkApi: YukkApi) {}

  private fetchSupplyChain(): Observable<SupplyChainData> {
    const entity = `company:${this._isEntityName}`;

    // Fetch the parent company information
    const parentCompany$: Observable<ParentCompany> = this.yukkApi.getEntitiesInfo([entity], "array").pipe(
      map((res) => {
        const parent = res[0];
        return {
          entity: {
            type: parent.type,
            alpha_id: parent.alpha_id,
            name: parent.name,
            shortName: parent.name.split(' ')[0],
            compound_key: parent.compound_key,
          },
          num_employees: parent.description?.Num_of_employees || null,
          countries: [parent.description?.Country?.name].filter(Boolean),
          cities: [parent.description?.City?.name].filter(Boolean),
          industries: [parent.description?.Industry?.name].filter(Boolean),
          supersectors: [parent.description?.Supersector?.name].filter(Boolean),
          sectors: [parent.description?.Sector?.name].filter(Boolean),
          subsectors: [parent.description?.Subsector?.name].filter(Boolean),
          persons: [parent.description?.CEO?.name].filter(Boolean),
        };
      })
    );

    // Fetch the suppliers
    const suppliers$: Observable<SupplierInfo[]> = this.yukkApi.getSupplyChainInfo(entity).pipe(
      switchMap((suppliers) => {
        if (suppliers.length > 0) {
          const compoundKeys = suppliers.map(
            (supplier) => supplier.compound_key
          );

          // Fetch detailed entity information for each supplier
          return this.yukkApi.getEntitiesInfo(compoundKeys, "array").pipe(
            map((entities) => {
              const entityDict = entities.reduce((acc, entity) => {
                if (entity?.compound_key) {
                  acc[entity.compound_key] = entity;
                }
                return acc;
              }, {});

              // Map entity info to each supplier
              return suppliers.map((supplier) => {
                const entity = entityDict[supplier.compound_key] || null;
                const supplierInfo: SupplierInfo = {
                  entity: entity
                    ? {
                        type: entity.type,
                        alpha_id: entity.alpha_id,
                        name: entity.name,
                        shortName: entity.name.split(' ')[0],
                        compound_key: entity.compound_key,
                      }
                    : null,
                  num_employees: entity?.description?.Num_of_employees || null,
                  supplied_products: supplier.supplied_products.map(
                    (item) => item.product.alpha_id?.replace('_', ' ')
                  ),
                  countries: [entity?.description?.Country?.name].filter(Boolean),
                  cities: [entity?.description?.City?.name].filter(Boolean),
                  industries: [entity?.description?.Industry?.name].filter(Boolean),
                  supersectors: [entity?.description?.Supersector?.name].filter(Boolean),
                  sectors: [entity?.description?.Sector?.name].filter(Boolean),
                  subsectors: [entity?.description?.Subsector?.name].filter(Boolean),
                  persons: [entity?.description?.CEO?.name].filter(Boolean),
                };
                return supplierInfo;
              });
            })
          );
        } else {
          return of([]);
        }
      })
    );

    // Combine both observables and emit the result
    return forkJoin([parentCompany$, suppliers$]).pipe(
      map(([motherCompany, suppliers]) => {
        const supplyChainData: SupplyChainData = {
          motherCompany,
          suppliers,
        };
        this.supplyChainSubject.next(supplyChainData);
        return supplyChainData;
      }),
      shareReplay(1)
    );
  }

  // Get supply chain data, fetching it if not already available
  getSupplyChain(): Observable<SupplyChainData | null> {
    if (!this.supplyChainSubject.value) {
      return this.fetchSupplyChain();
    }
    return this.supplyChain$;
  }
}
