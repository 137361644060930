import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';

/**
 * this is the gallery where we can display and test all the cockpit components
 */
@Component({
  selector: 'app-widget-gallery',
  templateUrl: './widget-gallery.component.html',
  styleUrls: ['./widget-gallery.component.scss']
})
export class WidgetGalleryComponent {

  /**
   *
   */
  iwidth = 500;

  /**
   *
   */
  iheight = 500;

  /**
   *
   */
  params: any;

  /**
   *
   */
  iframes: any;

  /**
   *
   */
  theme: string;

  /**
   *
   */
  style = [];

  /**
   *
   */
  widgets2 = [
    'newsfeed',
    'treemap',
    'chart',
    'benchmark',
    'company',
    // 'radar',
    // 'network',
    // 'topicloud',
    'portfolio',
    'query',
    'trending',
    'event'
  ];

  /**
   *
   */
  widget = [];

  /**
   *
   */
  isrc = false;

  /**
   *
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routing: RoutingService,
  ) {

    this.route.queryParams.subscribe(params => {
      this.params = params;
      if (params.widget) {
        this.widget = params.widget.toString().split(',');
      }
      if (params.style) {
        this.style = params.style.toString().split(',');
      }
      if (params.width) {
        this.iwidth = params.width;
      }
      if (params.height) {
        this.iheight = params.height;
      }
      this.inInit();
    });
  }

  /**
   *
   */
  inInit() {
    this.iframes = [];
    this.widget.forEach(widget => {
      let urlWidget = widget;
      let url = '';
      let params = '?type=company&id=tesla_motors&time=7';
      if (widget === 'secsignal') {
        params = '?id=stoxx_europe_600_auto_parts_trn';
      }
      if (widget === 'indicator') {
        params = '?id=dax';
      }
      if (widget === 'radar') {
        params = '?type=subsector&id=automobiles&time=7';
      }
      if (widget === 'portfolio') {
        params = '?';
      }
      if (widget === 'benchmark') {
        params = '?';
        urlWidget = 'portfolio/chart2';
      }
      if (window.location.hostname === 'localhost') {
        url = '//localhost:4200/widget/' + urlWidget + params;
      } else {
        url = '//' + window.location.hostname + '/widget/' + urlWidget + params;
      }
      if (this.params.theme) {
        url = url + '&theme=' + this.params.theme;
        this.theme = this.params.theme;
      }
      if (this.style.includes('nomenu')) { url = url + '&nomenu=true'; }
      if (this.style.includes('notitle')) { url = url + '&notitle=true'; }
      if (this.style.includes('nocount')) { url = url + '&nocount=true'; }
      if (this.style.includes('nomap')) { url = url + '&nomap=true'; }

      this.iframes.push(url);
    });
  }

  /**
   *
   */
  inTheme() {
    this.router.navigate([], {
      queryParams: {
        theme: this.theme,
      }, queryParamsHandling: 'merge', replaceUrl: false
    });
  }

  /**
   *
   */
  inStyle() {
    let style = this.style.join(',');
    if (this.style.length == 0) { style = null; }
    this.router.navigate([], {
      queryParams: { style: style, },
      queryParamsHandling: 'merge',
      replaceUrl: false
    });
  }

  /**
   *
   */
  inWidget() {
    this.router.navigate([], {
      queryParams: {
        widget: this.widget.join(',')
      }, queryParamsHandling: 'merge'
    });
  }

  /**
   *
   */
  inSize() {
    this.router.navigate([], {
      queryParams: {
        width: this.iwidth,
        height: this.iheight
      }, queryParamsHandling: 'merge'
    });
  }

}
