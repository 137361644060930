<div class="messages__textbox">
  <div
    class="message__messages"
    *ngIf="joinedMessages"
    [innerHTML]="joinedMessages"
  ></div>
  <div *ngIf="factsWithSources?.length > 0">
    <ng-container *ngIf="isFactsHtmlFormatted">
      <div class="messages__facts">
        <div class="fact__text" #factsContainer>
          <div *ngIf="hasFacts()" [innerHTML]="sanitizedFactsHtml"></div>
        </div>
      </div>
    </ng-container>

    <!-- Non-HTML formatted facts with sources -->
    <ng-container *ngIf="!isFactsHtmlFormatted">
      <div class="messages__facts" *ngFor="let item of factsWithSources">
        <span class="fact__text" [innerHTML]="formatFact(item.fact)"></span>
        <div class="citation__container" *ngFor="let doc of item.source_docs">
          <div
            id="chat-article-citation"
            *ngIf="doc.citation"
            class="citation-item"
            (click)="onCitationClick(doc)"
            (mouseenter)="onCitationMouseEnter($event, doc)"
            (mouseleave)="onCitationMouseLeave()"
          >
            <span>{{ doc.citation }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="hoveredDoc"
    [ngStyle]="{
      position: 'fixed',
      'left.px': tooltipPosition?.left,
      'top.px': tooltipPosition?.top,
    }"
    [attr.data-doc-id]="currentHoveredDocId"
  >
    <app-citation-tooltip [sourceDoc]="hoveredDoc"></app-citation-tooltip>
  </div>
</div>
