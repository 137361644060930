/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Component,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, startWith } from 'rxjs/operators';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { v1 as uuid } from 'uuid';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-scorelab-setup',
  templateUrl: './scorelab-setup.component.html',
  styleUrls: ['./scorelab-setup.component.scss'],
})
export class ScorelabSetupComponent implements OnInit {
  @ViewChild('eventsContainer') eventsContainer: ElementRef;
  @ViewChildren('inputScoreEvent') scoreEventFields: QueryList<ElementRef>;

  isButtonActive = false;

  params: any;

  currentScore: any;
  currentScoreType = '';
  customScores = [];
  subEvents = {};
  subEventsIds = {};
  eventsList: any;

  notitle = false;

  eventsForms = [];
  eventsGroupsFiltered$: Observable<any>[] = [];
  eventsGroupsOptions = [];

  weightOptions = [1, 2, 3, 4, 5];
  polarityOptions = [
    { label: 'Positive', value: 1 },
    { label: 'Negative', value: -1 },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public routing: RoutingService,
    public auth: AuthService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.params = JSON.parse(JSON.stringify(params));
    });
  }

  ngOnInit() {
    this.eventsList = JSON.parse(JSON.stringify(this.auth.eventsList));
    Object.entries(this.eventsList.subEvents).forEach(([, value]) => {
      // @ts-expect-error
      value.forEach((item) => {
        this.subEvents[item.value] = item.label;
        this.subEventsIds[item.label] = item.value;
      });
    });
    this.eventsGroupsOptions = this.eventsList.events.map((el) => {
      const events = this.eventsList.subEvents[el.value].map((item) => {
        return item.label;
      });
      return {
        label: el.label,
        events: events,
      };
    });
    this.initialize();
  }

  initialize() {
    this.notitle = false;
    if (this.auth.scorelabSettings.customScores) {
      this.customScores = JSON.parse(
        JSON.stringify(this.auth.scorelabSettings.customScores),
      );
    }
    if (this.auth.scorelabSettings.defaultScore === 'sentiment') {
      this.currentScore = {
        name: 'Sentiment',
        uid: 'sentiment',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: null,
      };
      this.currentScoreType = 'yukka';
    } else if (this.auth.scorelabSettings.defaultScore === 'general_risk') {
      this.currentScore = {
        name: 'General Risk',
        uid: 'general_risk',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: null,
      };
      this.currentScoreType = 'yukka';
    } else if (this.auth.scorelabSettings.defaultScore === 'credit_risk') {
      this.currentScore = {
        name: 'Credit Risk',
        uid: 'credit_risk',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: null,
      };
      this.currentScoreType = 'yukka';
    } else if (this.auth.scorelabSettings.defaultScore === 'esg_risk') {
      this.currentScore = {
        name: 'ESG Risk',
        uid: 'esg_risk',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: null,
      };
      this.currentScoreType = 'yukka';
    } else if (this.auth.scorelabSettings.defaultScore === 'immediate_risk') {
      this.currentScore = {
        name: 'Immediate Risk',
        uid: 'immediate_risk',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: null,
      };
      this.currentScoreType = 'yukka';
    } else if (this.auth.scorelabSettings.defaultScore === 'esg') {
      this.currentScore = {
        name: 'ESG',
        uid: 'esg',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: 0,
      };
      this.currentScoreType = 'yukka';
    } else if (this.auth.scorelabSettings.defaultScore === 'eb_sim') {
      this.currentScore = {
        name: 'EB-SIM',
        uid: 'eb_sim',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: 0,
      };
      this.currentScoreType = 'yukka';
    } else if (this.auth.scorelabSettings.defaultScore === 'eb_sim_risk') {
      this.currentScore = {
        name: 'EB-SIM Risk',
        uid: 'eb_sim_risk',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: 0,
      };
      this.currentScoreType = 'yukka';
    } else if (this.auth.scorelabSettings.defaultScore === 'bb') {
      this.currentScore = {
        name: 'Bull-Bear Daily',
        uid: 'bb',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: null,
      };
      this.currentScoreType = 'yukka';
    } else if (this.auth.scorelabSettings.defaultScore) {
      this.currentScore = this.customScores.filter((score) => {
        return score.uid === this.auth.scorelabSettings.defaultScore;
      })[0];
      this.currentScoreType = 'custom';
      this.updateEventsControls();
    }
  }

  updateEventsControls() {
    this.eventsForms = [];
    this.eventsGroupsFiltered$ = [];
    const currentScoreCopy = JSON.parse(JSON.stringify(this.currentScore));
    currentScoreCopy.event_weights.forEach((eventObj) => {
      const eventsFormBuilder = this.formBuilder.group({
        eventsFormGroup: [
          this.subEvents[eventObj.event_id],
          Validators.compose([
            Validators.required,
            (c) => this.eventsNameValidator(c),
          ]),
        ],
      });
      this.eventsForms.push(eventsFormBuilder);
    });
    this.eventsGroupsFiltered$.length = currentScoreCopy.event_weights.length;
    this.eventsForms.forEach((eventsForm, index) => {
      this.eventsGroupsFiltered$[index] = this.eventsForms[index]
        .get('eventsFormGroup')!
        .valueChanges.pipe(
          tap((value) => {
            const property = '' + value;
            if (
              Object.prototype.hasOwnProperty.call(this.subEventsIds, property)
            ) {
              this.currentScore.event_weights[index].event_id =
                this.subEventsIds[property];
            } else {
              this.currentScore.event_weights[index].event_id = undefined;
            }
          }),
          startWith(
            this.subEvents[currentScoreCopy.event_weights[index].event_id],
          ),
          map((value) => this.groupMatches(value)),
        );
    });
  }

  /**
   * validate event name if corresponding id exists
   */
  eventsNameValidator(control) {
    return !Object.prototype.hasOwnProperty.call(
      this.subEventsIds,
      control.value,
    )
      ? { invalidEventName: true }
      : null;
  }

  /**
   * returns matching items
   */
  elementMatches(options, value) {
    const filterValue = value.toLowerCase();
    return options.filter(
      (item) => item.toLowerCase().indexOf(filterValue) === 0,
    );
  }

  /**
   * returns matching group's items
   */
  groupMatches(value) {
    if (value) {
      return this.eventsGroupsOptions
        .map((group) => ({
          label: group.label,
          events: this.elementMatches(group.events, value),
        }))
        .filter((group) => group.events.length > 0);
    }
    return this.eventsGroupsOptions;
  }

  selectScore(value) {
    this.notitle = false;
    if (value === 'sentiment') {
      this.currentScore = {
        name: 'Sentiment',
        uid: 'sentiment',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: null,
      };
      this.currentScoreType = 'yukka';
    } else if (value === 'general_risk') {
      this.currentScore = {
        name: 'General Risk',
        uid: 'general_risk',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: null,
      };
      this.currentScoreType = 'yukka';
    } else if (value === 'credit_risk') {
      this.currentScore = {
        name: 'Credit Risk',
        uid: 'credit_risk',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: null,
      };
      this.currentScoreType = 'yukka';
    } else if (value === 'esg_risk') {
      this.currentScore = {
        name: 'ESG Risk',
        uid: 'esg_risk',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: null,
      };
      this.currentScoreType = 'yukka';
    } else if (value === 'immediate_risk') {
      this.currentScore = {
        name: 'Immediate Risk',
        uid: 'immediate_risk',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: null,
      };
      this.currentScoreType = 'yukka';
    } else if (value === 'esg') {
      this.currentScore = {
        name: 'ESG',
        uid: 'esg',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: 0,
      };
      this.currentScoreType = 'yukka';
    } else if (value === 'eb_sim') {
      this.currentScore = {
        name: 'EB-SIM',
        uid: 'eb_sim',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: 0,
      };
      this.currentScoreType = 'yukka';
    } else if (value === 'eb_sim_risk') {
      this.currentScore = {
        name: 'EB-SIM Risk',
        uid: 'eb_sim_risk',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: 0,
      };
      this.currentScoreType = 'yukka';
    } else if (value === 'bb') {
      this.currentScore = {
        name: 'Bull-Bear',
        uid: 'bb',
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: null,
      };
      this.currentScoreType = 'yukka';
    } else {
      this.currentScore = this.customScores.filter((score) => {
        return score.uid === value;
      })[0];
      this.currentScoreType = 'custom';
      this.updateEventsControls();
    }
  }

  setAsDefault(newDefaultId) {
    this.selectScore(newDefaultId);
    this.auth.scorelabSettings.defaultScore = newDefaultId;
    const newDefaultName = this.auth.getScoreName().full;
    const newCustomSettings = Object.assign({}, this.auth.cockpitSettings, {
      user_defaultScore: newDefaultId,
    });
    this.auth.cockpitSettings = newCustomSettings;
    this.auth.setCustomSettings(newCustomSettings).subscribe(() => {
      this.snackBar.open(
        'Default score set to: ' + newDefaultName + '.',
        'OK',
        { duration: 5000 },
      );
    });
    if (
      this.auth.scorelabSettings.defaultScore === 'sentiment' &&
      this.params.sort
    ) {
      this.router.navigate([], {
        queryParams: {
          update: Math.random(),
          sort: '-senti',
          focused_score: this.auth.scorelabSettings.defaultScore,
          score: null,
          time: this.params.score ? null : this.params.time,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    } else if (this.params.sort) {
      this.router.navigate([], {
        queryParams: {
          update: Math.random(),
          sort: 'score',
          focused_score: this.auth.scorelabSettings.defaultScore,
          score: null,
          time: this.params.score ? null : this.params.time,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    } else {
      this.router.navigate([], {
        queryParams: {
          update: Math.random(),
          focused_score: this.auth.scorelabSettings.defaultScore,
          score: null,
          time: this.params.score ? null : this.params.time,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
  }

  createScore() {
    this.notitle = false;
    this.currentScoreType = 'new';
    this.currentScore = {
      name: '',
      uid: uuid(),
      event_weights: [
        {
          event_id: undefined,
          weight: 5,
          polarity: 1,
        },
      ],
    };
    this.updateEventsControls();
  }

  isValidScore(score) {
    let isValid = true;
    if (!score.name.trim()) {
      this.notitle = true;
      isValid = false;
    }
    if (!score.event_weights) {
      isValid = false;
    } else {
      const checkEl = (el) =>
        !el.event_id || el.event_id === undefined || !el.polarity || !el.weight;
      score.event_weights.forEach((el, index) => {
        if (checkEl(el)) {
          isValid = false;
          if (this.scoreEventFields) {
            this.scoreEventFields.toArray()[index].nativeElement.focus();
          }
        }
      });
    }
    return isValid;
  }

  saveScore() {
    if (this.currentScoreType === 'custom') {
      if (this.isValidScore(this.currentScore)) {
        let sentimentType = 'neg';
        this.currentScore.sentiment_weight = 0;
        this.currentScore.event_weights.forEach((item, index) => {
          if (item.polarity === 1) {
            sentimentType = 'pos';
          }
          this.currentScore.event_weights[index]['weight_persistence'] = 90;
        });
        this.currentScore.sentiment_type = sentimentType;
        this.auth.scorelabSettings.customScores = JSON.parse(
          JSON.stringify(this.customScores),
        );
        const newCustomSettings = Object.assign({}, this.auth.cockpitSettings, {
          user_customScores: this.customScores,
        });
        this.auth.cockpitSettings = newCustomSettings;
        this.auth.setCustomSettings(newCustomSettings).subscribe(() => {
          this.snackBar.open('Custom score saved.', 'OK', { duration: 5000 });
        });
        if (this.currentScore.uid === this.auth.scorelabSettings.defaultScore) {
          this.router.navigate([], {
            queryParams: {
              update: Math.random(),
              focused_score: this.auth.scorelabSettings.defaultScore,
              score: null,
              time: this.params.score ? null : this.params.time,
            },
            queryParamsHandling: 'merge',
            replaceUrl: true,
          });
        } else {
          this.router.navigate([], {
            queryParams: {
              updateScoresOnSave: Math.random(),
              focused_score: this.auth.scorelabSettings.defaultScore,
              score: null,
              time: this.params.score ? null : this.params.time,
            },
            queryParamsHandling: 'merge',
            replaceUrl: true,
          });
        }
      } else {
        alert('Error: all of the required fields must be valid.');
      }
    } else if (this.currentScoreType === 'new') {
      this.notitle = false;
      if (this.isValidScore(this.currentScore)) {
        let sentimentType = 'neg';
        this.currentScore.sentiment_weight = 0;
        this.currentScore.event_weights.forEach((item, index) => {
          if (item.polarity === 1) {
            sentimentType = 'pos';
          }
          this.currentScore.event_weights[index]['weight_persistence'] = 90;
        });
        this.currentScore.sentiment_type = sentimentType;
        this.customScores.push(this.currentScore);
        this.auth.scorelabSettings.customScores = JSON.parse(
          JSON.stringify(this.customScores),
        );
        const newCustomSettings = Object.assign({}, this.auth.cockpitSettings, {
          user_customScores: this.customScores,
        });
        this.auth.cockpitSettings = newCustomSettings;
        this.auth.setCustomSettings(newCustomSettings).subscribe(() => {
          this.snackBar.open('Custom score created.', 'OK', { duration: 5000 });
          this.selectScore(this.currentScore.uid);
        });
        this.router.navigate([], {
          queryParams: {
            updateScores: Math.random(),
            focused_score: this.auth.scorelabSettings.defaultScore,
            score: null,
            time: this.params.score ? null : this.params.time,
          },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      } else {
        alert('Error: all of the required fields must be valid.');
      }
    }
  }

  deleteScore() {
    if (this.auth.scorelabSettings.defaultScore === this.currentScore.uid) {
      alert(
        'Please select another score as default before deleting tool`s current default one.',
      );
    } else {
      this.customScores = this.customScores.filter((score) => {
        return score.uid !== this.currentScore.uid;
      });
      this.auth.scorelabSettings.customScores = JSON.parse(
        JSON.stringify(this.customScores),
      );
      const newCustomSettings = Object.assign({}, this.auth.cockpitSettings, {
        user_customScores: this.customScores,
      });
      this.auth.cockpitSettings = newCustomSettings;
      const currentScoreCopy = this.currentScore.uid;
      this.auth.setCustomSettings(newCustomSettings).subscribe(() => {
        this.snackBar.open('Custom score removed.', 'OK', { duration: 5000 });
        this.auth.getUserSettings('scannerColumns').subscribe((result) => {
          if (result.entry) {
            let resultObj = JSON.parse(result.entry);
            resultObj = resultObj.filter((item) => {
              return (
                item !== currentScoreCopy &&
                item !== currentScoreCopy + '_delta'
              );
            });
            this.auth
              .setUserSettings('scannerColumns', JSON.stringify(resultObj))
              .subscribe(() => {
                this.router.navigate([], {
                  queryParams: {
                    updateScores: Math.random(),
                    focused_score: this.auth.scorelabSettings.defaultScore,
                    score: null,
                    time: this.params.score ? null : this.params.time,
                  },
                  queryParamsHandling: 'merge',
                  replaceUrl: true,
                });
              });
          }
        });
      });
      this.initialize();
    }
  }

  addEvent() {
    this.currentScore.event_weights.push({
      event_id: undefined,
      weight: 5,
      polarity: 1,
    });
    this.updateEventsControls();
    setTimeout(() => {
      this.scrollToBottom();
    }, 0);
  }

  removeEvent(index) {
    this.currentScore.event_weights.splice(index, 1);
    this.updateEventsControls();
  }

  scrollToBottom() {
    this.eventsContainer.nativeElement.scrollTop =
      this.eventsContainer.nativeElement.scrollHeight;
  }

  iClose() {
    this.dialog.closeAll();
  }
}
