import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../service/auth.service';
import { RoutingService } from '../../../service/routing.service';
import { ConfigService } from '../../../service/config.service';

/**
* This dialog display different error message.
*/

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['../login/login.component.scss', './message.component.scss']
})
export class MessageComponent {

  /**
  * message id
  */
  message: string;

  /**
   * message to show on error
   */
  errorMessage: string;

  /**
  * project of the enviroment
  */
  project: string;

  /**
   * logo of the project
   */
  logo: string;

  /**
  * constructor
  */
  constructor(
    /**
    * ActivatedRoute
    */
    private route: ActivatedRoute,
    /**
    * AuthService
    */
    private auth: AuthService,
    /**
     * RoutingService
     */
    private routing: RoutingService,
    /**
     * ConfigService
     */
    private config: ConfigService
  ) {
    this.project = this.config.appConfig.routing.project;
    this.route.params.subscribe(params => {
      if (params.id) { this.message = params.id; }
      if (window.location.hostname === 'swissre.yukkalab.com') {
        this.logo = 'swissre';
      } else {
        this.logo = this.project;
      }
      if (this.message === 'logout') { this.auth.authLogout(); }
    });
    this.route.queryParams.subscribe(params => {
      if (params.error_message) {
        this.errorMessage = params.error_message;
      }
    });
  }

}
