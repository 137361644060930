import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoutingService } from './service/routing.service';
import { ConfigService } from './service/config.service';
import { TranslateService } from '@ngx-translate/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { ConnectionService } from 'ng-connection-service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  theme: string;
  isOnline: boolean;
  baseUrl: string;

  constructor(
    private route: ActivatedRoute,
    public routing: RoutingService,
    private translate: TranslateService,
    private updates: SwUpdate,
    private connectionService: ConnectionService,
    public dialog: MatDialog,
    public config: ConfigService,
  ) {
    // if (
    //   this.config.appConfig.production === true ||
    //   this.config.appConfig.production === 'true'
    // ) {
    //
    // }

    // this.connectionService.monitor().subscribe(isConnected => {
    //   if (isConnected) {
    //     window.location.reload();
    //   } else {
    //     this.dialog.open(InternetComponent, {
    //       autoFocus: false,
    //       disableClose: true
    //     });
    //   }
    // });

    // this.updates.available.subscribe(() => {
    //   updates.activateUpdate().then(() => document.location.reload());
    // });

    this.updates.versionUpdates.subscribe((event: VersionEvent) => {
      if (event.type === 'VERSION_READY') {
        this.updates.activateUpdate().then(() => document.location.reload());
      }
    });

    translate.setDefaultLang('en');

    routing.getTitle();

    if (this.config.appConfig.setup.useStorage) {
      if (localStorage.getItem('theme')) {
        this.theme = localStorage.getItem('theme');
      } else {
        this.theme = this.config.appConfig.routing.theme;
      }
    } else {
      this.theme = this.config.appConfig.routing.theme;
    }

    if (this.theme === 'yukka') {
      this.theme = 'dark';
    }

    this.route.queryParams.subscribe((params) => {
      if (this.config.appConfig.setup.useStorage) {
        if (localStorage.getItem('theme')) {
          this.theme = localStorage.getItem('theme');
        } else {
          this.theme = this.config.appConfig.routing.theme;
        }
      } else {
        this.theme = this.config.appConfig.routing.theme;
      }

      if (params.theme) {
        this.theme = params.theme;
      }

      if (this.theme === 'yukka') {
        this.theme = 'dark';
      }

      this.routing.inTheme(this.theme);
      if (params.i18n) {
        this.translate.use(params.i18n);
      }
    });
  }
}
