import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/service/config.service';
import { AuthService } from 'src/app/service/auth.service';
import { RoutingService } from 'src/app/service/routing.service';

/**
 * This component need to be fixed.
 */

@Component({
  // encapsulation: ViewEncapsulation.None,
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['../login/login.component.scss', './password.component.scss']
})
export class PasswordComponent {

  nouser: boolean;
  loading: boolean;
  params: any;
  queryParams: any;
  project: string;
  logo: string;
  formEmail: UntypedFormGroup;
  formPassword: UntypedFormGroup;

  hideOld = true;
  hideNew = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    public routing: RoutingService,
    public config: ConfigService,
    public snackBar: MatSnackBar,
  ) {

    this.project = this.config.appConfig.routing.project;

    this.formEmail = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email])
    });

    this.formPassword = new UntypedFormGroup({
      password1: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
      password2: new UntypedFormControl('', [Validators.required, Validators.pattern('((?=.*\\d)(?=.*\\W)(?=.*[a-z])(?=.*[A-Z]).{8,30})')]),
    });

    this.route.params.subscribe(params => {
      this.params = params;
      if (window.location.hostname === 'swissre.yukkalab.com') {
        this.logo = 'swissre';
      } else {
        this.logo = this.project;
      }
    });

    this.route.queryParams.subscribe(params => {
      this.queryParams = params;
    });

  }

  sendEmail(event) {
    this.nouser = false;
    const email = this.formEmail.controls['email'].value;
    const url = window.location.origin + '/password/reset';
    if (this.formEmail.valid) {
      this.loading = true;
      this.auth.requestPasswordReset(email, url).subscribe(resp => {
        this.router.navigate(['/message/password']);
      }, error => {
        this.loading = false;
        this.nouser = true;
      });
    }
  }

  setPassword(event) {
    if (this.formPassword.valid) {
      if (this.params.id === 'reset') {
        const valid = (this.formPassword.controls['password1'].value === this.formPassword.controls['password2'].value);
        if (valid) {
          if (this.queryParams.token && this.queryParams.email) {
            this.loading = true;
            this.auth.resetPassword(this.queryParams.email, this.queryParams.token, this.formPassword.controls['password1'].value)
              .subscribe(risp => {
                this.router.navigate(['/login']);
                this.snackBar.open('Your password has been changed successfully. Please log in to continue.', 'OK', { duration: 5000 });
              }, error => {
                const errorMessage = JSON.parse(error).error;
                this.router.navigate(['/message/errorMessage'], {
                  queryParams: {
                    error_message: errorMessage,
                  }
                });
              });
          } else {
            alert('User email and reset token must be provided in the url.');
          }
        } else {
          alert('Passwords cannot be different.');
        }
      } else {
        // const valid = this.formPassword.controls['password1'].value === this.formPassword.controls['password2'].value
        // if (valid) {
        this.loading = true;
        this.auth.setPassword(this.formPassword.controls['password1'].value, this.formPassword.controls['password2'].value)
          .subscribe(risp => {
            this.router.navigate(['/login']);
            this.snackBar.open('Your password has been changed successfully. Please log in to continue.', 'OK', { duration: 5000 });
          }, error => {
            this.router.navigate(['/password/change']);
            alert('The old password is not correct');
            this.loading = false;
          });
        // } else {
        //   alert('Paswwords cannot be different.')
        // }
      }
    }
  }



}
