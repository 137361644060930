import { Component } from '@angular/core';
import { YukkApi } from '../../../../service/yukkapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
// import { yukka } from '../hierarchy'

@Component({
  selector: 'app-board-entity',
  templateUrl: './board-entity.component.html',
  styleUrls: ['./board-entity.component.scss']
})
export class BoardEntityComponent {

  treemapdata: any;
  loading: boolean;
  myOrder: string;
  myArray: any = [];
  hierarchies: any;
  previousValue: any;
  params: any;

  constructor(
    private yukkApi: YukkApi,
    private router: Router,
    private route: ActivatedRoute,
    public routing: RoutingService,
    public auth: AuthService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.params = params;

      if (params.sort) {
        if (params.sort === 'score') { this.myOrder = '-score'; }
        if (params.sort === '-score') { this.myOrder = 'score'; }
        if (params.sort === 'senti') { this.myOrder = '-score'; }
        if (params.sort === '-senti') { this.myOrder = 'score'; }
        if (params.sort === 'abc') { this.myOrder = 'entity.name'; }
        if (params.sort === '-abc') { this.myOrder = '-entity.name'; }
        if (params.sort === 'trend') { this.myOrder = '-score_delta'; }
        if (params.sort === '-trend') { this.myOrder = 'score_delta'; }
      } else {
        this.myOrder = '-trend';
      }

      if ((params.id && params.type && ( params.type !== 'event' ) ) || params.portfolio) {
        if (routing.reFresh(params, this.previousValue, ['type', 'id', 'time', 'lang', 'feed', 'categories', 'continents', 'countries', 'ranks', 'panels', 'update'])) {
          this.treemapdata = [];
          this.loading = true;
          this.yukkApi.treemap(params).subscribe(result => {
            this.treemapdata = result.treemap.children;
            this.loading = false;
          });
        }
        this.previousValue = params;
      }
    });
  }

  goMap(entity) {
    if (entity.type !== 'index') {
      let path = ['../chart'];
      if (this.routing.isIframe()) { path = []; }
      this.router.navigate(path, {
        relativeTo: this.route, queryParams: {
          type: entity.type,
          id: entity.alpha_id,
          tag: null,
        },
        queryParamsHandling: 'merge'
      });
    }
  }

}
