/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { MatSidenav } from '@angular/material/sidenav';
import { RoutingService } from 'src/app/service/routing.service';
import { ConfigService } from 'src/app/service/config.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-cockpit',
  templateUrl: './cockpit.component.html',
  styleUrls: ['./cockpit.component.scss'],
})
export class CockpitComponent {
  params: any;
  impersonate: any;
  status: any;
  statusObject: any;
  @ViewChild('sidenav') public sidenav: MatSidenav;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    public routing: RoutingService,
    public config: ConfigService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.params = params;
    });

    this.impersonate = false;

    if (this.config.appConfig.setup.useStorage) {
      if (
        this.params.impersonate ||
        (localStorage.getItem('impersonate') !== undefined &&
          localStorage.getItem('impersonate') === this.auth.userdata?.email)
      ) {
        this.impersonate = true;
      }
    }

    this.auth.getBackendStatus().subscribe((result) => {
      this.statusObject = result;
      // this.statusObject = {
      //   'uid': '6f41e479-1b79-4ed4-bcab-f23e48bb4436',
      //   'message': 'Testmessage2',
      //   'background-colour': '#ffc83d',
      //   'created_on': '2020-06-25T14:13:31.777Z'
      // };
      if (Object.prototype.hasOwnProperty.call(this.statusObject, 'message')) {
        this.status = true;
      } else {
        this.status = false;
      }
    });

    if (this.config.appConfig.setup.useStorage) {
      // if (!localStorage.getItem('news_curation')) {
      //   this.auth.getStars().subscribe(result => {
      //     localStorage.setItem('news_curation', result);
      //   });
      // }
      this.auth.getStars().subscribe((result) => {
        localStorage.setItem('news_curation', result);
      });
    }

    document.addEventListener('fullscreenchange', () => {
      if (!document.fullscreen || !(document.fullscreenElement !== null)) {
        router.navigate([], {
          queryParams: { fullscreen: null },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      } else {
        router.navigate([], {
          queryParams: { fullscreen: true },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      }
    });
  }

  closeStatus() {
    this.status = false;
  }

  closeSidenav() {
    // this.router.navigate([], { queryParams: { sidenav: null }, queryParamsHandling: 'merge', replaceUrl: true })
  }
}
