import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { AIMessage } from '../interfaces/chart-details.interface';
import * as moment from 'moment';
import getScoreTypeDetails from './chart-score-names';
import { DatePipe } from '@angular/common';

interface RecommendedChart {
  chart_type: string;
  entities: string | null;
  score_type: string | null;
  date_from: string | null;
  date_to: string | null;
}

@Component({
  selector: 'app-chat-charts',
  templateUrl: './chat-charts.component.html',
  styleUrls: ['./chat-charts.component.scss'],
  providers: [DatePipe],
})
export class ChatChartsComponent implements OnInit {
  constructor(private datePipe: DatePipe) {}
  @Input() childChat: AIMessage;
  @Input() isVisible = true;
  @Output() visibilityChange = new EventEmitter<boolean>();

  public thisIsVisible: boolean = false;

  chat: AIMessage;
  showRanking = false;
  showTrend = false;
  showEvents = false;
  showBenchmark = false;

  rankingTimeframe: string | null = '7';
  trendTimeframe: string | null = '7';
  eventsTimeframe: string | null = '7';

  chartHeaderCompany: string | null = null;
  scoreTypeOptions: { value: string; label: string }[] = [];
  selectedScoreType: string | null = null;
  chartTimeFrame: string | null;

  ngOnInit(): void {
    this.chat = this.childChat;
    this.processRecommendedCharts();
  }

  changeScoreType(scoreType: string): void {
    this.selectedScoreType = scoreType;
  }

  closeCharts(): void {
    this.visibilityChange.emit(false);
  }

  openCharts(): void {
    this.visibilityChange.emit(true);
  }

  get showChartsContainer(): boolean {
    return (
      this.showRanking ||
      this.showTrend ||
      this.showEvents ||
      this.showBenchmark
    );
  }
  markFieldAsSeen(event): void {
    if (this.thisIsVisible === false && event === true) {
      this.thisIsVisible = true;
    }
  }

  generateChatHeader(currentScore): string {
    if (this.showRanking) {
      return `${getScoreTypeDetails(currentScore).label} Ranking`;
    } else {
      return `${getScoreTypeDetails(currentScore).label} Analysis`;
    }
  }

  generateChartTimeFrame(displayedCharts: RecommendedChart[]): void {
    const filteredDisplayCharts = displayedCharts.filter(
      (chart) => chart.score_type === this.selectedScoreType,
    );
    const minDateTo = this.adjustDateByDays(filteredDisplayCharts[0].date_to);
    const maxDateFrom = filteredDisplayCharts[0].date_from;

    const formattedMinDateTo = this.datePipe.transform(minDateTo, 'MMM d, y');
    const formattedMaxDateFrom = this.datePipe.transform(
      maxDateFrom,
      'MMM d, y',
    );

    this.chartTimeFrame = `${formattedMaxDateFrom} - ${formattedMinDateTo}`;
  }

  private processRecommendedCharts(): void {
    if (
      this.chat?.recommended_charts === undefined ||
      !Array.isArray(this.chat?.recommended_charts)
    ) {
      console.log('Recommended charts data is invalid');
      return;
    }
    const recommendedCharts = this.chat
      .recommended_charts as RecommendedChart[];

    const rankingCharts = this.filterChartsByType(recommendedCharts, 'Ranking');
    if (rankingCharts.length > 0 && this.chat.requested_entities.length > 1) {
      this.displayRankingChart(rankingCharts);
    } else {
      const filteredCharts = recommendedCharts.filter(
        (chart) => chart.chart_type !== 'Ranking',
      );
      this.displayNonRankingCharts(filteredCharts);
    }
  }

  private displayRankingChart(rankingCharts: RecommendedChart[]): void {
    this.getChartScores(rankingCharts);
    this.generateChartTimeFrame(rankingCharts);
    this.chartHeaderCompany = null;
    this.resetChartVisibility();
    this.showRanking = true;

    this.rankingTimeframe = this.chartTimeLine(
      rankingCharts[0].date_from,
      rankingCharts[0].date_to,
    );
  }

  private displayNonRankingCharts(charts: RecommendedChart[]): void {
    this.resetChartVisibility();
    this.getChartScores(charts);
    this.generateChartTimeFrame(charts);
    this.chartHeaderCompany = this.chat.entityName;
    charts.forEach((chart) => {
      if (!chart.score_type) {
        chart.score_type = 'sentiment';
      }

      switch (chart.chart_type) {
        case 'Trend':
          this.showTrend = true;
          this.trendTimeframe = this.chartTimeLine(
            chart.date_from,
            chart.date_to,
          );
          break;
        case 'Events':
          this.showEvents = true;
          this.eventsTimeframe = this.chartTimeLine(
            chart.date_from,
            chart.date_to,
          );
          break;
        case 'Benchmark':
          this.showBenchmark = true;
          break;
        default:
          break;
      }
    });
  }

  private resetChartVisibility(): void {
    this.showRanking = false;
    this.showTrend = false;
    this.showEvents = false;
    this.showBenchmark = false;
  }

  private filterChartsByType(
    charts: RecommendedChart[],
    type: string,
  ): RecommendedChart[] | undefined {
    return charts.filter((chart) => chart.chart_type === type);
  }

  private getChartScores(charts: RecommendedChart[]): void {
    const scoreTypes: string[] = [];
    charts.map((chart) => {
      if (chart.score_type && !scoreTypes.includes(chart.score_type)) {
        scoreTypes.push(chart.score_type);
      }
    });

    this.scoreTypeOptions = scoreTypes.map((scoreType) => {
      return {
        value: scoreType,
        label: getScoreTypeDetails(scoreType).label,
      };
    });
    this.selectedScoreType = this.scoreTypeOptions[0].value;
  }

  private chartTimeLine(dateFrom, dateTo): string {
    const timeLine =
      dateFrom && dateTo
        ? dateFrom + '--' + this.adjustDateByDays(dateTo)
        : '7';
    return timeLine;
  }

  private adjustDateByDays(date) {
    return moment(date, 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD');
  }
}
