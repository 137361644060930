import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { ConfigService } from '../service/config.service';
import { RoutingService } from 'src/app/service/routing.service';

/**
 * This guard redirects to main cockpit's url based on user trying to access chat llm board/widget
 */
@Injectable()
export class ChatGuard {
  redirected: boolean;

  constructor(
    private auth: AuthService,
    private router: Router,
    private config: ConfigService,
    public routing: RoutingService,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    if (
      this.auth.featureFlags.showChatLLM ||
      this.auth.userdata?.email?.includes('@yukkalab')
    ) {
      if (state.root.queryParams.jwt) {
        this.router.navigate([state.url.split('?')[0]], {
          queryParams: Object.assign({}, state.root.queryParams, {
            jwt: null,
          }),
          queryParamsHandling: 'merge',
        });
      }
      return true;
    } else {
      this.router.navigate(['/cockpit/news/portfolio/scanner']);
    }
  }
}
