<div class="news-chart">
  <app-news-title
    *ngIf="!routing.isWidget() && !routing.isScanner() && !params.custom_score"
    [titolo]="titolo"
  ></app-news-title>
  <div
    class="chart-cont"
    *ngIf="titolo && !routing.isNewsaudit()"
    [class.score-focus]="
      routing.isChart() && (routing.isFolio() || routing.isMarket())
    "
    [class.score-focused]="params?.score"
    [style.border-color]="auth.scoreFocusedColor"
  >
    <div
      class="chart-title"
      *ngIf="!routing.isScanner() && !params.custom_score"
      [class.noback]="true"
      [style.background-color]="'transparent'"
      [style.color]="'transparent'"
    >
      <div class="title">
        <div class="percent" *ngIf="showAverage">
          <form class="inptrage" (ngSubmit)="inAverage()">
            <span>Average 1:</span>
            <input
              matInput
              type="number"
              min="1"
              max="99"
              name="period"
              [(ngModel)]="period1"
              autocomplete="off"
            />
          </form>
          <span class="daysenty"> day Sentiment</span>
        </div>
        <div class="percent" *ngIf="showAverage2">
          <form class="inptrage" (ngSubmit)="inAverage2()">
            <span>Average 2:</span>
            <input
              matInput
              type="number"
              min="1"
              max="99"
              name="period2"
              [(ngModel)]="period2"
              autocomplete="off"
            />
          </form>
          <span class="daysenty"> day Sentiment</span>
        </div>
        <div
          class="submenu"
          *ngIf="!params.custom_score && !routing.isNewsaudit()"
        >
          <mat-icon class="menu" [matMenuTriggerFor]="menuExpo"
            >more_vert</mat-icon
          >
          <mat-menu #menuExpo="matMenu">
            <button mat-menu-item (click)="goInfo()">Legend</button>
            <button mat-menu-item (click)="getAverage()">Average 1</button>
            <button mat-menu-item (click)="getAverage2()">Average 2</button>
            <button mat-menu-item (click)="iAnnotation()">Annotation</button>
            <button mat-menu-item (click)="goFully()">Fixscale</button>
            <button mat-menu-item (click)="takeScreenshot()">Screenshot</button>
            <button mat-menu-item [matMenuTriggerFor]="export">Export</button>
            <mat-menu #export="matMenu">
              <button mat-menu-item [matMenuTriggerFor]="imageFormat">
                Image
              </button>
              <button
                mat-menu-item
                *ngIf="
                  !(customScore === 'sentiment') &&
                  (routing.isFolio() ||
                    (params.type &&
                      (params.type === 'company' ||
                        params.type === 'pne' ||
                        [
                          'industry',
                          'supersector',
                          'sector',
                          'subsector',
                        ].includes(params.type))))
                "
                [matMenuTriggerFor]="scoreFormat"
              >
                Score
              </button>
              <button
                mat-menu-item
                *ngIf="
                  !(
                    !(customScore === 'sentiment') &&
                    (routing.isFolio() ||
                      (params.type &&
                        (params.type === 'company' ||
                          params.type === 'pne' ||
                          [
                            'industry',
                            'supersector',
                            'sector',
                            'subsector',
                          ].includes(params.type))))
                  )
                "
                [matMenuTriggerFor]="sentimentFormat"
              >
                Sentiment
              </button>
              <button mat-menu-item [matMenuTriggerFor]="volumeFormat">
                Volume
              </button>
              <button
                mat-menu-item
                *ngIf="
                  !(customScore === 'sentiment') &&
                  (routing.isFolio() ||
                    (params.type &&
                      (params.type === 'company' ||
                        params.type === 'pne' ||
                        [
                          'industry',
                          'supersector',
                          'sector',
                          'subsector',
                        ].includes(params.type))))
                "
                [matMenuTriggerFor]="scorevolumeFormat"
              >
                Score and Volume
              </button>
              <button
                mat-menu-item
                *ngIf="
                  !(
                    !(customScore === 'sentiment') &&
                    (routing.isFolio() ||
                      (params.type &&
                        (params.type === 'company' ||
                          params.type === 'pne' ||
                          [
                            'industry',
                            'supersector',
                            'sector',
                            'subsector',
                          ].includes(params.type))))
                  )
                "
                [matMenuTriggerFor]="sentimentvolumeFormat"
              >
                Sentiment and Volume
              </button>
              <button
                mat-menu-item
                *ngIf="!fromPortfolio && !fromSearchQuery && !fromNewsAudit"
                [matMenuTriggerFor]="priceFormat"
              >
                Price
              </button>
              <!--<button mat-menu-item (click)="exportEvent('xlsx')" *ngIf="routing.isDev()">Events</button>-->
            </mat-menu>
            <mat-menu #imageFormat="matMenu">
              <button mat-menu-item (click)="imgChart('jpg')">jpg</button>
              <button mat-menu-item (click)="imgChart('png')">png</button>
              <button mat-menu-item (click)="imgChart('svg')">svg</button>
            </mat-menu>
            <mat-menu #sentimentFormat="matMenu">
              <button
                mat-menu-item
                (click)="exportChartData('csv', ['sentiment'])"
              >
                csv
              </button>
              <button
                mat-menu-item
                (click)="exportChartData('xlsx', ['sentiment'])"
              >
                xlsx
              </button>
            </mat-menu>
            <mat-menu #scoreFormat="matMenu">
              <button mat-menu-item (click)="exportChartData('csv', ['score'])">
                csv
              </button>
              <button
                mat-menu-item
                (click)="exportChartData('xlsx', ['score'])"
              >
                xlsx
              </button>
            </mat-menu>
            <mat-menu #volumeFormat="matMenu">
              <button
                mat-menu-item
                (click)="exportChartData('csv', ['volume'])"
              >
                csv
              </button>
              <button
                mat-menu-item
                (click)="exportChartData('xlsx', ['volume'])"
              >
                xlsx
              </button>
            </mat-menu>
            <mat-menu #scorevolumeFormat="matMenu">
              <button
                mat-menu-item
                (click)="exportChartData('csv', ['score', 'volume'])"
              >
                csv
              </button>
              <button
                mat-menu-item
                (click)="exportChartData('xlsx', ['score', 'volume'])"
              >
                xlsx
              </button>
            </mat-menu>
            <mat-menu #sentimentvolumeFormat="matMenu">
              <button
                mat-menu-item
                (click)="exportChartData('csv', ['sentiment', 'volume'])"
              >
                csv
              </button>
              <button
                mat-menu-item
                (click)="exportChartData('xlsx', ['sentiment', 'volume'])"
              >
                xlsx
              </button>
            </mat-menu>
            <mat-menu #priceFormat="matMenu">
              <button mat-menu-item (click)="exportChartData('csv', ['price'])">
                csv
              </button>
              <button
                mat-menu-item
                (click)="exportChartData('xlsx', ['price'])"
              >
                xlsx
              </button>
            </mat-menu>
          </mat-menu>
        </div>
        <div class="submenu" *ngIf="routing.isNewsaudit()">
          <mat-icon class="menu" [matMenuTriggerFor]="menuExpo"
            >more_vert</mat-icon
          >
          <mat-menu #menuExpo="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="export">Export</button>
            <mat-menu #export="matMenu">
              <!--<button mat-menu-item (click)="exportEvent('xlsx')">Events</button>-->
              <button mat-menu-item (click)="exportAudit()">Audits</button>
            </mat-menu>
          </mat-menu>
        </div>
      </div>
    </div>
    <div
      class="chart"
      [class.no-navigator]="routing.isScanner() || params.custom_score"
      *ngIf="!loading && !nodata && !routing.isNewsaudit()"
      [chart]="stockChart"
      [class.score-focus]="
        routing.isChart() && (routing.isFolio() || routing.isMarket())
      "
      [class.score-focused]="params?.score"
    ></div>
    <div
      class="chart-audit"
      *ngIf="!loading && !nodata && routing.isNewsaudit()"
      [chart]="stockChart"
    ></div>
    <div
      *ngIf="
        !params.custom_score && !routing.isScanner() && !params.custom_score
      "
      class="izoom"
      [class.score-focus]="
        routing.isChart() && (routing.isFolio() || routing.isMarket())
      "
      [class.score-focused]="params?.score"
    >
      <div [chart]="izoom"></div>
    </div>
  </div>
  <div class="chart-load" *ngIf="loading && !routing.isNewsaudit()">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <div class="nodata" *ngIf="nodata && !routing.isNewsaudit()">
    NO DATA AVAILABLE
  </div>
</div>
