import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import { YukkApi } from '../service/yukkapi.service';
import { ConfigService } from 'src/app/service/config.service';

/**
 * redirect to specific portfolio if no one is selected and store the data in the auth service
 */

@Injectable()
export class HomeGuard  {
  constructor(
    private auth: AuthService,
    private yukkapi: YukkApi,
    public config: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (/*(this.config.appConfig.routing.project !== 'dev') && */(this.config.appConfig.routing.project !== 'scorelab')) {
      this.router.navigate(['/cockpit/news/portfolio'], {
        replaceUrl: true
      });
    } else if (/*(this.config.appConfig.routing.project !== 'dev') && */(this.config.appConfig.routing.project === 'scorelab')) {
      this.router.navigate(['/cockpit/news/portfolio/scanner'], {
        replaceUrl: true
      });
    } else {
      return this.auth.portFolios().pipe(map(folios => {
        this.auth.folios = [];
        this.auth.folio = undefined;
        if (folios[0]) {
          this.auth.folio = null;
          this.auth.folios = folios;
          this.yukkapi.isentimentArray(Object.assign({}, state.root.queryParams, {
            fromHomeGuard: true
          }), true).subscribe(result => {
            const portfolios = folios.map((portfolio, index) => {
              return Object.assign({}, portfolio, {sentiment: result[index].sentiment});
            });
            const sortedFolios = JSON.parse(JSON.stringify(portfolios));
            sortedFolios.sort((a, b) => {
              return a.sentiment.sentiment - b.sentiment.sentiment;
            });
            this.auth.folios = sortedFolios;
            if (!state.root.queryParams.id) {
              if (this.auth.customDefaultPortfolio) {
                this.auth.folio = sortedFolios.filter(item => {
                  return (item.uid === this.auth.customDefaultPortfolio);
                })[0];
              } else {
                this.auth.folio = sortedFolios[0];
              }
              this.router.navigate([state.url.split('?')[0]], {
                queryParams: {
                  id: this.auth.folio.uid,
                  news: state.root.queryParams.news,
                  highlight: state.root.queryParams.highlight,
                  time: state.root.queryParams.time,
                  filter: state.root.queryParams.filter,
                  lang: state.root.queryParams.lang ? state.root.queryParams.lang : 'en,de',
                  feed: state.root.queryParams.feed ? state.root.queryParams.feed : 'non_professional,online,print,professional',
                  ranks: state.root.queryParams.ranks,
                  panels: state.root.queryParams.panels,
                  // categories: state.root.queryParams.categories ? state.root.queryParams.categories : 'gent,gedu,ciprof,glife,cmarkr,gpersf,gsci,gspo,gwea',
                  categories: state.root.queryParams.categories ? state.root.queryParams.categories : '',
                  continents: state.root.queryParams.continents,
                  countries: state.root.queryParams.countries,
                  factuality: state.root.queryParams.factuality,
                  temporality: state.root.queryParams.temporality,
                  event_ids: state.root.queryParams.event_ids,
                  tutorial: state.root.queryParams.tutorial,
                  sort: !(this.auth.scorelabSettings.defaultScore === 'sentiment') ? 'score' : '-senti',
                },
                // replaceUrl: true,
              });
            } else {
              sortedFolios.map(folio => {
                if (folio.uid === state.root.queryParams.id) {
                  this.auth.folio = folio;
                }
              });
            }
          });
        }
        return true;
      }));
    }
  }
}
