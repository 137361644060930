import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * Component used in the news audit view for displaying chartmap's related info like lists of related topics, sources, events or entities
 */
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-chartmap-info',
  templateUrl: './chartmap-info.component.html',
  styleUrls: ['./chartmap-info.component.scss']
})
export class ChartmapInfoComponent {

  /**
   * array of tab names
   */
  tabNames: any;

  /**
   * lists with chartmap related info
   */
  infoLists = {};

  /**
   * countries for filtering companies
   */
  countries = [];

  /**
   * selected country for filtering companies
   */
  selectedCountry: string;

  /**
   * sectors for filtering companies
   */
  sectors = [];

  /**
   * selected sector for filtering companies
   */
  selectedSector: string;

  /**
   * countries for filtering participants
   */
  countries2 = [];

  /**
   * selected country for filtering participants
   */
  selectedCountry2: string;

  /**
   * sectors for filtering participants
   */
  sectors2 = [];

  /**
   * selected sector for filtering participants
   */
  selectedSector2: string;

  /**
   * countries for filtering sources
   */
  sourcesCountries = [];

  /**
   * selected country for filtering sources
   */
  selectedSourceCountry: string;

  /**
   * groups for 'Discover' tab
   */
  discoverGroups = ['Events', 'Companies', 'Countries', 'Potential Entities', 'Sources'];

  /**
   * selected group for 'Discover' tab
   */
  selectedDiscoverGroup = 'Events';

  /**
   * selected tab's index
   */
  selectedTabIndex = 0;

  /**
   * query parameters
   */
  qparam: any;

  /**
   * query parameters refresh
   */
  previousValue: any;

  /**
   * tag added to the url for newsfeed filtering
   */
  activeTag: string;

  /**
   * participant added to the url for newsfeed filtering
   */
  activeParticipant: string;

  /**
   * event added to the url for newsfeed filtering
   */
  activeEvent: any;

  /**
   * checks whether some data was returned from the backend requests
   */
  nodata: boolean;

  /**
   * if true mat-spinner replaces component's content
   */
  loading: boolean;

  /**
   * checks if the component is ready for an update
   */
  iready: boolean;

  /**
   * Subscribing to the backend data API using 'yukkapi' service component, populating component's content with the data, updating on a rout refresh.
   */
  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private yukkApi: YukkApi,
      public routing: RoutingService,
      public auth: AuthService
  ) {

    this.route.queryParams.subscribe(qparam => {
      if (this.routing.isFolio() && !qparam.portfolioId) {
        this.tabNames = ['Portfolio', 'Discover'];
      } else if (this.routing.isQuery() || this.routing.isSearch() || (qparam.type && ((qparam.type === 'sub_event') || (qparam.type === 'event') || (qparam.type === 'super_event')))) {
        this.tabNames = ['Participants', 'Discover'];
      } else {
        this.tabNames = ['Discover'];
      }
      this.qparam = qparam;
      this.activeTag = decodeURI(qparam.tag).split('|')[0];
      this.activeParticipant = decodeURI(qparam.participant).split('|')[0];
      this.activeEvent = {
        type: decodeURI(qparam.eventype),
        id: decodeURI(qparam.eventid)
      };
      if (this.routing.reFresh(this.qparam, this.previousValue, ['type', 'id', 'time', 'lang', 'feed', 'categories', 'continents', 'countries', 'factuality', 'temporality', 'ranks', 'panels', 'period', 'event_ids'])) {
        this.nodata = false;
        this.loading = true;
        this.iready = false;
        this.selectedDiscoverGroup = 'Events';
        this.selectedTabIndex = 0;
        forkJoin({
          // sources: this.yukkApi.getTopSources(this.qparam, 29),
          companies: this.yukkApi.relatedEntities(this.qparam, 'company', 1000),
          pnes: this.yukkApi.relatedEntities(this.qparam, 'pne', 1000),
          countries: this.yukkApi.relatedEntities(this.qparam, 'country', 1000),
          participants: (this.routing.isQuery() || this.routing.isSearch() || (qparam.type && ((qparam.type === 'sub_event') || (qparam.type === 'event') || (qparam.type === 'super_event')))) ? this.yukkApi.relatedEntities(this.qparam, 'participants', 1000) : of([]),
          // events: (this.routing.isFolio() || this.routing.isMarket()) ? this.yukkApi.relatedEvents(this.qparam, 'sub_events') : of([]),
          events: this.yukkApi.relatedEvents(this.qparam, 'sub_events'),
          portfolios: (this.routing.isFolio() && this.auth.folio) ? this.yukkApi.portfolio(this.qparam, this.auth.folio.content) : of({treemap: {children: []}})
        }).subscribe( result => {
          // const sourcesCountriesFilter = [];
          // const sourcesList = result.sources.top_sources.map( element => {
          //   if (element.countries) {
          //     element.countries.forEach(country => {
          //       sourcesCountriesFilter.push(JSON.stringify({
          //         label: country.name,
          //         value: country.entity.alpha_id
          //       }));
          //     });
          //   }
          //   return {
          //     name: element.source.name,
          //     countries: ( element.countries ) ? element.countries.map(el => el.entity.alpha_id) : [],
          //     volume: element.count,
          //     tag: 'm:' + element.source.type + ':' + element.source.alpha_id
          //   };
          // });
          const countriesFilter = [];
          const sectorsFilter = [];
          const companyList = result.companies.map( element => {
            if (element.entity.description.Country) {
              countriesFilter.push(JSON.stringify({
                label: element.entity.description.Country.name,
                value: element.entity.description.Country.entity.alpha_id
              }));
            }
            if (element.entity.description.Sector) {
              sectorsFilter.push(JSON.stringify({
                label: element.entity.description.Sector.name,
                value: element.entity.description.Sector.entity.alpha_id
              }));
            }
            return {
              name: element.entity.name,
              country: ( element.entity.description.Country ) ? {
                name: element.entity.description.Country.name,
                value: element.entity.description.Country.entity.alpha_id
              } : null,
              sector: ( element.entity.description.Sector ) ? {
                name: element.entity.description.Sector.name,
                value: element.entity.description.Sector.entity.alpha_id
              } : null,
              volume: (Math.round(element.sentiment.volume_ratio * 10) / 10),
              tag: 'm:' + element.entity.compound_key
            };
          });
          const pneList = result.pnes.map( element => {
            return {
              name: element.entity.name,
              volume: (Math.round(element.sentiment.volume_ratio * 10) / 10),
              tag: 'm:' + element.entity.compound_key
            };
          });
          const countryList = result.countries.map( element => {
            return {
              name: element.entity.name,
              volume: (Math.round(element.sentiment.volume_ratio * 10) / 10),
              tag: 'm:' + element.entity.compound_key
            };
          });
          const countriesFilter2 = [];
          const sectorsFilter2 = [];
          const participantList = result.participants.map( element => {
            if (element.entity.description.Country) {
              countriesFilter2.push(JSON.stringify({
                label: element.entity.description.Country.name,
                value: element.entity.description.Country.entity.alpha_id
              }));
            }
            if (element.entity.description.Sector) {
              sectorsFilter2.push(JSON.stringify({
                label: element.entity.description.Sector.name,
                value: element.entity.description.Sector.entity.alpha_id
              }));
            }
            return {
              name: element.entity.name,
              country: ( element.entity.description.Country ) ? {
                name: element.entity.description.Country.name,
                value: element.entity.description.Country.entity.alpha_id
              } : null,
              sector: ( element.entity.description.Sector ) ? {
                name: element.entity.description.Sector.name,
                value: element.entity.description.Sector.entity.alpha_id
              } : null,
              volume: (Math.round(element.sentiment.volume_ratio * 10) / 10),
              tag: 'm:' + element.entity.compound_key,
              compound_key: element.entity.compound_key
            };
          });
          if (this.routing.isQuery() && (participantList.length === 0)) {
            this.selectedTabIndex = 1;
            this.selectedDiscoverGroup = 'Companies';
          }
          const eventList = result.events.events.filter( element => {
            return (element.count > 0);
          }).map( element => {
            return {
              name: element.event.name,
              volume: element.count,
              id: element.event.id,
              type: element.event.type
            };
          });
          // this.infoLists['Sources'] = sourcesList;
          this.infoLists['Companies'] = companyList;
          this.infoLists['Potential Entities'] = pneList;
          this.infoLists['Countries'] = countryList;
          this.infoLists['Participants'] = participantList;
          this.infoLists['Events'] = eventList;
          // const uniqueSourcesCountries = Array.from(new Set(sourcesCountriesFilter));
          // this.sourcesCountries = uniqueSourcesCountries.map(item => {
          //   return JSON.parse(item);
          // });
          const uniqueCountries = Array.from(new Set(countriesFilter));
          const uniqueSectors = Array.from(new Set(sectorsFilter));
          this.countries = uniqueCountries.map(item => {
            return JSON.parse(item);
          });
          this.sectors = uniqueSectors.map(item => {
            return JSON.parse(item);
          });
          const uniqueCountries2 = Array.from(new Set(countriesFilter2));
          const uniqueSectors2 = Array.from(new Set(sectorsFilter2));
          this.countries2 = uniqueCountries2.map(item => {
            return JSON.parse(item);
          });
          this.sectors2 = uniqueSectors2.map(item => {
            return JSON.parse(item);
          });
          if (!(this.routing.isFolio() && this.auth.folio)) {
            this.iready = true;
          } else {
            const content = this.auth.folio.content;
            const requestsList = [];
            const chunkSize = 100;
            for (let i = 0; i < content.length; i += chunkSize) {
              requestsList.push(this.yukkApi.relatedEvents(Object.assign({}, this.qparam, {
                body: content.slice(i, (i + chunkSize))
              }), 'sub_events_itemized').pipe(catchError(error => {
                return of(Array(((content.length - i) < chunkSize) ? (content.length - i) : chunkSize).fill({result: []}));
              })));
            }
            forkJoin(requestsList).subscribe(res => {
              // @ts-ignore
              const res2 = res.flat();
              const filteredCompanies = [];
              res2.forEach((el, index) => {
                if (el.result && (el.result.length > 0)) {
                  let isParticipant = false;
                  el.result.forEach(event => {
                    if (this.qparam && this.qparam.event_ids) {
                      if ((event.count > 0) && this.qparam.event_ids.split(',').includes(event.event.id)) {
                        isParticipant = true;
                      }
                    } else {
                      if (event.count > 0) {
                        isParticipant = true;
                      }
                    }
                  });
                  if (isParticipant) {
                    filteredCompanies.push({tag: 'm:' + this.auth.folio.content[index]});
                  }
                }
              });
              const portfolioList = result.portfolios.treemap.children.map( element => {
                return {
                  name: element.entity.name,
                  volume: (Math.round(element.volume_ratio * 10) / 10),
                  tag: 'm:' + element.entity.compound_key
                };
              });
              const portfolioParticipantsList = portfolioList.filter(item => {
                return filteredCompanies.map(element => {
                  return element.tag;
                }).includes(item.tag);
              });
              const portfolioCompaniesList = portfolioList.filter(item => {
                return !filteredCompanies.map(element => {
                  return element.tag;
                }).includes(item.tag);
              });
              this.infoLists['PortfolioParticipants'] = portfolioParticipantsList;
              this.infoLists['PortfolioCompanies'] = portfolioCompaniesList;
              this.iready = true;
            });
          }
        }, error => {
          this.nodata = true;
          this.loading = false;
        });
        this.yukkApi.getTopSources(this.qparam, 29).subscribe(result => {
          const sourcesCountriesFilter = [];
          const sourcesList = result.top_sources.map( element => {
            if (element.countries) {
              element.countries.forEach(country => {
                sourcesCountriesFilter.push(JSON.stringify({
                  label: country.name,
                  value: country.entity.alpha_id
                }));
              });
            }
            return {
              name: element.source.name,
              countries: ( element.countries ) ? element.countries.map(el => el.entity.alpha_id) : [],
              volume: element.count,
              tag: 'm:' + element.source.type + ':' + element.source.alpha_id
            };
          });
          this.infoLists['Sources'] = sourcesList;
          const uniqueSourcesCountries = Array.from(new Set(sourcesCountriesFilter));
          this.sourcesCountries = uniqueSourcesCountries.map(item => {
            return JSON.parse(item);
          });
        }, error => {
          this.infoLists['Sources'] = [];
          this.sourcesCountries = [];
        });
      }
      this.previousValue = this.qparam;
    });

  }

  /**
   * filtered lists of items
   */
  filteredInfoLists(tab) {
    if (tab === 'Sources') {
      return this.infoLists[tab].filter(item => {
        let condition = true;
        if (this.selectedSourceCountry) {
          condition = (item.countries) ? (item.countries.includes(this.selectedSourceCountry)) : false;
        }
        return condition;
      });
    } else if (tab === 'Companies') {
      return this.infoLists[tab].filter(item => {
        let condition1 = true;
        let condition2 = true;
        if (this.selectedCountry) {
          condition1 = (item.country) ? (item.country.value === this.selectedCountry) : false;
        }
        if (this.selectedSector) {
          condition2 = (item.sector) ? (item.sector.value === this.selectedSector) : false;
        }
        return ( condition1 && condition2 );
      });
    } else if (tab === 'Participants') {
      return this.infoLists[tab].filter(item => {
        let condition1 = true;
        let condition2 = true;
        if (this.selectedCountry2) {
          condition1 = (item.country) ? (item.country.value === this.selectedCountry2) : false;
        }
        if (this.selectedSector2) {
          condition2 = (item.sector) ? (item.sector.value === this.selectedSector2) : false;
        }
        return ( condition1 && condition2 );
      });
    } else {
      return this.infoLists[tab];
    }
  }

  /**
   * filtering newsfeed on click for entities/events lists items
   */
  goItem(item, type) {
    const returnParticipants = ( ( type && type === 'Participants' ) && (this.routing.isQuery() || this.routing.isSearch() || this.qparam.eventype || (this.qparam.type === 'event') || (this.qparam.type === 'sub_event') || (this.qparam.type === 'super_event') ) );
    if (type === 'Events') {
      this.iEvent(item);
    } else if (returnParticipants) {
      this.iParticipant(item);
    } else {
      this.iTag(item);
    }
  }

  /**
   * add participant to the url
   */
  iParticipant(item) {
    const participant = (this.activeParticipant === item.compound_key) ? null : encodeURI(item.compound_key);
    this.router.navigate([], {
      queryParams: {
        participant: participant,
        eventype: null,
        eventid: null,
        tag: null
      },
      queryParamsHandling: 'merge',
      // replaceUrl: true
    });
  }

  /**
   * add active tag to the url
   */
  iTag(item) {
    const tag = (this.activeTag === item.tag) ? null : encodeURI(item.tag);
    this.router.navigate([], {
      queryParams: {
        tag: tag,
        eventype: null,
        eventid: null,
        participant: null
      },
      queryParamsHandling: 'merge',
      // replaceUrl: true
    });
  }

  /**
   * add active tag to the url
   */
  iEvent(item) {
    let eventType = null;
    let eventId = null;
    if ( (this.activeEvent.type !== item.type) || (this.activeEvent.id !== item.id) ) {
      eventType = item.type;
      eventId = item.id;
    }
    this.router.navigate([], {
      queryParams: {
        eventype: eventType,
        eventid: eventId,
        newsday: null,
        tag: null,
        participant: null
      },
      queryParamsHandling: 'merge',
      // replaceUrl: true
    });
  }

}
