import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfigService } from './config.service';
import * as moment from 'moment';

/**
* This service is used for detect specific path or device, 4 setup the title of the page and define the theme.
*/

@Injectable()
export class RoutingService {

  params: any;
  theme: string;
  mobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

  constructor(
    private titleService: Title,
    public config: ConfigService,
  ) { }

  isLogin() {
    return location.pathname.split('/')[1] === 'login';
  }
  isHomepage() {
    return location.pathname.split('/')[2] === 'home';
  }
  isScanner() {
    return location.pathname.split('/')[4] === 'scanner';
  }
  isStories() {
    return location.pathname.split('/')[2] === 'stories';
  }
  isSearch() {
    return location.pathname.split('/')[3] === 'search';
  }
  isMarket() {
    return location.pathname.split('/')[3] === 'market';
  }
  isFolio() {
    return ( location.pathname.split('/')[2] === 'portfolio' || location.pathname.split('/')[3] === 'portfolio' );
  }
  isHot() {
    return location.pathname.split('/')[3] === 'trending';
  }
  isQuery() {
    return location.pathname.split('/')[3] === 'query';
  }
  isInvest() {
    return location.pathname.split('/')[2] === 'invest';
  }
  isMain() {
    return location.pathname.split('/')[4] === 'main';
  }
  isChart2() {
    return location.pathname.split('/')[4] === 'chart2';
  }
  isChart() {
    return location.pathname.split('/')[4] === 'chart';
  }
  isParticipants() {
    return location.pathname.split('/')[4] === 'participants';
  }
  isMulti() {
    return location.pathname.split('/')[4] === 'multi';
  }
  isRadar() {
    return location.pathname.split('/')[4] === 'radar';
  }
  isNetwork() {
    return location.pathname.split('/')[4] === 'network';
  }
  isNewsaudit() {
    return location.pathname.split('/')[4] === 'audit';
  }
  isNewsletter() {
    return location.pathname.split('/')[2] === 'newsletter';
  }
  isPreview() {
    return location.pathname.split('/')[3] === 'preview';
  }
  // isLivePreview() {
  //   return location.pathname.split('/')[3] === 'livepreview';
  // }
  // isArticles() {
  //   return location.pathname.split('/')[3] === 'articles';
  // }

  isPath(value) {
    return location.pathname.split('/')[1] === value;
  }
  isReport() {
    return location.pathname.split('/')[2] === 'report';
  }
  isMobile() {
    return location.pathname.split('/')[1] === 'mobile' || this.mobile || window.innerWidth <= 700;
  }
  isFeed() {
    return location.pathname.split('/')[2] === 'newsfeed' || location.pathname.split('/')[3] === 'newsfeed' || location.pathname.split('/')[4] === 'newsfeed';
  }
  isTrending() {
    return location.pathname.split('/')[1] === 'trending';
  }
  isChat() {
    return location.pathname.split('/')[2] === 'chat';
  }
  isIframe() {
    return location.pathname.split('/')[1] === 'iframe' || location.pathname.split('/')[1] === 'widget';
  }
  isWidget() {
    return location.pathname.split('/')[1] === 'widget';
  }
  isReader() {
    return location.pathname.split('/')[2] === 'reader';
  }
  isStoryReader() {
    return location.pathname.split('/')[2] === 'story';
  }
  isDev() {
    return this.config.appConfig && (this.config.appConfig.environment === 'dev');
  }
  isProd() {
    return this.config.appConfig && (this.config.appConfig.environment === 'prod');
  }
  isApp() {
    return this.config.appConfig && (this.config.appConfig.routing.project === 'app');
  }

  getTitle() {
    const title = 'YUKKALab | Realtime News-Analytics';
    this.titleService.setTitle(title);
  }
  reFresh(oldp, newp, goin) {
    if (!newp) { return true; }
    let shouldRefresh = false;
    goin.forEach(item => {
      if (oldp[item] !== newp[item]) {
        shouldRefresh = true;
        return;
      }
    });
    return shouldRefresh;
  }
  inTheme(theme: string) {
    this.theme = (theme !== 'yukka') ? theme : 'dark';
    if (Array.isArray(this.theme)) { this.theme = this.theme[0]; }
    if ((this.theme !== 'null') && this.config.appConfig.setup.useStorage) { localStorage.setItem('theme', this.theme); }
    document.querySelector('body').className = '';
    document.querySelector('body').className = 'theme-' + this.theme;
  }
  isToday(value) {
    if (value && isNaN(value)) {
      const date = value.split('--');
      return moment().format('YYYY-MM-DD') <= moment(date[1]).format('YYYY-MM-DD');
    }
    return true;
  }

}
