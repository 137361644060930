import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/service/auth.service';
/**
 * redirect to specific query if no one is selected and store the data in the auth service
 */
@Injectable({
  providedIn: 'root',
})
export class QueryGuard {
  constructor(
    public auth: AuthService,
    private router: Router,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    if (
      state.url.split('?')[0] === '/cockpit/news/query/main' &&
      this.auth.featureFlags.defaultCollectionView !== 'main'
    ) {
      this.router.navigate(
        ['/cockpit/news/query/' + this.auth.featureFlags.defaultCollectionView],
        {
          replaceUrl: true,
        },
      );
    } else {
      return forkJoin({
        groups: this.auth.getGroupsQuery(),
        queries: this.auth.getQuery(),
      }).pipe(
        map((result) => {
          this.auth.querys = result.groups;
          this.auth.iquery = undefined;
          this.auth.query = undefined;
          if (result.groups[0]) {
            if (!state.root.queryParams.id) {
              const sortedGroups = JSON.parse(JSON.stringify(result.groups));
              sortedGroups.sort((a, b) => {
                return a.name.localeCompare(b.name);
              });
              this.router.navigate([state.url.split('?')[0]], {
                queryParams: {
                  id: sortedGroups[0].uid,
                  groupId: sortedGroups[0].uid,
                  news: state.root.queryParams.news,
                  highlight: state.root.queryParams.highlight,
                  time: state.root.queryParams.time,
                  filter: state.root.queryParams.filter,
                  lang: state.root.queryParams.lang
                    ? state.root.queryParams.lang
                    : 'en,de',
                  feed: state.root.queryParams.feed
                    ? state.root.queryParams.feed
                    : 'non_professional,online,print,professional',
                  ranks: state.root.queryParams.ranks,
                  // categories: state.root.queryParams.categories ? state.root.queryParams.categories : 'gent,gedu,ciprof,glife,cmarkr,gpersf,gsci,gspo,gwea',
                  categories: state.root.queryParams.categories
                    ? state.root.queryParams.categories
                    : '',
                  continents: state.root.queryParams.continents,
                  countries: state.root.queryParams.countries,
                  factuality: state.root.queryParams.factuality,
                  temporality: state.root.queryParams.temporality,
                  event_ids: state.root.queryParams.event_ids,
                  tutorial: state.root.queryParams.tutorial,
                  sort: 'ratio',
                  nomenu: state.root.queryParams.nomenu,
                  notitle: state.root.queryParams.notitle,
                },
                // replaceUrl: true,
              });
            } else {
              if (state.root.queryParams.id === 'default') {
                this.auth.iquery = result.queries;
                this.auth.query = {
                  uid: 'default',
                  name: 'default',
                  query: {
                    operator: 'or',
                    filters: [],
                    groups: this.auth.iquery.map((query) => query.query),
                  },
                };
                return true;
              } else {
                let collectionIdExists = false;
                let queryIdExists = false;
                result.groups.map((group) => {
                  if (group.uid === state.root.queryParams.id) {
                    this.auth.iquery = group.content;
                    this.auth.query = {
                      uid: group.uid,
                      name: group.name,
                      query: {
                        operator: 'or',
                        filters: [],
                        groups: group.content.map((query) => query.query),
                      },
                    };
                  } else {
                    group.content.map((query) => {
                      if (query.uid === state.root.queryParams.id) {
                        this.auth.query = query;
                      }
                    });
                  }
                  if (group.uid === state.root.queryParams.groupId) {
                    collectionIdExists = true;
                  }
                });
                if (this.auth.query === undefined) {
                  result.queries.map((query) => {
                    if (query.uid === state.root.queryParams.id) {
                      this.auth.query = query;
                      queryIdExists = true;
                    }
                  });
                } else {
                  queryIdExists = true;
                }
                if (
                  (!queryIdExists || !collectionIdExists) &&
                  state.root.queryParams.groupId !== 'default'
                ) {
                  const sortedGroups = JSON.parse(
                    JSON.stringify(result.groups),
                  );
                  sortedGroups.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                  });
                  this.router.navigate([state.url.split('?')[0]], {
                    queryParams: {
                      id: sortedGroups[0].uid,
                      groupId: sortedGroups[0].uid,
                      news: state.root.queryParams.news,
                      highlight: state.root.queryParams.highlight,
                      time: state.root.queryParams.time,
                      filter: state.root.queryParams.filter,
                      lang: state.root.queryParams.lang
                        ? state.root.queryParams.lang
                        : 'en,de',
                      feed: state.root.queryParams.feed
                        ? state.root.queryParams.feed
                        : 'non_professional,online,print,professional',
                      ranks: state.root.queryParams.ranks,
                      // categories: state.root.queryParams.categories ? state.root.queryParams.categories : 'gent,gedu,ciprof,glife,cmarkr,gpersf,gsci,gspo,gwea',
                      categories: state.root.queryParams.categories
                        ? state.root.queryParams.categories
                        : '',
                      continents: state.root.queryParams.continents,
                      countries: state.root.queryParams.countries,
                      factuality: state.root.queryParams.factuality,
                      temporality: state.root.queryParams.temporality,
                      event_ids: state.root.queryParams.event_ids,
                      tutorial: state.root.queryParams.tutorial,
                      sort: 'ratio',
                      nomenu: state.root.queryParams.nomenu,
                      notitle: state.root.queryParams.notitle,
                    },
                    // replaceUrl: true,
                  });
                }
                return true;
              }
            }
          } else {
            if (state.root.queryParams.id === 'default') {
              this.auth.iquery = result.queries;
              this.auth.query = {
                uid: 'default',
                name: 'default',
                query: {
                  operator: 'or',
                  filters: [],
                  groups: this.auth.iquery.map((query) => query.query),
                },
              };
              return true;
            } else if (state.root.queryParams.groupId === 'default') {
              if (state.root.queryParams.id) {
                result.queries.map((query) => {
                  if (query.uid === state.root.queryParams.id) {
                    this.auth.query = query;
                  }
                });
                return true;
              } else {
                this.router.navigate([state.url.split('?')[0]], {
                  queryParams: {
                    id: 'default',
                    groupId: 'default',
                    news: state.root.queryParams.news,
                    highlight: state.root.queryParams.highlight,
                    time: state.root.queryParams.time,
                    lang: state.root.queryParams.lang
                      ? state.root.queryParams.lang
                      : 'en,de',
                    feed: state.root.queryParams.feed
                      ? state.root.queryParams.feed
                      : 'non_professional,online,print,professional',
                    ranks: state.root.queryParams.ranks,
                    panels: state.root.queryParams.panels,
                    // categories: state.root.queryParams.categories ? state.root.queryParams.categories : 'gent,gedu,ciprof,glife,cmarkr,gpersf,gsci,gspo,gwea',
                    categories: state.root.queryParams.categories
                      ? state.root.queryParams.categories
                      : '',
                    continents: state.root.queryParams.continents,
                    countries: state.root.queryParams.countries,
                    factuality: state.root.queryParams.factuality,
                    temporality: state.root.queryParams.temporality,
                    event_ids: state.root.queryParams.event_ids,
                    tutorial: state.root.queryParams.tutorial,
                    sort: 'ratio',
                    nomenu: state.root.queryParams.nomenu,
                    notitle: state.root.queryParams.notitle,
                  },
                  // replaceUrl: true,
                });
              }
            } else {
              this.router.navigate([state.url.split('?')[0]], {
                queryParams: {
                  id: 'default',
                  groupId: 'default',
                  news: state.root.queryParams.news,
                  highlight: state.root.queryParams.highlight,
                  time: state.root.queryParams.time,
                  lang: state.root.queryParams.lang
                    ? state.root.queryParams.lang
                    : 'en,de',
                  feed: state.root.queryParams.feed
                    ? state.root.queryParams.feed
                    : 'non_professional,online,print,professional',
                  ranks: state.root.queryParams.ranks,
                  panels: state.root.queryParams.panels,
                  // categories: state.root.queryParams.categories ? state.root.queryParams.categories : 'gent,gedu,ciprof,glife,cmarkr,gpersf,gsci,gspo,gwea',
                  categories: state.root.queryParams.categories
                    ? state.root.queryParams.categories
                    : '',
                  continents: state.root.queryParams.continents,
                  countries: state.root.queryParams.countries,
                  factuality: state.root.queryParams.factuality,
                  temporality: state.root.queryParams.temporality,
                  event_ids: state.root.queryParams.event_ids,
                  tutorial: state.root.queryParams.tutorial,
                  sort: 'ratio',
                  nomenu: state.root.queryParams.nomenu,
                  notitle: state.root.queryParams.notitle,
                },
                // replaceUrl: true,
              });
            }
          }
        }),
      );
    }
  }
}
