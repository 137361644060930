import {
  Component,
  ViewEncapsulation,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-article-card',
  templateUrl: './chat-article-cards.component.html',
  styleUrls: ['./chat-article-cards.component.scss'],
})
export class ArticleCardComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() publishTime: string;
  @Input() provider: string;
  @Input() eventNames: string[];
  @Input() entityNames?: string[];
  @Input() citation: number;
  @Input() isTooltip: boolean;

  articleTitle: string;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.processTitle();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['title']) {
      this.processTitle();
      this.cdr.detectChanges();
    }
  }

  private processTitle(): void {
    if (!this.title) {
      this.articleTitle = '';
      return;
    }

    this.articleTitle = this.isTooltip
      ? this.title
      : this.sliceTitle(this.title);
  }

  private sliceTitle(title: string): string {
    return title.length > 55 ? `${title.slice(0, 55)}...` : title;
  }
}
