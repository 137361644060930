import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { ConfigService } from 'src/app/service/config.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { StepsWelcomeComponent } from '../../main-steps/steps-welcome/steps-welcome.component';

/**
* This is the menu above the cockpit, it contain the logo, search, filter, theme, etc...
*/

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-menu-above',
  templateUrl: './menu-above.component.html',
  styleUrls: ['./menu-above.component.scss']
})
export class MenuAboveComponent {

  project: any;
  params: any;
  logo: any;

  /**
   * query parameters subscribe
   */
  constructor(
    private route: ActivatedRoute,
    public routing: RoutingService,
    private router: Router,
    public auth: AuthService,
    public config: ConfigService,
    public dialog: MatDialog,
  ) {
    this.project = this.config.appConfig.routing.project;
    this.logo = this.auth.featureFlags.logo ? this.auth.featureFlags.logo : this.project;
    this.route.queryParams.subscribe(params => {
      this.params = params;
      if (window.location.hostname === 'swissre.yukkalab.com') {
        this.logo = 'swissre';
      } else {
        // this.logo = this.project;
      }
    });

  }

  /**
  * Open dialog tutorial
  */
  inTutorial() {
    this.dialog.open(StepsWelcomeComponent);
  }

  /**
  * Click logo redirect
  */
  inLogo() {
    if (this.routing.isWidget()) {
      if (window.location.hostname === 'localhost') {
        window.open('//localhost:4200' + this.router.url);
      } else {
        window.open('//' + window.location.hostname + this.router.url);
      }
    } else {
      this.router.navigate(['/'], {
        queryParams: {
          id: null,
          score: null,
          time: this.params.score ? null : this.params.time
        },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    }
  }

  /**
  * Toggle aside menu
  */
  asideMenu() {
    this.router.navigate([], {
      queryParams: {
        sidenav: this.params.sidenav ? null : 'account'
      },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

}
