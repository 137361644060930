import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WelcomeQuestion, welcomeQuestions } from './chat-welcome-questions';

@Component({
  selector: 'app-chat-welcome-container',
  templateUrl: './chat-welcome-container.component.html',
  styleUrls: ['./chat-welcome-container.component.scss'],
})
export class ChatWelcomeContainerComponent {
  @Input() userName: string = '';
  @Output() exampleQuestionSubmitted = new EventEmitter<string>();

  preDefinedQuestions: WelcomeQuestion[] = welcomeQuestions;

  constructor() {}

  onQuestionClick(question: string) {
    this.exampleQuestionSubmitted.emit(question);
  }
}
