import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import getScoreTypeDetails from '../chart-score-names';

@Component({
  selector: 'app-chat-charts-trend',
  templateUrl: './chat-charts-trend.component.html',
  styleUrls: ['./chat-charts-trend.component.scss'],
})
export class ChatChartsTrendComponent implements OnInit, OnChanges {
  @Input() childTrend: string;
  @Input() childTrendScore: string;
  @Input() childTrendTimeframe: string;

  widget = ['chart'];

  score: string | null;

  companyName: string;

  timeframe: string;

  dynamicUrl: string;

  ngOnInit() {
    this.urlChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['childTrendScore']) {
      this.urlChange();
    }
  }

  urlChange() {
    this.companyName = this.childTrend;
    this.score = this.childTrendScore;
    this.timeframe = this.childTrendTimeframe;

    this.dynamicUrl = `${window.location.origin}/widget/chart?type=company&id=${this.companyName}&notitle=true&time=${this.timeframe}&nomenu=true&custom_score=${getScoreTypeDetails(this.score).scoreKey}`;
  }
}
