<div class="report-main">
  <!--<div mat-dialog-content>
    <div matDialogTitle>
      <div class="tito">{{data.report}}</div>
      <div class="subo">{{data.match}}</div>
    </div>
    <mat-form-field class="message" appearance="outline">
      <mat-label>Report</mat-label>
      <textarea matInput [formControl]="message" cdkTextareaAutosize></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="secondary" (click)="onCancel()">CANCEL</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="message" *ngIf="message.valid">SUBMIT</button>
  </div>-->

  <div class="main-blok header">
    <div class="info title">
      <div class="cont title">
        <mat-icon
          *ngIf="!routing.isReader() && !routing.isStoryReader()"
          [class.active]="pin"
          (click)="pinReport()"
          [matTooltip]="pinTooltip()"
          svgIcon="pin"
        ></mat-icon>
        <span>Report on</span>
      </div>
      <!--<mat-form-field>
        <mat-select [(ngModel)]="reportType" (selectionChange)="onSelection()">
          <mat-option value="sentiment">Report Wrong Sentiment</mat-option>
          <mat-option value="entity">Report Wrong Entity</mat-option>
          <mat-option value="event">Report Wrong Event</mat-option>
          <mat-option value="other">Report Other</mat-option>
        </mat-select>
      </mat-form-field>-->
    </div>
    <div class="info">
      <div
        class="info-tab"
        [class.active-tab]="reportType === 'entity'"
        (click)="onSelection('entity')"
      >
        Entity
      </div>
      <div
        class="info-tab"
        [class.active-tab]="reportType === 'event'"
        (click)="onSelection('event')"
      >
        Event
      </div>
      <div
        class="info-tab"
        [class.active-tab]="reportType === 'sentiment'"
        (click)="onSelection('sentiment')"
      >
        Sentiment
      </div>
      <div
        class="info-tab"
        [class.active-tab]="reportType === 'other'"
        (click)="onSelection('other')"
      >
        Other
      </div>
    </div>
  </div>

  <div class="main-blok content">
    <mat-form-field
      class="form-field"
      *ngIf="reportType !== 'other'"
      appearance="outline"
    >
      <mat-label>Paste sentence here</mat-label>
      <textarea
        matInput
        [(ngModel)]="reportSentence"
        cdkTextareaAutosize
      ></textarea>
    </mat-form-field>

    <!--<mat-form-field class="form-field" *ngIf="(reportType === 'other')" appearance="outline">
      <mat-label>Add comment</mat-label>
      <textarea matInput [(ngModel)]="reportComment" cdkTextareaAutosize></textarea>
    </mat-form-field>-->

    <div
      class="info text"
      *ngIf="reportType !== 'sentiment' && reportType !== 'other'"
    >
      <span>What is wrong?</span>
    </div>

    <div class="info" *ngIf="reportType === 'entity'">
      <div
        class="selection-tab"
        [class.active-tab]="reportError === 'wrong entity'"
        (click)="setError('wrong entity')"
      >
        Wrong entity
      </div>
      <div
        class="selection-tab"
        [class.active-tab]="reportError === 'unrecognized entity'"
        (click)="setError('unrecognized entity')"
      >
        Entity not detected
      </div>
    </div>

    <div class="info" *ngIf="reportType === 'event'">
      <div
        class="selection-tab"
        [class.active-tab]="reportError === 'wrong event'"
        (click)="setError('wrong event')"
      >
        Wrong event
      </div>
      <div
        class="selection-tab"
        [class.active-tab]="reportError === 'unrecognized event'"
        (click)="setError('unrecognized event')"
      >
        Event not detected
      </div>
      <div
        class="selection-tab"
        [class.active-tab]="reportError === 'wrong participant'"
        (click)="setError('wrong participant')"
      >
        Wrong participant
      </div>
      <div
        class="selection-tab"
        [class.active-tab]="reportError === 'unrecognized participant'"
        (click)="setError('unrecognized participant')"
      >
        Participant not detected
      </div>
    </div>

    <div
      class="info report"
      *ngIf="
        reportType === 'event' &&
        (reportError === 'wrong event' ||
          reportError === 'wrong participant' ||
          reportError === 'unrecognized participant')
      "
    >
      <mat-form-field appearance="outline">
        <mat-label>Select wrong event</mat-label>
        <mat-select (selectionChange)="setEvent($event.value)">
          <mat-option *ngFor="let event of data.events" [value]="event">
            {{ event.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field
      class="form-field"
      *ngIf="reportError === 'unrecognized event'"
      appearance="outline"
    >
      <mat-label>Enter event's name</mat-label>
      <textarea
        matInput
        [(ngModel)]="reportEvent"
        cdkTextareaAutosize
      ></textarea>
    </mat-form-field>

    <div
      class="info report"
      *ngIf="reportType === 'event' && reportError === 'wrong participant'"
    >
      <mat-form-field appearance="outline">
        <mat-label>Select wrong participant</mat-label>
        <mat-select (selectionChange)="setParticipant($event.value)">
          <mat-option
            *ngFor="let participant of data.participants"
            [value]="participant"
          >
            {{ participant.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field
      class="form-field"
      *ngIf="reportError === 'unrecognized participant'"
      appearance="outline"
    >
      <mat-label>Enter participant's name</mat-label>
      <textarea
        matInput
        [(ngModel)]="reportParticipant"
        cdkTextareaAutosize
      ></textarea>
    </mat-form-field>

    <div
      class="info report"
      *ngIf="
        reportType === 'sentiment' ||
        (reportType === 'entity' && reportError === 'wrong entity')
      "
    >
      <mat-form-field appearance="outline">
        <mat-label>Select wrong entity</mat-label>
        <mat-select
          [compareWith]="compareFn"
          [(value)]="entityControl"
          (selectionChange)="clickTarget($event.value)"
        >
          <ng-container
            *ngFor="
              let items of data.matches
                | groupBy: ['entity.type']
                | orderByType
                | pairs
            "
          >
            <mat-optgroup
              *ngIf="items[0] === 'company'"
              [label]="items[0] | nicetxt"
            >
              <mat-option
                *ngFor="let option of items[1]"
                [value]="option"
                [style.color]="option.sentiment | color"
                >{{ option.entity.name }}</mat-option
              >
            </mat-optgroup>
          </ng-container>
          <ng-container
            *ngFor="
              let items of data.matches
                | groupBy: ['entity.type']
                | orderByType
                | pairs
            "
          >
            <mat-optgroup
              *ngIf="items[0] === 'person'"
              [label]="items[0] | nicetxt"
            >
              <mat-option
                *ngFor="let option of items[1]"
                [value]="option"
                [style.color]="option.sentiment | color"
                >{{ option.entity.name }}</mat-option
              >
            </mat-optgroup>
          </ng-container>
          <ng-container
            *ngFor="
              let items of data.matches
                | groupBy: ['entity.type']
                | orderByType
                | pairs
            "
          >
            <mat-optgroup
              *ngIf="items[0] !== 'company' && items[0] !== 'person'"
              [label]="items[0] | nicetxt"
            >
              <mat-option
                *ngFor="let option of items[1]"
                [value]="option"
                [style.color]="option.sentiment | color"
                >{{ option.entity.name }}</mat-option
              >
            </mat-optgroup>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field
      class="form-field"
      *ngIf="reportError === 'unrecognized entity'"
      appearance="outline"
    >
      <mat-label>Unrecognized entity</mat-label>
      <textarea
        matInput
        [(ngModel)]="reportEntity"
        cdkTextareaAutosize
      ></textarea>
    </mat-form-field>

    <div class="info text sent" *ngIf="reportType === 'sentiment'">
      <span>Select correct sentiment</span>
    </div>

    <div class="info" *ngIf="reportType === 'sentiment'">
      <div
        class="selection-tab"
        [class.active-tab]="reportSentiment === 'positive'"
        (click)="setSentiment('positive')"
      >
        Positive
      </div>
      <div
        class="selection-tab"
        [class.active-tab]="reportSentiment === 'neutral'"
        (click)="setSentiment('neutral')"
      >
        Neutral
      </div>
      <div
        class="selection-tab"
        [class.active-tab]="reportSentiment === 'negative'"
        (click)="setSentiment('negative')"
      >
        Negative
      </div>
    </div>
  </div>

  <div class="main-blok footer">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Add comment</mat-label>
      <textarea
        matInput
        [(ngModel)]="reportComment"
        cdkTextareaAutosize
      ></textarea>
    </mat-form-field>
    <div mat-dialog-actions>
      <!--<button *ngIf="!routing.isReader()" mat-raised-button color="secondary" (click)="onCancel()">CANCEL</button>-->
      <button
        mat-raised-button
        color="primary"
        [class.active-button]="true"
        (click)="onSubmit()"
        *ngIf="!submitted"
      >
        SUBMIT
      </button>
      <button
        mat-raised-button
        color="primary"
        [class.active-button]="true"
        (click)="onSubmit()"
        *ngIf="submitted"
      >
        SUBMITTED
      </button>
    </div>
  </div>
</div>
