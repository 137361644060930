/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DatepicherComponent } from '../menu-filters/datepicher/datepicher.component';
import { RoutingService } from '../../../service/routing.service';
import { Location } from '@angular/common';
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { ConfigService } from 'src/app/service/config.service';
import { HelpComponent } from '../../help/help.component';

/**
 * main menu filter of the application
 */
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-menu-options',
  templateUrl: './menu-options.component.html',
  styleUrls: ['./menu-options.component.scss'],
})
export class MenuOptionsComponent implements AfterViewInit {
  /**
   * fullscreen mode
   */
  fullscreen: boolean;

  /**
   * sort by
   */
  sort: any;

  /**
   * time
   */
  time: any;

  /**
   * filtering by sentiment (possible values: 'pos', 'neu', 'neg')
   */
  filter: any;

  /**
   * possible values and labels for filtering by sentiment options
   */
  filterLabels = {
    pos: 'Pos',
    neu: 'Neu',
    neg: 'Neg',
    all: 'All',
  };

  /**
   * filter by sentiment name to display
   */
  filterName: string;

  /**
   * array of the time
   */
  timenumber: any = ['0', '1', '7', '30', '90', '365'];

  /**
   * time array string
   */
  timestring: any = ['custom', 'day', 'week', 'month', 'quarter', 'year'];

  /**
   * time name to display
   */
  timename: string;

  /**
   * feed selected
   */
  feed: any;

  /**
   *
   */
  itheme: string;

  /**
   *
   */
  params: any;

  /**
   *
   */
  layout = 'main';

  /**
   *
   */
  intheme = true;

  /**
   * possible labels and values of language filters
   */
  filterLang = [
    {
      label: 'English',
      value: 'en',
    },
    {
      label: 'German',
      value: 'de',
    },
  ];
  menuButtonLang = [];

  /**
   * possible labels and values of timeframe filters
   */
  filterTimeframe = [
    {
      label: 'Day',
      value: '1',
    },
    {
      label: 'Week',
      value: '7',
    },
    {
      label: 'Month',
      value: '30',
    },
    {
      label: 'Quarter',
      value: '90',
    },
    {
      label: 'Custom',
      value: '0',
    },
  ];
  /**
   * form control for timeframe filters
   */
  menuFilterTimeframe: UntypedFormControl;

  /**
   * possible labels and values of sentiment filters
   */
  filterSentiment = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Positive',
      value: 'pos',
    },
    {
      label: 'Neutral',
      value: 'neu',
    },
    {
      label: 'Negative',
      value: 'neg',
    },
  ];
  /**
   * form control for sentiment filters
   */
  menuFilterSentiment: UntypedFormControl;

  /**
   * form control for language filters
   */
  menuFilterLang: UntypedFormControl;

  /**
   *
   */
  project: string;

  /**
   *
   */
  i18n = 'English';

  /**
   * period
   */
  period: string;

  /**
   *
   */
  filterNews = [
    {
      label: 'Pro (Full Text)',
      value: 'professional',
      icon: 'star',
      active: true,
    },
    {
      label: 'Print (Full Text)',
      value: 'print',
      icon: 'list_alt',
      active: true,
    },
    {
      label: 'Web (Finance)',
      value: 'online',
      icon: 'dvr',
      active: true,
    },
    {
      label: 'Web (Global)',
      value: 'non_professional',
      icon: 'rss_feed',
      active: true,
    },
  ];
  menuButtonNews = [];

  /**
   * form control of the feed
   */
  menuFilterNews: UntypedFormControl;

  /**
   * preset filter
   */
  presets: any;

  /**
   * events object containing info for filtering purposes
   */
  events: any;

  /**
   * events filter
   */
  filterEvents = [];

  /**
   * form control of the events filter
   */
  menuFilterEvents: UntypedFormControl;

  /**
   * document categories object containing info for filtering purposes
   */
  categories: any;

  /**
   * document categories filter
   */
  filterCategories = [];

  /**
   * form control of the document categories filter
   */
  menuFilterCategories: UntypedFormControl;

  /**
   * geography filters group object
   */
  filterGeography = {
    continents: [],
    countries: [],
  };

  /**
   * form control of the geography filters
   */
  menuFilterGeography: UntypedFormControl;

  /**
   * geography group autocompletion form, group and options observable
   */
  geographyForm: UntypedFormGroup;
  geographyGroups = [];
  geographyGroupOptions: Observable<any>;

  /**
   * source ranks object containing info for filtering purposes
   */
  ranks: any;

  /**
   * source ranks filter
   */
  filterRanks = [];

  /**
   * form control of the source ranks filter
   */
  menuFilterRanks: UntypedFormControl;

  /**
   * query parameters subscribe and initialize the formcontrols
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private yukkApi: YukkApi,
    public dialog: MatDialog,
    public routing: RoutingService,
    public location: Location,
    public snackBar: MatSnackBar,
    public auth: AuthService,
    public config: ConfigService,
    private cd: ChangeDetectorRef,
  ) {
    this.project = this.config.appConfig.routing.project;
    this.presets = this.auth.getFilter();
    if (this.config.appConfig.routing.project === 'ubs') {
      this.intheme = false;
    }
    this.route.queryParams.subscribe((params) => {
      if (params.event_ids) {
        this.menuFilterEvents = new UntypedFormControl(
          params.event_ids.split(','),
        );
      } else {
        this.menuFilterEvents = new UntypedFormControl();
      }
      // if ( this.filterEvents.length === 0 ) {
      //   this.yukkApi.getEventsTreemap().subscribe(result => {
      //     this.filterEvents = [];
      //     result.treemap.children.forEach( superEvent => {
      //       superEvent.children.forEach( event => {
      //         this.filterEvents.push( {
      //           label: event.entity.name,
      //           value: event.entity.id,
      //           selected: false
      //         } );
      //       } );
      //     } );
      //     this.filterEvents.forEach((element, index) => {
      //       if (this.menuFilterEvents.value !== null) {
      //         this.filterEvents[index].selected = this.menuFilterEvents.value.includes(element.value);
      //       } else {
      //         this.filterEvents[index].selected = false;
      //       }
      //     });
      //   });
      // } else {
      //   this.filterEvents.forEach((element, index) => {
      //     if (this.menuFilterEvents.value !== null) {
      //       this.filterEvents[index].selected = this.menuFilterEvents.value.includes(element.value);
      //     } else {
      //       this.filterEvents[index].selected = false;
      //     }
      //   });
      // }

      if (params.categories) {
        this.menuFilterCategories = new UntypedFormControl(
          params.categories.split(','),
        );
      } else {
        this.menuFilterCategories = new UntypedFormControl();
      }
      if (params.ranks) {
        this.menuFilterRanks = new UntypedFormControl(params.ranks.split(','));
      } else {
        this.menuFilterRanks = new UntypedFormControl();
      }
      let geography = [];
      if (params.continents) {
        const continentsArray = params.continents
          .split(',')
          .map((continent) => 'continent ' + continent);
        geography = geography.concat(continentsArray);
      }
      if (params.countries) {
        const countriesArray = params.countries
          .split(',')
          .map((country) => 'country ' + country);
        geography = geography.concat(countriesArray);
      }
      this.menuFilterGeography = new UntypedFormControl(geography);

      this.geographyForm = this.formBuilder.group({
        geographyGroup: '',
      });

      this.geographyGroupOptions = this.geographyForm
        .get('geographyGroup')!
        .valueChanges.pipe(
          startWith(''),
          map((value) => this.groupMatches(value)),
        );

      if (
        this.filterCategories.length === 0 ||
        this.filterRanks.length === 0 ||
        this.filterGeography.continents.length === 0 ||
        this.filterGeography.countries.length === 0
      ) {
        this.yukkApi.getFiltersList().subscribe((result) => {
          const documentCategories = result.filter(
            (element) => element.filter === 'document_categories',
          );
          const sourceRanks = result.filter(
            (element) => element.filter === 'source_ranks',
          );
          const sourceContinents = result.filter(
            (element) => element.filter === 'source_continents',
          );
          const sourceCountries = result.filter(
            (element) => element.filter === 'source_countries',
          );
          if (documentCategories.length === 1) {
            this.filterCategories = documentCategories[0].values.map(
              (element) => {
                return {
                  value: element.value,
                  label: element.name,
                  selected: false,
                };
              },
            );
            this.filterCategories.forEach((element, index) => {
              if (this.menuFilterCategories.value !== null) {
                this.filterCategories[index].selected =
                  this.menuFilterCategories.value.includes(element.value);
              } else {
                this.filterCategories[index].selected = false;
              }
            });
          } else {
            this.filterCategories = [];
          }
          if (sourceRanks.length === 1) {
            this.filterRanks = sourceRanks[0].values.map((element) => {
              return {
                value: element.value.toString(),
                label: 'Rank ' + element.name,
              };
            });
          } else {
            this.filterRanks = [];
          }
          if (sourceContinents.length === 1) {
            this.filterGeography.continents = sourceContinents[0].values.map(
              (element) => {
                return {
                  value: 'continent ' + element.value,
                  label: element.name,
                  selected: false,
                };
              },
            );
            this.filterGeography.continents.forEach((element, index) => {
              if (this.menuFilterGeography.value !== null) {
                this.filterGeography.continents[index].selected =
                  this.menuFilterGeography.value.includes(element.value);
              } else {
                this.filterGeography.continents[index].selected = false;
              }
            });
          } else {
            this.filterGeography.continents = [];
          }
          if (sourceCountries.length === 1) {
            this.filterGeography.countries = sourceCountries[0].values.map(
              (element) => {
                return {
                  value: 'country ' + element.value,
                  label: element.name,
                  selected: false,
                };
              },
            );
            this.filterGeography.countries.forEach((element, index) => {
              if (this.menuFilterGeography.value !== null) {
                this.filterGeography.countries[index].selected =
                  this.menuFilterGeography.value.includes(element.value);
              } else {
                this.filterGeography.countries[index].selected = false;
              }
            });
          } else {
            this.filterGeography.countries = [];
          }

          this.geographyGroups = [
            {
              type: 'Continents',
              names: this.filterGeography.continents.map(
                (continent) => continent.label,
              ),
            },
            {
              type: 'Countries',
              names: this.filterGeography.countries.map(
                (country) => country.label,
              ),
            },
          ];
        });
      } else {
        this.filterCategories.forEach((element, index) => {
          if (this.menuFilterCategories.value !== null) {
            this.filterCategories[index].selected =
              this.menuFilterCategories.value.includes(element.value);
          } else {
            this.filterCategories[index].selected = false;
          }
        });
        this.filterGeography.continents.forEach((element, index) => {
          if (this.menuFilterGeography.value !== null) {
            this.filterGeography.continents[index].selected =
              this.menuFilterGeography.value.includes(element.value);
          } else {
            this.filterGeography.continents[index].selected = false;
          }
        });
        this.filterGeography.countries.forEach((element, index) => {
          if (this.menuFilterGeography.value !== null) {
            this.filterGeography.countries[index].selected =
              this.menuFilterGeography.value.includes(element.value);
          } else {
            this.filterGeography.countries[index].selected = false;
          }
        });
      }

      this.period = params.period ? params.period : '7';
      if (params.filter) {
        this.menuFilterSentiment = new UntypedFormControl(
          params.filter.split(','),
        );
      } else {
        this.menuFilterSentiment = new UntypedFormControl(['all']);
      }
      if (params.lang) {
        this.menuFilterLang = new UntypedFormControl(params.lang.split(','));
        this.menuButtonLang = this.filterLang.filter(
          (option) => params.lang.split(',').indexOf(option.value) !== -1,
        );
      } else {
        this.menuFilterLang = new UntypedFormControl(
          this.filterLang.map((option) => option.value),
        );
        this.menuButtonLang = this.filterLang.filter(
          (option) => this.menuFilterLang.value.indexOf(option.value) !== -1,
        );
      }
      if (params.feed) {
        this.menuFilterNews = new UntypedFormControl(params.feed.split(','));
        this.menuButtonNews = this.filterNews.filter(
          (option) => params.feed.split(',').indexOf(option.value) !== -1,
        );
      } else {
        this.menuFilterNews = new UntypedFormControl(
          this.filterNews.map((option) => {
            if (option.active) {
              return option.value;
            }
          }),
        );
        this.menuButtonNews = this.filterNews.filter(
          (option) => this.menuFilterNews.value.indexOf(option.value) !== -1,
        );
      }
      if (this.config.appConfig.setup.useStorage) {
        this.itheme = localStorage.getItem('theme');
      }
      this.params = params;
      this.fullscreen = params.fullscreen === 'true';
      this.feed = params.feed || 'all';
      this.filter = params.filter ? params.filter : 'all';
      this.filterName = this.filterLabels[this.filter];
      if (params.time) {
        this.time = params.time;
        if (
          params.time === '1' ||
          params.time === '7' ||
          params.time === '14' ||
          params.time === '30' ||
          params.time === '90'
        ) {
          this.menuFilterTimeframe = new UntypedFormControl(
            params.time.split(','),
          );
        } else {
          this.menuFilterTimeframe = new UntypedFormControl(['0']);
        }
      } else {
        this.time = '7';
        this.menuFilterTimeframe = new UntypedFormControl(['7']);
      }
      this.timename = this.timestring[this.timenumber.indexOf(this.time)];
      if (this.timename === 'custom') {
        this.timename = params.time;
      }
    });
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  /**
   * parse the time
   */
  goTime(value) {
    let period = value;
    if (value === 30) {
      period = 7;
    }
    if (value === 90) {
      period = 14;
    }
    this.router.navigate([], {
      queryParams: {
        time: value,
        period: period,
        tag: null,
        newsday: null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  /**
   * open dialog datepicher
   */
  goCustom() {
    let time = this.time;
    if (this.time === '0') {
      time = this.params.time;
    }
    const dialogRef = this.dialog.open(DatepicherComponent, {
      data: {
        time: time,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (
        result &&
        Object.prototype.hasOwnProperty.call(result, 'time') &&
        Object.prototype.hasOwnProperty.call(result, 'period')
      ) {
        this.time = result.time;
        this.period = result.period;
      } else {
        if (this.params.time) {
          if (this.params.time < 500) {
            this.menuFilterTimeframe = new UntypedFormControl(
              this.params.time.split(','),
            );
          } else {
            this.menuFilterTimeframe = new UntypedFormControl(['0']);
          }
        } else {
          this.menuFilterTimeframe = new UntypedFormControl(['7']);
        }
      }
    });
  }

  /**
   * go fullscreen
   */
  goFullscreen() {
    if (
      this.fullscreen ||
      document.fullscreen ||
      document.fullscreenElement !== null
    ) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  /**
   * change the feed type
   */
  goFeed(value) {
    this.router.navigate([], {
      queryParams: {
        feed: value,
        newsday: null,
      },
      queryParamsHandling: 'merge' /*, replaceUrl: true*/,
    });
  }

  /**
   * change sort by
   */
  goSortBy(value) {
    this.router.navigate([], {
      queryParams: {
        sort: value,
      },
      queryParamsHandling: 'merge' /*, replaceUrl: true*/,
    });
  }

  /**
   * change the theme
   */
  inTheme(theme) {
    // localStorage.set('theme', theme)
    this.router.navigate([], {
      queryParams: {
        theme: theme,
      },
      queryParamsHandling: 'merge' /*, replaceUrl: true*/,
    });
    // this.routing.inTheme(theme)
  }

  /**
   * change filtering by sentiment
   */
  goSentiment(sentiment) {
    this.router.navigate([], {
      queryParams: {
        filter: sentiment !== 'all' ? sentiment : null,
      },
      queryParamsHandling: 'merge' /*, replaceUrl: true*/,
    });
  }

  handleSearch(name, type) {
    const geographyArray = this.menuFilterGeography.value;
    if (type === 'Continents') {
      this.filterGeography.continents.forEach((element, index) => {
        if (element.label === name && element.selected === false) {
          this.filterGeography.continents[index].selected = true;
          geographyArray.push(element.value);
          this.menuFilterGeography.setValue(geographyArray);
        }
      });
    } else if (type === 'Countries') {
      this.filterGeography.countries.forEach((element, index) => {
        if (element.label === name && element.selected === false) {
          this.filterGeography.countries[index].selected = true;
          geographyArray.push(element.value);
          this.menuFilterGeography.setValue(geographyArray);
        }
      });
    }
  }

  /**
   * flag selected items from multi-selection lists
   */
  handleSelection(element) {
    element.selected = !element.selected;
  }

  /**
   * make single selection work for 'mat-selection-list'
   * @param event
   * @param type
   */
  handleSingleSelection(event, type) {
    if (event.options[0].selected) {
      event.source.deselectAll();
      event.options[0]._setSelected(true);
      if (type === 'sentiment') {
        this.menuFilterSentiment = new UntypedFormControl(
          event.options[0].value.split(),
        );
      } else if (type === 'timeframe') {
        this.menuFilterTimeframe = new UntypedFormControl(
          event.options[0].value.split(),
        );
      }
    }
  }

  /**
   * handle custom timeframe selection
   * @param value
   */
  handleCustomTimeframeSelection(value) {
    if (value === '0') {
      this.goCustom();
    }
  }

  /**
   * returns matching items
   */
  elementMatches(options, value) {
    const filterValue = value.toLowerCase();
    return options.filter(
      (item) => item.toLowerCase().indexOf(filterValue) === 0,
    );
  }

  /**
   * returns matching group's items
   */
  groupMatches(value) {
    if (value) {
      return this.geographyGroups
        .map((group) => ({
          type: group.type,
          names: this.elementMatches(group.names, value),
        }))
        .filter((group) => group.names.length > 0);
    }
    return this.geographyGroups;
  }

  /**
   * setup all the filtering options from the main filter menu
   */
  setFilterOptions() {
    // timeframe filter options

    const timeframeValue = this.menuFilterTimeframe.value[0];
    let time = this.time;
    let period = this.period;
    if (timeframeValue === '0') {
      // do nothing
    } else if (timeframeValue === '1') {
      time = timeframeValue;
      period = '7';
    } else if (timeframeValue === '7') {
      time = timeframeValue;
      period = '7';
    } else if (timeframeValue === '30') {
      time = timeframeValue;
      period = '7';
    } else if (timeframeValue === '90') {
      time = timeframeValue;
      period = '14';
    }

    // sentiment filter options

    let sentiment = this.menuFilterSentiment.value[0];
    if (sentiment === 'all') {
      sentiment = null;
    }

    // language filter options

    let lang = this.menuFilterLang.value.join(',');
    if (this.menuFilterLang.value.length === this.filterLang.length) {
      lang = null;
    }
    if (this.menuFilterLang.value.length === 0) {
      this.menuFilterLang = new UntypedFormControl(
        this.filterLang.map((option) => option.value),
      );
      lang = null;
    }
    this.menuButtonLang = this.filterLang.filter(
      (option) => this.menuFilterLang.value.indexOf(option.value) !== -1,
    );

    // newsfeed filter options

    let feed = this.menuFilterNews.value.join(',');
    // if (this.menuFilterNews.value.length === this.filterNews.length) {
    //   feed = null
    // }
    if (this.menuFilterNews.value.length === 0) {
      feed = null;
      this.menuFilterNews = new UntypedFormControl(
        this.filterNews.map((option) => option.value),
      );
    }
    this.menuButtonNews = this.filterNews.filter(
      (option) => this.menuFilterNews.value.indexOf(option.value) !== -1,
    );

    // event filter options

    let events = null;
    if (
      this.menuFilterEvents.value &&
      this.menuFilterEvents.value.length !== 0
    ) {
      events = this.menuFilterEvents.value.join(',');
    }

    // document categories filter options

    let categories = null;
    if (
      this.menuFilterCategories.value &&
      this.menuFilterCategories.value.length !== 0
    ) {
      categories = this.menuFilterCategories.value.join(',');
    }

    // source continents filter options

    let continents = null;
    if (
      this.menuFilterGeography.value &&
      this.menuFilterGeography.value.length !== 0
    ) {
      const continentsArray = this.menuFilterGeography.value.filter((item) => {
        return (
          this.filterGeography.continents.filter((element) => {
            return item === element.value;
          }).length !== 0
        );
      });
      if (continentsArray.length !== 0) {
        continents = continentsArray
          .map((item) => item.split(' ').pop())
          .join(',');
      }
    }

    // source countries filter options

    let countries = null;
    if (
      this.menuFilterGeography.value &&
      this.menuFilterGeography.value.length !== 0
    ) {
      const countriesArray = this.menuFilterGeography.value.filter((item) => {
        return (
          this.filterGeography.countries.filter((element) => {
            return item === element.value;
          }).length !== 0
        );
      });
      if (countriesArray.length !== 0) {
        countries = countriesArray
          .map((item) => item.split(' ').pop())
          .join(',');
      }
    }

    // source ranks filter options

    let ranks = null;
    if (this.menuFilterRanks.value && this.menuFilterRanks.value.length !== 0) {
      ranks = this.menuFilterRanks.value.join(',');
    }

    this.router.navigate([], {
      queryParams: {
        time: time,
        period: period,
        filter: sentiment,
        lang: lang,
        feed: feed,
        event_ids: events,
        categories: categories,
        continents: continents,
        countries: countries,
        ranks: ranks,
        tag: null,
        newsday: null,
      },
      queryParamsHandling: 'merge' /*, replaceUrl: true*/,
    });
  }

  /**
   * change the language
   */
  inLang(event) {
    this.router.navigate([], {
      queryParams: {
        lang: event !== 'multi' ? event : null,
      },
      queryParamsHandling: 'merge' /*, replaceUrl: true*/,
    });
    // if (!event) {
    //   let lang = this.menuFilterLang.value.join(',');
    //   if (this.menuFilterLang.value.length === this.filterLang.length) {
    //     lang = null;
    //   }
    //   if (this.menuFilterLang.value.length === 0) {
    //     this.menuFilterLang = new FormControl(this.filterLang.map(option => option.value));
    //     lang = null;
    //   }
    //   this.menuButtonLang = this.filterLang.filter(option => this.menuFilterLang.value.indexOf(option.value) !== -1);
    //   this.router.navigate([], {
    //     queryParams: {
    //       lang: lang,
    //       tag: null,
    //       newsday: null
    //     }, queryParamsHandling: 'merge', replaceUrl: true
    //   });
    // }
  }

  /**
   * parse the feed type
   */
  inNews(event) {
    if (!event) {
      let feed = this.menuFilterNews.value.join(',');
      // if (this.menuFilterNews.value.length === this.filterNews.length) {
      //   feed = null
      // }
      if (this.menuFilterNews.value.length === 0) {
        feed = null;
        this.menuFilterNews = new UntypedFormControl(
          this.filterNews.map((option) => option.value),
        );
      }
      this.menuButtonNews = this.filterNews.filter(
        (option) => this.menuFilterNews.value.indexOf(option.value) !== -1,
      );
      this.router.navigate([], {
        queryParams: {
          feed: feed,
          tag: null,
        },
        queryParamsHandling: 'merge' /*, replaceUrl: true*/,
      });
    }
  }

  /**
   * check/uncheck radio button based on filter
   * @param filter
   */
  isFilter(filter) {
    if (this.filter === filter) {
      return 'radio_button_checked';
    }
    return 'radio_button_unchecked';
  }

  /**
   * save a preset filter
   */
  savePreset() {
    const name = prompt('Name of the filter:', '');
    if (name && name !== '') {
      const filter = {
        name: name,
        time: this.params.time,
        filter: this.params.filter,
        lang: this.params.lang,
        feed: this.params.feed,
        event_ids: this.params.event_ids,
        categories: this.params.categories,
        continents: this.params.continents,
        countries: this.params.countries,
        ranks: this.params.ranks,
      };
      this.auth.setFilter(filter).subscribe(() => {
        this.snackBar.open('Collection update', 'OK', { duration: 5000 });
        this.presets = this.auth.getFilter();
      });
    }
  }

  /**
   * delete a preset filter
   */
  delPreset(filter) {
    if (window.confirm('Delete ' + filter.name + ' filter?')) {
      this.auth.delFilter(filter.id).subscribe(() => {
        this.snackBar.open('Collection update', 'OK', { duration: 5000 });
        this.presets = this.auth.getFilter();
      });
    }
  }

  /**
   * redirect the preset filter
   */
  viewPreset(preset) {
    this.router.navigate([], {
      queryParams: {
        time: preset.time,
        filter: preset.filter,
        lang: preset.lang,
        feed: preset.feed,
        event_ids: preset.event_ids,
        categories: preset.categories,
        continents: preset.continents,
        countries: preset.countries,
        ranks: preset.ranks,
        newsday: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  /**
   * open help component in dialog window
   */
  openHelp() {
    this.dialog.open(HelpComponent);
  }
}
