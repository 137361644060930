<div class="menu-above" *ngIf="auth.featureFlags.showMenuTop" [class.inblur]="params.tutorial" [class.noblur]="params.tutorial=='menuabove'">
  <div class="menu-above-left" [class.mobile]="routing.isMobile()" *ngIf="auth.featureFlags.showLogo">
    <!--<button mat-icon-button (click)="asideMenu()" *ngIf="routing.isDev() && !routing.isWidget() && !routing.isMobile()">
      <mat-icon>more_vert</mat-icon>
    </button>-->
    <img *ngIf="!routing.isMobile()" class="navlogo" src="assets/logo/{{logo}}_menu.svg" alt="yukkalab" (click)="inLogo()">
    <img *ngIf="routing.isMobile()" class="navlogo" src="assets/logo/{{logo}}_mobile.svg" alt="yukkalab" (click)="inLogo()">
  </div>
  <div class="menu-above-rigt">
    <div class="abovel">
      <app-menu-search [component]="'menuabove'"></app-menu-search>
    </div>
    <div class="abover" [class.mobile]="routing.isMobile()">
      <app-menu-options></app-menu-options>
    </div>
  </div>
</div>
