const getScoreTypeDetails = (scoreType: string) => {
  const scoreTypeMapping = {
    sentiment: { label: 'Sentiment', shortName: 'Sent', scoreKey: 'sentiment' },
    general_risk: {
      label: 'General Risk',
      shortName: 'GR',
      scoreKey: 'general_risk',
    },
    credit_watch: {
      label: 'Credit Risk',
      shortName: 'CR',
      scoreKey: 'credit_risk',
    },
    esg_risk: {
      label: 'ESG Risk',
      shortName: 'ESGR',
      scoreKey: 'esg_risk',
    },
    esg: { label: 'ESG', shortName: 'ESG', scoreKey: 'esg' },
    immediate_risk: {
      label: 'Immediate Risk',
      shortName: 'IR',
      scoreKey: 'immediate_risk',
    },
    bull_bear: { label: 'Bull-Bear', shortName: 'BB', scoreKey: 'bb' },
    eb_sim: { label: 'EB-SIM', shortName: 'EB-SIM', scoreKey: 'eb_sim' },
    eb_sim_risk: {
      label: 'EB-SIM Risk',
      shortName: 'EB-SIMR',
      scoreKey: 'eb_sim_risk',
    },
  };

  return (
    scoreTypeMapping[scoreType] || { label: '', shortName: '', scoreKey: '' }
  );
};

export default getScoreTypeDetails;
