import { Component, Input } from '@angular/core';
import { SourceDoc } from '../interfaces/chart-details.interface';

@Component({
  selector: 'app-citation-tooltip',
  templateUrl: './chat-citation-tooltip.component.html',
  styleUrls: ['./chat-citation-tooltip.component.scss'],
})
export class CitationTooltipComponent {
  @Input() sourceDoc: SourceDoc;

  constructor() {}
}
