import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthService } from 'src/app/service/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-dialog-report',
  templateUrl: './dialog-report.component.html',
  styleUrls: ['./dialog-report.component.scss'],
})
export class DialogReportComponent implements OnInit {
  reportStatus: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<DialogReportComponent>,
    public auth: AuthService,
    public snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.reportStatus = this.data.status;
  }

  // send email with report
  onClick() {
    const date = moment(new Date()).format('YYYY-MM-DD');
    let type = this.data.type;
    if (this.data.type === 'credit_risk') {
      type = 'credit_watch';
    }
    if (Object.prototype.hasOwnProperty.call(this.data, 'portfolioName')) {
      this.auth
        .sendReportPortfolio(
          this.data.portfolioName,
          this.data.portfolioContent,
          type,
          date,
        )
        .subscribe(
          () => {
            this.dialogRef.close();
            this.snackBar.open(
              'Thank you for your interest. In the next minutes, you will receive an email with a download link to access the report.',
              'OK',
              { duration: 5000 },
            );
          },
          () => {
            this.dialogRef.close();
            this.snackBar.open(
              'Something went wrong, report could not be sent.',
              'OK',
              { duration: 5000 },
            );
          },
        );
    } else {
      this.auth.sendReport(this.data.entityId, type, date).subscribe(
        () => {
          this.dialogRef.close();
          this.snackBar.open(
            'Thank you for your interest. In the next minutes, you will receive an email with a download link to access the report.',
            'OK',
            { duration: 5000 },
          );
        },
        () => {
          this.dialogRef.close();
          this.snackBar.open(
            'Something went wrong, report could not be sent.',
            'OK',
            { duration: 5000 },
          );
        },
      );
    }
  }
}
