<div
  *ngIf="showChartsContainer"
  [ngClass]="{ hidden: !isVisible }"
  class="charts__container"
>
  <div
    class="charts__header--container"
    appDetectVisibility
    (isVisible)="markFieldAsSeen($event)"
  >
    <div class="charts__header">
      <div class="charts__title">
        <span
          *ngIf="chartHeaderCompany"
          class="charts__company"
          style="font-weight: 600"
        >
          {{ chartHeaderCompany }}, </span
        >{{ generateChatHeader(selectedScoreType) }}
      </div>
      <div class="charts__timeframe">
        <span class="charts__timeframe--text">{{ chartTimeFrame }}</span>
      </div>
    </div>
    <mat-icon
      class="charts__icon--close"
      id="chat-close-charts"
      (click)="closeCharts()"
      >close</mat-icon
    >
  </div>

  <div class="charts__content--body">
    <div
      *ngIf="scoreTypeOptions?.length > 1"
      class="charts__content--body--score"
    >
      <mat-select
        [(value)]="selectedScoreType"
        (selectionChange)="changeScoreType($event.value)"
      >
        <mat-option
          *ngFor="let scores of scoreTypeOptions | orderBy: 'label'"
          [value]="scores.value"
          >{{ scores.label }}</mat-option
        >
      </mat-select>
    </div>
  </div>
  <div *ngIf="thisIsVisible">
    <app-chat-charts-trend
      *ngIf="showTrend"
      [childTrend]="chat.companyName"
      [childTrendScore]="selectedScoreType"
      [childTrendTimeframe]="trendTimeframe"
    ></app-chat-charts-trend>
    <app-chat-charts-events
      *ngIf="showEvents"
      [childEvent]="chat.companyName"
      [childEventScore]="selectedScoreType"
      [childEventsTimeframe]="eventsTimeframe"
    ></app-chat-charts-events>
    <app-chat-charts-ranking
      *ngIf="showRanking"
      [childRankingScoreType]="selectedScoreType"
      [chartEntities]="chat.requested_entities"
      [childRankingTimeframe]="rankingTimeframe"
    ></app-chat-charts-ranking>
    <!-- <app-chat-charts-benchmark *ngIf="showEvents" [childBenchmark]="chat.message.companyName"></app-chat-charts-benchmark> -->
  </div>
</div>
